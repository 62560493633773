import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

const Container = styled.div<{xl?: number}>`
    flex: ${props => props.xl === 8 ? '0 0 66.66667%' : (props.xl === 6 ? '0 0 50%' : (props.xl === 4 ? '0 0 33.33333%' : (props.xl === 3 ? '0 0 25%' : 'unset')))};
    max-width: ${props =>  props.xl === 8 ? '66.66667%' : (props.xl === 6 ? '50%' : (props.xl === 4 ? '33.33333%' : (props.xl === 3 ? '25%' : '100%')))};
    flex-basis: ${props => !props.xl && 0};
    flex-grow: ${props => !props.xl && 1};
    position: relative;
    width: 100%;
    min-height: 1px;
`

const Col = (props: Props) =>{

    return(
        <Container
            {...props}
        >
            {props.children}
        </Container>
    )
}
export default Col;
export interface Props extends ComponentPropsWithoutRef<"div">{
    xl?: number
    style?: React.CSSProperties
}