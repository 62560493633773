import React from "react";
import styled from "styled-components";
import { ResidenceScheme, ActivityRenderScheme } from "client-v2";

import { Text, Color } from "@adoptaunabuelo/react-components";
import Card from "../../../components/Containers/Card";
import History from "../../../components/Profile/History";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;

const SectionHistory = (props: Props) => {
	return (
		<Card style={{ marginBottom: 16 }}>
			<TitleHeader>
				<Text type="p" weight={"semibold"}>
					Llamadas
				</Text>
			</TitleHeader>
			{props.calls.length > 0 ? (
				<History activities={props.calls} />
			) : (
				<Text type="p2" style={{ color: Color.text.high }}>
					No hay notas de momento
				</Text>
			)}
		</Card>
	);
};
export default SectionHistory;
export interface Props {
	residence: ResidenceScheme;
	calls: ActivityRenderScheme[];
}
