import { Action } from "../actions/index";
import { ActionType } from "../actions-types/index";

interface stateProps {
	launch: boolean;
	function: "all";
	data: Object;
}

const initialState: stateProps = {
	launch: false,
	function: "all",
	data: {},
};

export const profileReducer = (
	data: stateProps = initialState,
	action: Action
) => {
	switch (action.type) {
		case ActionType.SET_PROFILE_FUNCTION:
			return action.data;
		case ActionType.SET_COORPORATE_PROFILE_FUNCTION:
			return action.data;
		case ActionType.SET_RESIDENCE_PROFILE_FUNCTION:
			return action.data;
		default:
			return data;
	}
};
