import { Action } from '../actions/index'
import { ActionType } from '../actions-types/index'
import { AdminTaskScheme, UserScheme } from 'client-v2';
import { SalesRole } from '../../roles';

const initialState: UserScheme | null = null
const roleInitialState = SalesRole

export const callReducer = (user:UserScheme | null = initialState, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_CALL_USER:
       		return action.data;
      	default:
        	return user;
    }
};

export const currentUserReducer = (user:UserScheme | null = initialState, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_CURRENT_USER:
       		return action.data;
      	default:
        	return user;
    }
};

export const roleReducer = (role:Object = roleInitialState, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_ROLE:
       		return action.data;
      	default:
        	return role;
    }
};

export const autodialReducer = (data: {play: boolean, list:Array<{user: UserScheme, adminTask: AdminTaskScheme}>} = {play: false, list: []}, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_AUTODIAL:
       		return action.data;
      	default:
        	return data;
    }
};