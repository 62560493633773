import React, { useState } from "react";
import styled from "styled-components";
import { Constant, ResidenceScheme, UserScheme } from "client-v2";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../../../redux";
import { bindActionCreators } from "redux";

import { Text, Color, Filter } from "@adoptaunabuelo/react-components";
import Card from "../../../components/Containers/Card";
import CardList from "../../../components/Profile/CardList";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 4px;
`;

const SectionGrandpa = (props: Props) => {
	const dispatch = useDispatch();
	const { setResidenceProfileFunction } = bindActionCreators(
		actionsCreators,
		dispatch
	);

	const [selectedStates, setSelectedStates] = useState<Array<string>>([]);
	const [selectedServices, setSelectedServices] = useState<{
		visit: boolean;
		letter: boolean;
	}>({
		visit: true,
		letter: false,
	});

	const getGrandpas = async (
		states?: Array<string>,
		services?: {
			letter: boolean;
			visit: boolean;
		}
	) => {
		setResidenceProfileFunction({
			launch: true,
			function: "grandpas",
			data: { states: states, services: services },
		});
	};

	return (
		<Card style={{ marginBottom: 16 }}>
			<TitleHeader>
				<Text type="p" weight={"semibold"}>
					Abuelos
					<Text type="c1" style={{ color: Color.text.high }}>
						{props.grandpaCount} resultados
					</Text>
				</Text>
				<Row>
					<Filter
						id={"services-filter"}
						placeholder="Programas"
						type={"single"}
						options={[
							{
								id: "call",
								label: "Voluntariado",
							},
							{
								id: "letter",
								label: "Cartas",
							},
						]}
						selectedOptions={
							selectedServices.letter || selectedServices.visit
								? [
										{
											id: selectedServices.visit
												? "call"
												: "letter",
										},
								  ]
								: undefined
						}
						onChange={(ops) => {
							const temp = {
								visit:
									ops.filter((i) => i.id === "call").length >
									0
										? true
										: false,
								letter:
									ops.filter((i) => i.id === "letter")
										.length > 0
										? true
										: false,
							};
							setSelectedServices(temp);
							getGrandpas(selectedStates, temp);
						}}
					/>
					<Filter
						id={"state-filter"}
						placeholder="Estado"
						type={"multiple"}
						options={Constant.state()
							.filter((item) => item.grandpa)
							.map((item) => ({
								...item,
								label: item.title,
							}))}
						onChange={(ops) => {
							const temp = ops.map((item) => item.id);
							setSelectedStates(temp);
							getGrandpas(temp, selectedServices);
						}}
					/>
				</Row>
			</TitleHeader>
			{props.grandpas.length > 0 ? (
				<CardList
					data={props.grandpas.map((item) => ({
						user: item,
						labels: [item.state],
					}))}
				/>
			) : (
				<Text type="p2" style={{ color: Color.text.high }}>
					No hay abuelos
				</Text>
			)}
		</Card>
	);
};

export default SectionGrandpa;
export interface Props {
	residence: ResidenceScheme;
	grandpas: UserScheme[];
	grandpaCount: number;
	onResidenceChanged: (residence: ResidenceScheme) => void;
}
