import React, { useState } from "react";
import { ErrorScheme, User, UserScheme } from 'client-v2';
import styled from 'styled-components';
import moment from "moment";
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';

import { Phone, Users, Mail, Plus, Video, Clapperboard } from 'lucide-react';
import Card from '../../../components/Containers/Card'
import { Text, Button, Color, Menu, Checkbox } from '@adoptaunabuelo/react-components';

const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin: 18px 0px;
`;
const ProgramIcon =  styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background:#F2F2F2;
	height: 32px;
	width: 32px;
	border-radius: 50%;
	margin: 0px 4px;
`;
const CallRow = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	margin: 8px 0px;
`
const CallCell = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
`
const BottomView = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-top: 8px;
	border-top: 1px solid ${Color.line.soft};
`

const SectionCall = (props: Props) =>{

	const dispatch = useDispatch();
	const { setError } = bindActionCreators(actionsCreators, dispatch);

	const [ options, setOptions ] = useState([
		{
			id: 'call',
			label: 'Programa llamada',
			defaultSelection: props.user.data && props.user.data.call ? true : false 
		},
		{
			id: 'visit',
			label: 'Programa visitas',
			defaultSelection: props.user.data && props.user.data.visit ? true : false 
		},
		{
			id: 'letter',
			label: 'Programa carta',
			defaultSelection: props.user.data && props.user.data.letter ? true : false 
		},
		{
			id: 'casting',
			label: 'Casting',
			defaultSelection: props.user.data && props.user.data.casting ? true : false 
		}
	])
	const [ loading, setLoading ] = useState(false);

	const onProgramChange = (optionsResult: Array<any>) =>{
		const temp = options.map(item => {
			if(optionsResult.findIndex(tempIndex => tempIndex.id === item.id) > -1) {
				return {...item, defaultSelection: true}
			} 
			else{
				return {...item, defaultSelection: false}
			}
		});
		setOptions(temp);
	}

    const onProgramSave = async ()=> {
		setLoading(true);
		let selection = {};
		options.map(item => {
			selection = {...selection, [item.id]: item.defaultSelection}
		});
		User.set(props.user.objectId, {
			data: {
				...selection
			}
		}).then((user) =>{
			setLoading(false);
			props.onUserChanged(user.data);
		}).catch((error: ErrorScheme) =>{
			setLoading(false);
			setError(true, error.message);
		});
	}

	const renderCell = (title: string, data: number) =>(
		<CallCell>
			<Text type='h5' weight={"medium"}>
				{title === 'tiempo' ? (moment.utc(data).format('HH')+"h "+ moment.utc(data).format('mm')+"m") : title === 'valoración' ? data.toFixed(2) : data.toFixed(0)}
			</Text>
			<Text type='c1' style={{color: Color.text.high}}>
				{title}
			</Text>
		</CallCell>
	)

	return (
        <Card style={{marginBottom:16}}>
            <Text type='p' weight={"semibold"}>
				Acompañamiento
			</Text>
            <Column>
				{props.user.analytics &&
					<>
					<CallRow>
						{renderCell('tiempo',  ((props.user.analytics.call && props.user.analytics.video && props.user.analytics.visit) ? (props.user.analytics.call.time + props.user.analytics.video.time + props.user.analytics.visit.time)*60*1000 : 0))}
						{renderCell('valoración', (props.user.analytics.review && props.user.analytics.review.average) ? props.user.analytics.review.average : 0)}
						{renderCell('nª reviews', (props.user.analytics.review && props.user.analytics.review.amount) ? props.user.analytics.review.amount : 0)}
					</CallRow>
					<CallRow>
						{renderCell('llamadas', props.user.analytics.call ? props.user.analytics.call.amount : 0)}
						{renderCell('videollam.', props.user.analytics.video ? props.user.analytics.video.amount : 0)}
						{renderCell('visitas',  props.user.analytics.visit ? props.user.analytics.visit.amount : 0)}
					</CallRow>
					</>
				}
            </Column>
            {props.user.type==="grandpa" &&
				<div style={{position:"relative",display:"flex", alignItems:"center"}}>
					{props.user.data && props.user.data.call && 
						<ProgramIcon>
							<Phone style={{height:15.91, stroke:"#004773"}}/>
						</ProgramIcon>
					}
					{props.user.data && props.user.data.visit && 
						<ProgramIcon>
							<Users style={{height:15.91, stroke:"#5963F6"}}/>
						</ProgramIcon>
					}
					{props.user.data && props.user.data.letter && 
						<ProgramIcon>
							<Mail style={{height:15.91, stroke:"#00ABC5"}}/>
						</ProgramIcon>
					}
					{props.user.data && props.user.data.casting && 
						<ProgramIcon>
							<Clapperboard style={{height:15.91, stroke:"#004773"}}/>
						</ProgramIcon>
					}
					<Menu
						id={'menu-program'}
						position={'bottom-right'}
						menuStyle={{height: 'unset'}}
						icon={<Plus color={Color.text.high}/>}
					>
						<Checkbox
							type="multiple"
							options={options}
							selectedOptions={options.filter(i => i.defaultSelection)}
							onChange={onProgramChange}
						/>
						<BottomView>
							<Button
								style={{height: 38, padding: 12}}
								onClick={onProgramSave}
								loading={loading}
							>
								Guardar
							</Button>
						</BottomView>
					</Menu>
            	</div>
			}
        </Card>
	);
}

export default SectionCall;
export interface Props{
    user: UserScheme,
	onUserChanged: (user: UserScheme) => void
}