import React, { useState } from "react";
import styled from 'styled-components';
import { ChallengeScheme } from 'client-v2';
import moment from "moment";

import Card from '../../../components/Containers/Card'
import { Color, Text, Button } from '@adoptaunabuelo/react-components';
import { Plus } from 'lucide-react';
import TimelineModal from "./TimelineModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const Cell = styled.div`
    display: flex;
    flex-direction: row;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
`
const DateView = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8px 12px;
    border-radius: 6px;
`
const Line = styled.div`
    display: flex;
    width: 1px;
    height: 100%;
    min-height: 20px;
    border-left: 2px solid ${Color.line.soft};
`
const Image = styled.img`
    height: 225px;
    width: 100%;
    border-radius: 6px;
    margin-bottom: 12px;
    object-fit: cover;
`

const TimelineSection = (props: Props) =>{

    const [ showModal, setShowModal ] = useState(false);

    const renderTimelineCell = (item: any, index: number) =>(
        <Cell
            key={'timeline-cell-'+index}
        >
            <Column style={{marginRight: 24, alignItems: 'center'}}>
                <DateView
                    style={{backgroundColor: item.color ? item.color : Color.background.soft}}
                >
                    <Text type='p' style={{color: item.textColor ? item.textColor : Color.text.high}}>
                        {moment(item.createdAt).format('MMM')}
                    </Text>
                    <Text type='p' style={{color: item.textColor ? item.textColor : Color.text.high}}>
                        {moment(item.createdAt).format('DD')}
                    </Text>
                </DateView>
                {!(index+1 === props.challenge.timeline.length) &&
                    <Line/>
                }
            </Column>
            <Column style={{paddingBottom: 26}}>
                {item.image &&
                    <Image
                        src={item.image}
                    />
                }
                <Text type='p' weight="medium" style={{marginTop: 4}}>
                    {item.title}
                </Text>
                {item.subtitle &&
                    <Text type='p2' style={{marginTop: 4}}>
                        {item.subtitle}
                    </Text>
                }
            </Column>
        </Cell>
    )

	return (
        <>
        <TimelineModal
            isVisible={showModal}
            challenge={props.challenge}
            onSave={(challenge) => props.setChallenge(challenge)}
            onClose={() => setShowModal(false)}
        />
        <Card style={{marginBottom:16}}>
            <TitleHeader>
				<Text type='p' weight={"semibold"}>
					Últimas actualizaciones
				</Text>
				<Button 
                    style={{position: 'absolute', right: 16, top: 12}}
					design={'image'} 
					icon={<Plus height={20} width={20}/>}
                    onClick={() => setShowModal(true)}
				/>
			</TitleHeader>
            {props.challenge.timeline && props.challenge.timeline.map((item, index) =>(
                renderTimelineCell(item, index)
            ))}
        </Card>
        </>
	);
}

export default TimelineSection;
export interface Props{
    challenge: ChallengeScheme
    setChallenge: (challenge: ChallengeScheme) => void
}