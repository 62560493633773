import React, { useState } from "react";
import { Constant, UserScheme } from 'client-v2';
import styled from 'styled-components';

import Card from '../../../components/Containers/Card'
import { Text, Button, Color, Label } from '@adoptaunabuelo/react-components';
import { User, Edit2 } from 'lucide-react';
import PreferencesModal from '../../../components/Modals/PreferencesModal'
import InfoCell from '../../../components/Cell/InfoCell';

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 13px;
	align-items: center;
`;
const Row2 = styled.div`
	display: flex;
	align-items: center;
	box-shadow: 0px 1px 0px #E5E5E5;
	min-height: 40px;
`;

const SectionPreferences = (props: Props) =>{

	const grandpa = props.user.type === 'grandpa' ? true : false;
	const [ showHobbiesModal, setShowHobbiesModal ] = useState(false);

	const isActive = (userData:any, text:string, entity:string) => {
		return userData && userData.schedule && userData.schedule[entity].indexOf(text)!==-1
	}

	return (
        <>
		<PreferencesModal
			isVisible={showHobbiesModal}
			user={props.user}
			onClose={() => setShowHobbiesModal(false)}
			onSave={(user) => {
				setShowHobbiesModal(false);
				props.onUserChanged(user)
			}}
		/>
        <Card style={{marginBottom:16}}>
            <TitleHeader>
                <Text type='p' style={{marginBottom:4}} weight={"semibold"}>Preferencias</Text>
				<Button
					design="image"
					onClick={()=> setShowHobbiesModal(true)}
					icon={<Edit2 height={20} width={20}/>}
					style={{position: 'absolute', right: 16, top: 12}}
				/>
            </TitleHeader>
			{props.user.type === 'volunteer' &&
				<InfoCell
					style={{marginBottom: 12}}
					title={'Tipo de voluntariado'}
					subtitle={props.user.data?.volunteering ? 
						props.user.data.volunteering.map((item, index) => {
							const volunteering = Constant.volunteering().filter((temp: any) => temp.id === item);
							if(item === 'all')
								return 'Presencial y online'
							else if(index !== 0) 
								return ", "+volunteering[0].title;
							else 
								return volunteering[0].title;
						}) 
						: 'Sin datos'
					}
					icon={User}
				/>
			}
			{props.user.type === 'grandpa' &&
				<InfoCell
					style={{marginBottom: 12}}
					title={'Máximo de voluntarios'}
					subtitle={props.user.internalData?.slot ? props.user.internalData?.slot : 4}
					icon={User}
				/>
			}
            <Row2 style={{boxShadow:"none", flexWrap: "wrap"}}>
                {(props.user.data && props.user.data.hobbies && props.user.data.hobbies.length > 0) ?
					props.user.data.hobbies.map((item:string, index:number) => {
						const hobbie = Constant.hobbies().filter((temp: any) => temp.id === item);
						if(hobbie[0]) return(
							<Label
								type="chip"
								key={'hobbies'+index}
								size={"selector"} 
								style={{marginRight:4, marginBottom:4}} 
								text={hobbie[0].title}
							/>
						)
						else return null;
					})
                : 
					<Text type='p2' style={{backgroundColor: grandpa ? Color.status.color.warning : 'transparent'}}>
						Sin hobbies añadidos
					</Text>	
				}
            </Row2>
			<Text type='p' weight="semibold" style={{marginTop: 28, marginBottom: 12, width: 'fit-content', backgroundColor: grandpa ? (props.user.data && props.user.data.schedule && props.user.data.schedule.dates && props.user.data.schedule.dates?.length > 0 && props.user.data.schedule.hours && props.user.data.schedule.hours?.length > 0) ? "transparent" : Color.status.color.warning  : "transparent"}}>
				Disponibilidad
			</Text>
			<Row2 style={{boxShadow:"none"}}>
                <Label type="chip" disabled={!isActive(props.user.data,"monday","dates")} style={{marginRight:4}} size={"selector"} text={"Lu"}/>
                <Label type="chip" disabled={!isActive(props.user.data,"tuesday","dates")} style={{marginRight:4}} size={"selector"} text={"Ma"}/>
                <Label type="chip" disabled={!isActive(props.user.data,"wednesday","dates")} style={{marginRight:4}} size={"selector"} text={"Mi"}/>
                <Label type="chip" disabled={!isActive(props.user.data,"thursday","dates")} style={{marginRight:4}} size={"selector"} text={"Ju"}/>
                <Label type="chip" disabled={!isActive(props.user.data,"friday","dates")} style={{marginRight:4}} size={"selector"} text={"Vi"}/>
                <Label type="chip" disabled={!isActive(props.user.data,"saturday","dates")} style={{marginRight:4}} size={"selector"} text={"Sa"}/>
                <Label type="chip" disabled={!isActive(props.user.data,"sunday","dates")} style={{marginRight:4}} size={"selector"} text={"Do"}/>
            </Row2>
            <Row2 style={{boxShadow:"none", flexWrap:"wrap"}}>
				<Label type="chip" disabled={!isActive(props.user.data,"morning","hours")} size={"selector"} style={{marginRight:4,marginBottom:4}} text={"Mañanas"}/>
				<Label type="chip" disabled={!isActive(props.user.data,"afternoon","hours")} size={"selector"} style={{marginRight:4,marginBottom:4}} text={"Tardes"}/>
            </Row2>
        </Card>
        </>
	);
}

export default SectionPreferences;
export interface Props{
    user: UserScheme,
	onUserChanged: (user: UserScheme) => void
}