import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorScheme, Grandpa, Residence, ResidenceScheme, UserScheme } from 'client-v2';

import { Input, Feedback, Checkbox, Dropdown } from '@adoptaunabuelo/react-components';
import AdminBar from "../../../components/Nav/AdminBar";
import RegisterForm from "../../../components/Form/RegisterForm";

const Container = styled.div`
    position: relative;
	display: flex;
	width: -webkit-fill-available;
    height: 100vh;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const Icon = styled.img`
    height: 24px;
    width: 24px;
`

const Register = (props: Props) =>{

    const navigate = useNavigate();
    const location = useLocation();

    const [ timer, setTimer ] = useState<any>(undefined);
    const [ loading, setLoading ] = useState(false);
    const [ form, setForm ] = useState<{name: string | undefined, surname: string | undefined, phone: string | undefined, gender: string | undefined, residence: {id: string, title: string} | undefined}>({
        name: undefined,
        surname: undefined,
        phone: undefined,
        gender: undefined,
        residence: undefined
    });
    const [ residences, setResidences ] = useState<Array<ResidenceScheme>>([]);
    const [ showResidenceDrop, setShowResidenceDrop ] = useState(false);
    const [ error, setError ] = useState<string | undefined>(undefined);

    useEffect(() =>{
        if(location.state && location.state.residence){
            setForm({
                ...form,
                residence: {
                    id: location.state.residence.objectId,
                    title: location.state.residence.name
                }
            })
            setShowResidenceDrop(true);
        }
    },[]);

    const onNameChange = (e: any) =>{
        setError(undefined);
        setForm({
            ...form,
            name: e.target.value
        })
    }

    const onSurnameChange = (e: any) =>{
        setError(undefined);
        setForm({
            ...form,
            surname: e.target.value
        })
    }

    const onPhoneChange = (e: any) =>{
        setError(undefined);
        if(e.isValid)
            setForm({
                ...form,
                phone: e.country+e.value
            })
    }

    const onGenderChange = (array: Array<any>) =>{
        setForm({
            ...form,
            gender: array[0].id
        })
    }

    const onResidenceChange = (array: any) =>{
        setForm({
            ...form,
            residence: array[0]
        })
    }

    const onSaveClick = async () =>{
        if(form.name && form.surname && form.phone){
            setLoading(true);
            Grandpa.register({
                name: form.name,
                surname: form.surname,
                phone: form.phone,
                gender: form.gender,
                residenceId: form.residence ? form.residence.id : undefined
            }).then((result) =>{
                setLoading(false);
                navigate('/abuelos/perfil/?id='+result.data.objectId);
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
                setError(error.message);
            });
        }
        else{
            if(!form.name) setError('Debe añadir el nombre del abuelo/a');
            else if(!form.surname) setError('Debe añadir el apellido del abuelo/a');
            else if(!form.phone) setError('Debes añadir un número de teléfono válido');
        }
    }

    const onResidenceSearch = (text: string) =>{
        if(timer){
            clearTimeout(timer);
            setTimer(undefined);
        }
        setTimer(setTimeout(() => {
            searchResidence(text);
        }, 500));
    }

    const searchResidence = async (text: string) =>{
        setLoading(true);
        Residence.get({
            name: text,
            limit: 5
        }).then((result) =>{
            setResidences(result.data);
            setLoading(false);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
        });
    }

    return(
        <Container>
            <Feedback
                isVisible={error ? true : false}
                type='error'
                text={error ? error : ''}
                onClose={() => setError(undefined)}
            />
            <AdminBar
                style={{marginBottom: 16}}
                title="Registro de abuelo/a"
                goBack={() => navigate(-1)}
            />
            <RegisterForm
                title={'Datos personales'}
                buttonProps={{
                    children: 'Guardar',
                    onClick: onSaveClick,
                    loading: loading
                }}
                options={[
                    {
                        id: 'name',
                        title: 'Nombre',
                        Data: <Input
                            type="text"
                            placeholder='Nombre'
                            onChange={onNameChange}
                        />
                    },
                    {
                        id: 'surname',
                        title: 'Apellidos',
                        Data: <Input
                            type="text"
                            placeholder='Apellidos'
                            onChange={onSurnameChange}
                        />
                    },
                    {
                        id: 'phone',
                        title: 'Teléfono',
                        Data: <Input 
                            placeholder='Teléfono'
                            type='tel'
                            onPhoneChange={onPhoneChange}
                        />
                    },
                    {
                        id: 'separator'
                    },
                    {
                        id: 'gender',
                        title: 'Género',
                        Data: <Dropdown
                            id='gender-dropdown'
                            style={{flex: 1}}
                            placeholder='Género'
                            type="single"
                            menuPosition="top"
                            options={[
                                {
                                    id: 'male',
                                    title: 'Hombre',
                                    icon: <Icon src={require('../../../assets/img/Man.png')}/>
                                },
                                {
                                    id: 'female',
                                    title: 'Mujer',
                                    icon: <Icon src={require('../../../assets/img/Woman.png')}/>
                                }
                            ]}
                            selectedOptions={form.gender ? [{id: form.gender, title: ""}] : []}
                            onChange={onGenderChange}
                        />
                    },
                    {
                        id: 'separator'
                    },
                    {
                        id: 'coorporate',
                        title: 'Residencia',
                        Data: <>
                            <Checkbox
                                elementStyle={{marginBottom: 0}}
                                type="multiple"
                                options={[
                                    {
                                        id: 'coorporate',
                                        label: 'Vive en residencia'
                                    }
                                ]}
                                selectedOptions={showResidenceDrop? [{id: 'coorporate'}] : []}
                                onChange={() => {
                                    setShowResidenceDrop(!showResidenceDrop);
                                    setForm({
                                        ...form,
                                        residence: undefined
                                    });
                                }}
                            />
                            {showResidenceDrop &&
                                <Dropdown
                                    id={'coorporate-dropdown'}
                                    style={{marginTop: 8}}
                                    placeholder={'Residencia'}
                                    type='single'
                                    options={residences.map(item => ({id: item.objectId, title: item.name}))}
                                    selectedOptions={form.residence ? [form.residence] : []}
                                    onSearchChange={onResidenceSearch}
                                    onChange={onResidenceChange}
                                />
                            }
                        </>
                    }
                ]}
            />
        </Container>
    )
}
export default Register;
export interface Props{

}