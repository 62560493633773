import React from 'react';
import styled from 'styled-components';
import { AdminTaskScheme } from 'client-v2';
import moment from 'moment'

import { Text, Color } from '@adoptaunabuelo/react-components';

const ListsContainer = styled.section`
    display: flex;
    align-items: start;
    padding: 0 32px;
    overflow-x: auto;
    height: calc(100vh - 72px - 66px);
`
const List = styled.div`
    flex: 0 0 27rem;
    display: flex;
    flex-direction: column;
    background-color: ${Color.background.soft};
    max-height: calc(100vh - 72px - 66px - 24px);
    border-radius: 12px;
    margin-right: 1rem;
`
const ListItems = styled.ul`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-content: start;
    padding: 0 12px 0.5rem;
    overflow-y: auto;
    list-style-type: none;
`
const Item = styled.li`
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    background-color: white;
    padding: 0.65rem 0.6rem;
    color: ${Color.text.full};
    border-bottom: 0.1rem solid ${Color.line.low};
    border-radius: 4px;
    margin-bottom: 8px;
    word-wrap: break-word;
    cursor: pointer;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 6px;
`

const Columns = (props: Props) =>{

    return(
        <ListsContainer>
            {props.lists && props.lists.map(item =>{
                const rows = props.rows.filter(item2 => item2.state === item.id);
                return(
                    <List
                        key={'column'+item.id}
                    >
                        <Text style={{padding: '12px 18px 0px'}} type='h6' weight='semibold'>
                            {item.label}
                        </Text>
                        <ListItems>
                            {rows.map(row => (
                                <Item
                                    key={row.objectId}
                                    onClick={() => props.onClick && props.onClick(row)}
                                >
                                    {row.typeTitle}
                                    <Row
                                        style={{justifyContent: 'space-between'}}
                                    >
                                        <Text type='c2' style={{color: Color.text.high}}>
                                            {row.objectId}
                                        </Text>
                                        <Text type='c2' style={{color: Color.text.high}}>
                                            {moment(row.createdAt).format('DD/MM/YYYY')}
                                        </Text>
                                    </Row>
                                </Item>
                            ))}
                        </ListItems>
                    </List>
                )
            })}
        </ListsContainer>
    )
}
export default Columns;
export interface Props{
    lists?: Array<{
        id: string,
        label: string
    }>
    rows: Array<AdminTaskScheme>
    onClick?: (task: AdminTaskScheme) => void
}