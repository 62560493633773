import React, { useState } from "react";
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';
import { Activity, ActivityRenderScheme, ErrorScheme, UserScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import { Text, Button, Color, Modal } from '@adoptaunabuelo/react-components';
import { Plus } from 'lucide-react';
import Note from '../../../components/Profile/Note'
import NoteModal from '../../../components/Modals/NoteModal'

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;

const SectionNotes = (props: Props) =>{

	const dispatch = useDispatch();
	const { setProfileFunction, setError } = bindActionCreators(actionsCreators, dispatch);

	const [ modalNote, setModalNote ] = useState<{show: boolean, type: 'edit' | 'new', data?: any}>({
		show:false,
		type:"new",
		data:undefined
	});
	const [ showRemoveModal, setShowRemoveModal ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ selectedNote, setSelectedNote ] = useState<ActivityRenderScheme | undefined>(undefined);
	
	const onNoteUpdated = () =>{
		setProfileFunction({launch: true, function: 'notes'})
		setModalNote({...modalNote, show:false})
	}

	const onRemoveClick = (note: ActivityRenderScheme) =>{
		setShowRemoveModal(true);
		setSelectedNote(note);
	}

	const removeNote = async () =>{
		if(selectedNote){
			setLoading(true);
			Activity.remove(selectedNote.objectId).then((result) =>{
				setLoading(false);
				setShowRemoveModal(false);
				setProfileFunction({launch: true, function: 'notes'})
			}).catch((error: ErrorScheme) =>{
				setLoading(false);
				setShowRemoveModal(false);
				setError(true, error.message);
			});
		}
	}

	return (
        <>
		<NoteModal 
			isVisible={modalNote.show}
			user={props.user}
			type={modalNote.type}
			data={modalNote.data}
			onSave={onNoteUpdated}
			onClose={() => setModalNote({...modalNote, show: false})}
		/>
		<Modal
			isVisible={showRemoveModal}
			title={'¿Seguro que quieres continuar?'}
			subtitle={'Al eliminar este comentario no se podrá recuperar'}
			onClose={() => setShowRemoveModal(false)}
			buttonProps={{
				children: 'Eliminar comentario',
				onClick: removeNote,
				loading: loading
			}}
		/>
        <Card 
			style={{position:"relative"}}
		>
            <TitleHeader>
                <Text type='p' weight={"semibold"}>
					Notas
				</Text>
				<Button 
					design={'image'} 
					icon={<Plus height={20} width={20} color={Color.text.primary}/>}
					onClick={()=>{setModalNote({type:"new", show:true})}}
					style={{position: 'absolute', right: 16, top: 12}}
				/>
            </TitleHeader>
            {props.notesArray.length > 0 ? props.notesArray.map((it, index:number) => (
				<Note 
					key={'note'+index} 
					style={{borderBottom: index+1 === props.notesArray.length ? 'none' : '1px solid '+Color.line.soft}}
					openModal={(note)=>{setModalNote({show:true, data:note, type:"edit"})}} 
					note={it}
					onRemove={() => onRemoveClick(it)}
				/>
			))
			:
				<Text type='p2' style={{color: Color.text.medium}}>
					No hay notas de momento
				</Text>
			}
        </Card>
        </>
	);
}

export default SectionNotes;
export interface Props{
    user: UserScheme,
	notesArray: Array<ActivityRenderScheme>
}