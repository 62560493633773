import React from "react";
import { Route, Routes } from "react-router-dom";

import Table from '../view/ambassador/Table';
import Profile from '../view/volunteer/profile/Profile';

const Ambassador = (props: any) =>{

	return (
		<Routes>
			<Route path={'/'} element={<Table/>}/>
			<Route path={'/perfil'} element={<Profile/>}/>
		</Routes> 
	);
}

export default Ambassador;
export interface Props{

}