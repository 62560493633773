import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import { Event, EventScheme, ProductScheme } from 'client-v2';

import { Color, Text, Button, Input, Modal, Menu } from '@adoptaunabuelo/react-components';
import * as icons from 'lucide-react';
import { Plus, X } from 'lucide-react';

const Row = styled.div`
    display: flex;
    margin-top: 8px;
`
const BulletContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: 32px;
`
const BulletCell = styled.div`
    position: relative;
    width: calc(33% - 41px);
    padding: 16px;
    border-radius: 12px;
    border: 1px solid ${Color.line.soft};
`
const AddCell = styled.div`
    width: 100%;
    margin-bottom: 32px;
    padding-bottom: 18px;
    border-bottom: 1px solid ${Color.line.soft};
`

const BulletModal = (props: Props) =>{

    const iconsT: any = icons;
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ newBullet, setNewBullet ] = useState<{title: string | undefined, subtitle: string | undefined, icon: any | undefined}>({
        title: undefined,
        subtitle: undefined,
        icon: undefined
    })
    const [ iconOptions, setIconOptions ] = useState<Array<{id: string, label: string}>>([]);
    const [ bullets, setBullets ] = useState<Array<any>>(props.event.bullet ? props.event.bullet : []);

    useEffect(() =>{
        const result = [];
        for(var icon in iconsT){
            if(!icon.endsWith('Icon') && !icon.endsWith('icons') && !icon.startsWith('Lucide')){
                const Icon = iconsT[icon];
                result.push({
                    id: icon.toString(),
                    label: icon.toString(),
                    icon: <Icon height={20} width={20}/>
                });
            }
        }
        setIconOptions(result);
    },[]);

    const onAddBullet = () =>{
        if(newBullet.title && newBullet.subtitle && newBullet.icon){
            setError(undefined);
            const temp = bullets;
            setBullets([...temp, {
                ...newBullet,
                icon: newBullet.icon.displayName
            }]);
        }
        else{
            setError('Para añadir una nueva celda, debes completar todos los datos.')
        }
    }

    const onDeleteBullet = (index: number) =>{
        const temp = [...bullets];
        temp.splice(index, 1);
        setBullets(temp);
    }

    const onSave = async () =>{
        if(bullets.length > 0){
            setLoading(true);
            Event.set(props.event.objectId, {
                bullet: bullets
            }).then((event) =>{
                props.onSave && props.onSave(event.data)
                setLoading(false);
            }).catch((e: string) =>{
                setError(e);
            });
        }
        else{
            setError('Debes añadir al menos una celda para poder continuar');
        }
    }

    return(
        <Modal
            isVisible={props.isVisible}
            onClose={() => {
                setError(undefined);
                setNewBullet({
                    title: undefined,
                    subtitle: undefined,
                    icon: undefined
                });
                setBullets(props.event.bullet ? props.event.bullet : []);
                props.onClose();
            }}
            title={'Editar qué incluye'}
            error={error}
            buttonProps={{
                children: 'Guardar',
                loading: loading,
                onClick: onSave
            }}
        >
            <AddCell
                key={'bullet-add'}
            >
                <Row
                    style={{alignItems: 'center', marginBottom: 12}}
                >
                    <Menu
                        id={'icons-menu'}
                        menuStyle={{height: 250, overflow: 'scroll'}}
                        position={'bottom-right'}
                        options={iconOptions}
                        Icon={newBullet.icon ? <newBullet.icon color={Color.text.primary}/> : <Plus color={Color.text.primary}/>}
                        onClick={(e) => setNewBullet({
                            ...newBullet,
                            icon: iconsT[e.label]
                        })}
                    />
                    <Input
                        type="text"
                        placeholder='Título'
                        containerStyle={{flex: 1, marginLeft: 12}}
                        onChange={(e) => setNewBullet({
                            ...newBullet,
                            title: e ?  e.target.value : undefined
                        })}
                    />
                </Row>
                <Input
                    type="text"
                    placeholder='Subtítulo'
                    containerStyle={{marginBottom: 12}}
                    onChange={(e) => setNewBullet({
                        ...newBullet,
                        subtitle: e ? e.target.value : undefined
                    })}
                />
                <Button
                    size='small'
                    onClick={onAddBullet}
                >
                    Añadir
                </Button>
            </AddCell>
            <BulletContainer>
                {bullets.map((item, index: number) =>{
                    const Icon = iconsT[item.icon];
                    return(
                        <BulletCell
                            key={'bullet-'+index}
                        >
                            <Icon style={{marginBottom: 12}}/>
                            <Text type='p2'>
                                {item.title}
                            </Text>
                            <Text type='c1' style={{color: Color.text.high}}>
                                {item.subtitle}
                            </Text>
                            <Button
                                style={{position: 'absolute', top: 0, right: 0}}
                                design={'image'}
                                icon={<X height={18} width={18}/>}
                                onClick={() => onDeleteBullet(index)}
                            />
                        </BulletCell>
                    )
                })}
            </BulletContainer>
        </Modal>
    )
}
export default BulletModal;
export interface Props{
    isVisible: boolean,
    event: EventScheme
    product: ProductScheme,
    onClose: () => void,
    onSave?: (event: EventScheme) => void
}