import React, { useState } from "react";
import styled from 'styled-components';
import { ActivityRenderScheme, ActivityScheme, UserScheme } from 'client-v2';
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';

import Card from '../../../components/Containers/Card'
import { Text, Filter } from '@adoptaunabuelo/react-components';
import History from '../../../components/Profile/History'

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 18px;
`;
const ShowMore = styled.div`
	width:100%;
 	display:flex;
 	margin-top:8px;
	cursor:pointer;
`;

const SectionHistory = (props: Props) =>{

	const dispatch = useDispatch();
	const { setProfileFunction } = bindActionCreators(actionsCreators, dispatch);

	const [ sortOptions ] = useState([
		{
			id: 'register',
			label: 'Registrado'
		},
		{
			id: 'call',
			label: 'Llamada'
		},
		{
			id: 'payment',
			label: 'Pago realizado'
		},
		{
			id: 'subscriptionActivated',
			label: 'Suscripción activada'
		},
		{
			id: 'subscriptionCanceled',
			label: 'Suscripción cancelada'
		},
		{
			id: 'match',
			label: 'Match'
		},
		{
			id: 'review',
			label: 'Valoración'
		}
	])
	const [ sortSelected, setSortSelected ] = useState<string | undefined>(undefined);
	const [ page, setPage ] = useState(0);
	const [ show, setShow ] = useState(true);

	const showMore = () =>{
		setPage(page+1);
		setProfileFunction({launch: true, function: 'activity', data: {
			page: page+1,
			type: sortSelected
		}});
	}

	const sortActivity = (selection: Array<any>) =>{
		const type = selection.length > 0 ? selection[0].id : undefined;
		setSortSelected(type);
		setPage(0);
		setProfileFunction({launch: true, function: 'activity', data: {
			page: 0,
			type: type,
			remove: true
		}});
	}

	return (
        <Card 
			style={{marginBottom:16}}
		>
            <TitleHeader>
                <Text type='p' weight={"semibold"}>
					Timeline de actividad
				</Text>
				<Filter
					id="HistoryFilter"
					type="single"
					placeholder="Filtros"
					onChange={sortActivity} 
					options={sortOptions}
					hideSearchBar={true}
					style={{position: 'absolute', right: 16, top: 12}}
				/>
            </TitleHeader>
            {props.activityArray && 
				<History activities={props.activityArray}/>
			}
            {show && 
				<ShowMore 
					onClick={showMore}
				>
                	<Text type='p' style={{textDecoration: 'underline'}}>
						ver más
					</Text>
            	</ShowMore>
			}
        </Card>
	);
}

export default SectionHistory;
export interface Props{
    user: UserScheme,
	activityArray: Array<ActivityRenderScheme>
}