import React, { useState } from "react";
import styled from 'styled-components';
import moment from 'moment';
import { ErrorScheme, Residence, ResidenceScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import { Text, Button, Checkbox, Input } from '@adoptaunabuelo/react-components';

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
`;
const Row2 = styled.div`
    display: flex;
	align-items: center;
	min-height: 40px;
`;

const SectionReporting = (props: Props) =>{

    const [ loading, setLoading ] = useState(false);
    const [ startDate, setStartDate ] = useState<moment.Moment | null>(moment().startOf('month'));
    const [ endDate, setEndDate ] = useState<moment.Moment | null>(moment().endOf('month'));
    const [ addExSubscriptor, setAddExSubscriptor ] = useState(false);

    const generateReporting = async () =>{
        if(startDate && endDate){
            setLoading(true);
            Residence.getReporting(props.residence.objectId, {
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                includeAll: addExSubscriptor
            }).then((result) =>{
                const contentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                fetch("data:"+contentType+";base64,"+result.data).then(res=>res.blob()).then(blob=>{
                    let link = window.document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = `Report_${props.residence.name}_${startDate.format('DD/MM/YY')}_${endDate.format('DD/MM/YY')}.xlsx`.replace(" ","_");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setLoading(false)
                })
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
            });
        }
    }

	return (
        <Card style={{marginBottom:16}}>
            <TitleHeader>
                <Text type='p' weight={"semibold"}>
                    Reporting
                </Text>
            </TitleHeader>
            <Row2>
                <div style={{width: '100%', marginRight: 12}}>
                    <Input
                        type="range-date"
                        style={{height: 50, width: '100%'}}
                        startDate={startDate}
                        endDate={endDate}
                        isOutsideRange={(date: moment.Moment) => moment().diff(date) < 0 ? true : false}
                        onChange={({startDate, endDate}: any) => {
                            setStartDate(startDate ? startDate.startOf('day') : null);
                            setEndDate(endDate ? endDate.endOf('day') : null);
                        }}
                    />
                </div>
            </Row2>
            <Row2
                style={{marginTop: 12, alignItems: 'center', justifyContent: 'space-between'}}
            >
                <Checkbox
                    elementStyle={{marginBottom: 0}}
                    options={[
                        { id: 'exSubscriptor', 'label': 'Incluir antiguos abuelos'}
                    ]}
                    type={'multiple'}
                    onChange={(a) => a.length > 0 ? setAddExSubscriptor(true) : setAddExSubscriptor(false)}
                />
                <Button
                    size={'small'}
                    loading={loading}
                    onClick={generateReporting}
                >
                    Generar
                </Button>
            </Row2>
        </Card>
	);
}

export default SectionReporting;
export interface Props{
    residence: ResidenceScheme
    onResidenceChanged: (residence: ResidenceScheme) => void
}