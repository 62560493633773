import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { PaymentMethod, AUA, UserScheme, ErrorScheme } from 'client-v2';
import { PaymentMethod as PaymentMethodStripe, SetupIntent } from "@stripe/stripe-js";
import Stripe from 'stripe';

import { Modal, Tabs, Payout, Checkbox, Input, ColorV2 } from '@adoptaunabuelo/react-components';
import { Landmark } from 'lucide-react';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 0px 24px;
`
const WebView = styled.iframe``

const PaymentMethodModal = (props: Props) =>{

    const payout = useRef<any>(null);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ setupIntent, setSetupIntent ] = useState<Stripe.SetupIntent | undefined>(undefined);
    const [ confirmUrl, setConfirmUrl ] = useState<string | undefined | null>(undefined);
    const [ paymentOption, setPaymentOption ] = useState<'sepa_debit' | 'card' | 'url'>('sepa_debit');
    const [ urlOption, setUrlOption ] = useState<string | undefined>(undefined);
    const [ iban, setIban ] = useState<string | undefined>(undefined);

    window.addEventListener('message', function(ev) {
        if(ev.data === '3DS-authentication-complete') {
			onConfirmModalClose();
        }
    }, false);

    const addPaymentMethod = async () =>{
        setLoading(true);
        const generatedPaymentMethod: PaymentMethodStripe = await payout.current?.getPaymentMethod();
        if(generatedPaymentMethod){
            PaymentMethod.initSetupIntent({
                userId: props.user.objectId, 
                cardId: generatedPaymentMethod.id,
                redirectUrl: "https://adoptaunabuelo.org/card-success"
            }).then((result) =>{
                const setupIntent = result.data as Stripe.SetupIntent;
                if(setupIntent.status === 'requires_action'){
                    setSetupIntent(setupIntent);
                    setConfirmUrl(setupIntent.next_action && setupIntent.next_action.redirect_to_url && setupIntent.next_action.redirect_to_url.url);
                }
                else
                   props.onSave();
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
                setError(error.message);
            });
        }
        else if(iban){
            PaymentMethod.initSetupIntent({
                userId: props.user.objectId,
                IBAN: iban,
                name: `${props.user.name} ${props.user.surname}`
            }).then(() =>{
                setLoading(false);
                props.onSave();
            }).catch((e: ErrorScheme) =>{
                setLoading(false);
                setError(e.message);
            });
        }
        else{
            setError('Parece que el método de pago que has añadido no es correcto');
            setLoading(false);
        }
    }

    const sendURL = async () =>{
        if(urlOption){
            setLoading(true);
            PaymentMethod.sendUrl({
                from: urlOption as any,
                userId: props.user.objectId,
                adminId: props.currentUser.objectId
            }).then(() =>{
                setLoading(false);
                props.onSave();
            }).catch((e: string) =>{
                setLoading(false);
                setError(e);
            });
        }
        else{
            setError("Seleccionar una opción antes de continuar");
        }
    }

    const onConfirmModalClose = async () =>{
		if(setupIntent){
			setConfirmUrl(undefined);
			PaymentMethod.finishSetupIntent(setupIntent.id).then((result) =>{
                if(result.data.status === 'succeeded')
					props.onSave();
				else
                    setError('No hemos podido verificar correctamente este método de pago. Inténtalo de nuevo.');
            }).catch((error: ErrorScheme) =>{
                setError(error.message);
            });
		}
		else
            setError('Ha ocurrido un error inesperado. Inténtalo de nuevo más tarde.');
	}

    return(
        <Modal
            isVisible={props.isVisible}
            onClose={props.onClose}
            title={'Añadir método de pago'}
            error={error}
            buttonProps={{
                onClick: paymentOption === 'url' ? sendURL : addPaymentMethod,
                loading: loading,
                children: paymentOption === 'url' ? "Enviar enlace" : "Añadir método de pago"
            }}
        >
            <Container>
                {confirmUrl ? 
                    <WebView
                        id={'web-iframe'}
                        src={confirmUrl} 
                        width="100%" 
                        height="500px"
                        style={{border: 'none'}}
                    />
                :
                    <>
                    <Tabs
                        style={{marginBottom: 12}}
                        options={[
                            {
                                id: 'sepa_debit',
                                title: 'IBAN'
                            },
                            {
                                id: 'card',
                                title: 'Tarjeta'
                            },
                            {
                                id: 'url',
                                title: 'Enviar enlace'
                            }
                        ]}
                        onChange={(op: any) => setPaymentOption(op.id)}
                    />
                    {paymentOption === 'url' ?
                        <Checkbox
                            type={'single'}
                            options={[
                                {
                                    id: 'sms',
                                    label: 'Enviar enlace por SMS'
                                },
                                {
                                    id: 'whatsapp',
                                    label: 'Enviar enlace por Whatsapp'
                                }
                            ]}
                            onChange={(op) => setUrlOption(op[0].id)}
                        />
                    : paymentOption === 'sepa_debit' ?
                        <Input
                            type='text'
                            placeholder='IBAN'
                            icon={<Landmark height={18} width={18} color={ColorV2.border.neutralHigh}/>}
                            onChange={(e) => {
                                setError(undefined);
                                const ibanRegex = /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/;
                                if(ibanRegex.test(e.target.value)){
                                    setIban(e.target.value);
                                    setError(undefined);
                                }
                                else{
                                    setIban(undefined);
                                    setError('Añade un IBAN válido');
                                }
                            }}
                        />
                    :
                        <Payout
                            ref={payout}
                            style={{width: '100%'}}
                            stripeKey={AUA.getStripeKey()}
                            paymentOption={paymentOption}
                            userData={{email: props.user.email}}
                        />
                    }
                    </>
                }
            </Container>    
        </Modal>
    )
}
export default PaymentMethodModal;
export interface Props{
    isVisible: boolean,
    user: UserScheme,
    currentUser: UserScheme,
    onClose: () => void,
    onSave: () => void
}