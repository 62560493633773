import React from 'react';
import { PaymentMethodScheme, UserScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import PaymentMethodSection from '../../../components/Profile/SectionPaymentMethod';

const SectionPaymentMethod = (props: Props) =>{

    return(props.user.type === 'volunteer' ?
        <Card 
			style={{marginBottom:16}}
		>
            <PaymentMethodSection
                {...props}
            />
        </Card>
    : null)
}
export default SectionPaymentMethod;
export interface Props{
    user: UserScheme,
    paymentMethods?: Array<PaymentMethodScheme>,
    onUserChanged: (user: UserScheme) => void
}