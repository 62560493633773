import React, { useState } from "react";
import styled from 'styled-components';
import { ErrorScheme, Residence, ResidenceScheme, UserScheme } from 'client-v2';
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';

import { Phone, Edit2, Plus, MoreHorizontal, Trash2 } from 'lucide-react'
import Card from '../../../components/Containers/Card'
import { Text, Button, Color, Modal, Menu } from '@adoptaunabuelo/react-components';
import EmergencyContactModal from "../../../components/Modals/EmergencyContactModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
`;
const Row = styled.div`
    position: relative;
    box-shadow: 0px 1px 0px #E5E5E5;
    padding: 8px 0px;
`
const Row2 = styled.div`
	display: flex;;
	align-items: center;
    padding: 4px 0px;
`;

const SectionEmergencyContact = (props: Props) =>{

    const dispatch = useDispatch();
    const { setCallUser } = bindActionCreators(actionsCreators, dispatch);

    const [ showModal, setShowModal ] = useState(false);
    const [ selectedContact, setSelectedContact ] = useState<any>(undefined);
    const [ showConfirmation, setShowConfirmation ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string | undefined>(undefined);

    const onMenuClick = (option: any, contact: any) =>{
        if(option.id === 'edit'){
            setShowModal(true)
            setSelectedContact(contact);
        }
        else if(option.id === 'remove'){
            setShowConfirmation(true);
            setSelectedContact(contact);
        }
        else if(option.id === 'call'){
            const user: any = {
                objectId: 'sec_'+props.residence.objectId,
                name: contact.name,
                surname: contact.surname,
                phone: contact.phone
            }
            setCallUser(user);
        }
    }

    const removeContact = async () =>{
        setLoading(true);
        const array = props.residence.contacts?.filter(item => item.name !== selectedContact.name && item.phone !== selectedContact.phone);
        Residence.set(props.residence.objectId, {
            contacts: array
        }).then((residence) =>{
            props.onResidenceChanged(residence.data);
            setSelectedContact(undefined);
            setShowConfirmation(false);
            setLoading(false);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
            setError(error.message);
        });
    }

	return (
        <>
        <EmergencyContactModal
            isVisible={showModal}
            residence={props.residence}
            contact={selectedContact}
            onClose={() => setShowModal(false)}
            onSave={(u?: UserScheme, r?: ResidenceScheme) => {
                setShowModal(false);
                if(r)
                    props.onResidenceChanged(r)
            }}
        />
        <Modal
            isVisible={showConfirmation}
            title='¿Seguro que quieres continuar?'
            subtitle="Si eliminas el contacto, no se podrá recuperar luego."
            error={error}
            onClose={() => setShowConfirmation(false)}
            buttonProps={{
                children: 'Eliminar contacto',
                loading: loading,
                onClick: removeContact
            }}
        />
        <Card style={{marginBottom:16}}>
            <TitleHeader>
                <Text type='p' weight={"semibold"}>
                    Contacto
                </Text>
                <Button
                    design="image"
                    icon={<Plus height={20} width={20} color={Color.text.primary}/>}
                    onClick={()=> {
                        setShowModal(true)
                        setSelectedContact(undefined);
                    }}
                    style={{position: 'absolute', right: 16, top: 12}}
                />
            </TitleHeader>
            {props.residence.contacts ? props.residence.contacts.map((contact, index) =>(
                <Row key={'contact-'+index}>
                    <Menu
                        id={'contact-menu-'+index}
                        position={'bottom-right'}
                        style={{position: 'absolute', top: 8, right: 0}}
                        menuStyle={{width: 'unset'}}
                        icon={<MoreHorizontal height={20} width={20} color={Color.text.high}/>}
                        options={[
                            {
                                id: 'call',
                                label: 'Llamar',
                                icon: <Phone height={20} width={20}/>
                            },
                            {
                                id: 'edit',
                                label: 'Editar',
                                icon: <Edit2 height={20} width={20}/>
                            },
                            {
                                id: 'remove',
                                label: 'Eliminar',
                                icon: <Trash2 height={20} width={20}/>
                            }
                        ]}
                        onClick={(o)=> onMenuClick(o, contact)}
                    />
                    <Text type='p' weight="medium">
                        {contact.name} {contact.surname}
                    </Text>
                    <Text type='c1'>
                        {contact.phone}
                    </Text>
                    {contact.email &&
                        <Text type='c1'>
                            {contact.email}
                        </Text>
                    }
                    {contact.position &&
                        <Text type='c1'>
                            {contact.position}
                        </Text>
                    }
                </Row>
            )) :
                <Text type="p2" style={{color: Color.text.high}}>
                    No hay persona de contacto
                </Text>
            }
        </Card>
        </>
	);
}

export default SectionEmergencyContact;
export interface Props{
    residence: ResidenceScheme
    onResidenceChanged: (residence: ResidenceScheme) => void
}