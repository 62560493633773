import React, { useState } from "react";
import styled from "styled-components";
import {
	Activity,
	ActivityRenderScheme,
	ActivityScheme,
	ErrorScheme,
	ResidenceScheme,
} from "client-v2";
import { useDispatch } from "react-redux";
import { actionsCreators } from "../../../redux";
import { bindActionCreators } from "redux";

import { Text, Color, Button, Modal } from "@adoptaunabuelo/react-components";
import { Plus } from "lucide-react";
import Card from "../../../components/Containers/Card";
import Note from "../../../components/Profile/Note";
import NoteModal from "../../../components/Modals/NoteModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;

const SectionNotes = (props: Props) => {
	const dispatch = useDispatch();
	const { setResidenceProfileFunction } = bindActionCreators(
		actionsCreators,
		dispatch
	);

	const [modalNote, setModalNote] = useState<{
		show: boolean;
		type: "edit" | "new";
		data?: any;
	}>({
		show: false,
		type: "new",
		data: undefined,
	});
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [selectedNote, setSelectedNote] = useState<any>(undefined);

	const onNoteUpdated = (activity: ActivityScheme) => {
		setModalNote({ ...modalNote, show: false });
		setResidenceProfileFunction({ launch: true, function: "notes" });
	};

	const onRemoveClick = (note: any) => {
		setShowRemoveModal(true);
		setSelectedNote(note);
	};

	const removeNote = async () => {
		setLoading(true);
		Activity.remove(selectedNote.objectId)
			.then((result) => {
				setLoading(false);
				setShowRemoveModal(false);
				setResidenceProfileFunction({
					launch: true,
					function: "notes",
				});
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setShowRemoveModal(false);
			});
	};

	return (
		<>
			<NoteModal
				isVisible={modalNote.show}
				residence={props.residence}
				type={modalNote.type}
				data={{
					...modalNote.data,
					data: {
						Residence: props.residence.objectId
					}
				}}
				onSave={onNoteUpdated}
				onClose={() => setModalNote({ ...modalNote, show: false })}
			/>
			<Modal
				isVisible={showRemoveModal}
				title={"¿Seguro que quieres continuar?"}
				subtitle={"Al eliminar este comentario no se podrá recuperar"}
				onClose={() => setShowRemoveModal(false)}
				buttonProps={{
					children: "Eliminar comentario",
					onClick: removeNote,
					loading: loading,
				}}
			/>
			<Card style={{ marginBottom: 16 }}>
				<TitleHeader>
					<Text type="p" weight={"semibold"}>
						Notas
					</Text>
					<Button
						style={{ position: "absolute", right: 16, top: 12 }}
						design={"image"}
						icon={
							<Plus
								height={20}
								width={20}
								color={Color.text.primary}
							/>
						}
						onClick={() => {
							setModalNote({ type: "new", show: true });
						}}
					/>
				</TitleHeader>
				{props.notes.length > 0 ? (
					props.notes.map((note) => (
						<Note
							key={"note_" + note.objectId}
							openModal={(note) => {
								setModalNote({
									show: true,
									data: note,
									type: "edit",
								});
							}}
							note={note}
							onRemove={() => onRemoveClick(note)}
						/>
					))
				) : (
					<Text type="p2" style={{ color: Color.text.high }}>
						No hay notas de momento
					</Text>
				)}
			</Card>
		</>
	);
};
export default SectionNotes;
export interface Props {
	notes: ActivityRenderScheme[];
	residence: ResidenceScheme;
	onResidenceChanged: (residence: ResidenceScheme) => void;
}
