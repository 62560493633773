import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CSVReader from "react-csv-reader";
import { User, CoorporateScheme, ErrorScheme } from 'client-v2';
import { json2csv } from 'json-2-csv';

import { Modal, Text, Color, Checkbox, Dropdown } from '@adoptaunabuelo/react-components';

const Container = styled.div`
    padding: 12px 0px 24px;
`
const IdSection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${Color.line.soft};
    margin: 12px 0px;
    padding: 12px 24px;
`

const LoadVolunteersModal = (props: Props) =>{

    const [ loading, setLoading ] = useState(false);
    const [ csvKeys, setCsvKeys ] = useState<Array<{id: string, title: string}>>([]);
    const [ csvData, setCsvData ] = useState<Array<Object>>([]);
    const [ trainingSelected, setTrainingSelected ] = useState(false);
    const [ selection, setSelection ] = useState<{name?: string, surname?: string, phone?: string, email?: string}>({});
    const [ error, setError ] = useState<string | undefined>(undefined);
    const neededKeys = [
        {
            id: 'name',
            title: 'Nombre'
        },
        {
            id: 'surname',
            title: 'Apellidos'
        },
        {
            id: 'email',
            title: 'Email'
        },
        {
            id: 'phone',
            title: 'Teléfono'
        }
    ]

    useEffect(() =>{
        if(!props.isVisible){
            setCsvData([]);
            setSelection({});
            setCsvKeys([]);
            setTrainingSelected(false);
            setLoading(false);
            setError(undefined);
        }
    },[props.isVisible]);

    const handleForce = (data: Array<any>) => {
        setCsvKeys(Object.keys(data[0]).map(item => ({id: item, title:item})));
        setCsvData(data);
    }

    const onSelectionChange = (newSelection: any, id: string) =>{
        setSelection({
            ...selection,
            [id]: newSelection.id
        })
    }

    const checkSelection = async () =>{
        setLoading(true);
        const array: Array<any> = csvData.map((item: any) => ({
            name: item[selection.name ? selection.name : ''],
            surname: item[selection.surname ? selection.surname : ''],
            phone: item[selection.phone ? selection.phone : ''],
            email: item[selection.email ? selection.email : ''],
            state: trainingSelected ? 'training' : 'waiting'
        }))
        User.upload({
            coorporateId: props.coorporate.objectId,
            users: array
        }).then((result) =>{
            if(result.data.errors && result.data.errors.length > 0){
                //Generate CSV
                const json: any = result.data.errors;
                const csv = json2csv(json, {
                    emptyFieldValue: ''
                });

                //Download file
                const url = window.URL.createObjectURL(new Blob([csv]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'errores.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
            setLoading(false);
            props.onFinish();
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
            setError(error.message);
        });
    }

    return(
        <Modal
            title={'Carga de voluntarios'}
            isVisible={props.isVisible}
            onClose={props.onClose}
            error={error}
            buttonProps={{
                children: 'Iniciar carga',
                disabled: (selection.name && selection.surname && selection.phone && selection.email) ? false : true,
                loading: loading,
                onClick: checkSelection
            }}
        >
            <Container>
            <CSVReader
                cssClass="react-csv-input"
                onFileLoaded={handleForce}
                parserOptions={{
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    transformHeader: (header: any) => header.toLowerCase().replace(/\W/g, "_")
                }}
            />
            {csvData.length > 0 &&
                neededKeys.map((key, index) =>(
                    <IdSection
                        key={'id_section_'+index}
                    >
                        <Text type='p'>
                            {key.title}
                        </Text>
                        <Dropdown
                            id={key.id+'_selector'}
                            style={{flex: 'none'}}
                            menuStyle={{right: 0, left: 'unset', maxWidth: 200}}
                            placeholder={'-'}
                            type={'single'}
                            options={csvKeys}
                            optionStyle={{right: 0}}
                            onChange={(selection) => onSelectionChange(selection[0], key.id)}
                        />
                    </IdSection>
                ))
            }
            {csvData.length > 0 &&
                <Checkbox
                    style={{marginTop: 32}}
                    type={'multiple'}
                    options={[{id: 'training', label: 'Los voluntarios deben completar la formación en la app'}]}
                    onChange={() => setTrainingSelected(!trainingSelected)}
                />
            }
            </Container>
        </Modal>
    )
}
export default LoadVolunteersModal;
export interface Props{
    isVisible: boolean,
    coorporate: CoorporateScheme,
    onClose: () => void,
    onFinish: () => void
}