import React, { useState } from "react";
import styled from 'styled-components';
import { EventScheme, ProductScheme } from 'client-v2';

import Card from '../../../components/Containers/Card';
import InfoModal from "./InfoModal";
import { Color, Text, Button, Label } from '@adoptaunabuelo/react-components';
import { Pen } from 'lucide-react';

const Row = styled.div`
    display: flex;
    flex-direction: row;
`
const ImageView = styled.div`
    position: relative;
    height: 200px;
    margin: -21px -16px 18px;
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
`
const Image = styled.img`
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-color: ${Color.background.soft};
`

const SectionMain = (props: Props) =>{

    const [ showModal, setShowModal ] = useState(false);

	return (
        <Card style={{marginBottom:16}}>
            <InfoModal
                isVisible={showModal}
                event={props.event}
                product={props.product}
                onClose={() => setShowModal(false)}
                onSave={() =>{
                    setShowModal(false);
                }}
            />
            <ImageView>
                <Image
                    src={props.event.image?.url}
                />
                <Button
                    style={{position: 'absolute', top: 8, right: 8, backgroundColor: 'white', borderRadius: 40}}
                    design="image"
                    icon={<Pen height={20} width={20}/>}
                    onClick={() => setShowModal(true)}
                />
                {!props.event.image &&
                    <Label
                        style={{position: 'absolute', top: 24, left: 24, backgroundColor: Color.status.color.warning}}
                        text="Falta añadir imagen"
                    />
                }
            </ImageView>
            <Text type='h3' weight={"semibold"}>
				{props.product.title}
			</Text>
            <Row style={{marginTop: 8}}>
                <Label
                    text={props.product.price === 0 ? 'Gratis' : props.product.price+'€'}
                />
                <Label
                    style={{marginLeft: 8}}
                    text={props.product.limit+' plazas'}
                />
            </Row>
            <Text type='p' style={{marginTop: 12}}>
				{props.event.description}
			</Text>
        </Card>
	);
}

export default SectionMain;
export interface Props{
    product: ProductScheme,
    event: EventScheme
}