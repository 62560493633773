import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Challenge, ChallengeScheme, ErrorScheme, UserScheme } from 'client-v2';
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';

import Row from '../../../components/Containers/Row'
import Col from '../../../components/Containers/Col'
import AdminBar from "../../../components/Nav/AdminBar";
import Spinner from '../../../components/Spinner/Spinner';
import SectionMain from './MainSection';
import DetailSection from './DetailSection';
import TimelineSection from './TimelineSection';
import DistributionSection from './DistributionSection';
import UsersSection from './UsersSection';

import { Button } from '@adoptaunabuelo/react-components';
import { Eye } from 'lucide-react';

const Container = styled.div`
	display: flex;
	width: -webkit-fill-available;
	flex-direction: column;
	padding: 0px 32px 32px;
`;

const Profile = (props: Props) =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
	const { setError } = bindActionCreators(actionsCreators, dispatch);

    const [ loading, setLoading ] = useState(false);
    const [ challenge, setChallenge ] = useState<ChallengeScheme | undefined>(undefined);
    const [ users, setUsers ] = useState<Array<{user: UserScheme, createdAt: string, amount: number}>>([]);

    useEffect(() =>{
		const userId = new URLSearchParams(window.location.search).get("id");
        if(userId){
            setLoading(true);
            Challenge.getById(userId).then((challenge)=>{
                setChallenge(challenge.data);
                Challenge.getSupporters(challenge.data.objectId, {dash: true}).then((result) =>{
                    setUsers(result.data as any);
                    setLoading(false);
                }).catch((e: ErrorScheme) =>{
                    setLoading(false);
                    setError(true, e.message);
                })
            }).catch((e: ErrorScheme) =>{
                setLoading(false);
                setError(true, e.message);
            });
        }
	},[]);

    return(loading ? <Spinner/> :
        <Container>
            {challenge &&
                <>
                <AdminBar
                    style={{marginBottom: 16}}
                    title="Detalle"
                    goBack={() => navigate(-1)}
                    RightBar={
                        <Row>
                            <Button
                                size={'small'}
                                onClick={() => {
                                    const url = challenge.type === 'birthday' ? 'https://adoptaunabuelo.org/cumpleanios?id=' : 'https://adoptaunabuelo.org/reto?id=';
                                    window.open(url+challenge.objectId, '_blank')
                                }}
                                icon={<Eye/>}
                            >
                                Vista previa
                            </Button>
                        </Row>
                    }
                />
                <Row style={{width:"100%"}}>
                    <Col style={{width:"33%"}}>
                        <SectionMain
                            challenge={challenge}
                            setChallenge={setChallenge}
                        />
                        <DetailSection
                            challenge={challenge}
                            setChallenge={setChallenge}
                        />
                    </Col>
                    <Col style={{width:"33%", marginLeft: 16, marginRight: 16}}>
                        {challenge.type !== 'signature' &&
                            <DistributionSection
                                challenge={challenge}
                                setChallenge={setChallenge}
                            />
                        }
                        {challenge.type !== 'birthday' &&
                            <TimelineSection
                                challenge={challenge}
                                setChallenge={setChallenge}
                            />
                        }
                    </Col>
                    <Col style={{width:"33%"}}>
                        <UsersSection
                            users={users}
                            challenge={challenge}
                        />
                    </Col>
                </Row>
                </>
            }
        </Container>
    )
}
export default Profile;
export interface Props{

}