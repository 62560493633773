import React, { useState } from "react";
import styled from 'styled-components';
import { UserScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import { Button, Color, Text } from '@adoptaunabuelo/react-components';
import { Edit2 } from 'lucide-react';
import HistoryModal from '../../../components/Modals/HistoryModal'

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 13px;
	align-items: center;
`;
const ShowMore = styled.div`
	width:100%;
 	display:flex;
 	margin-top:8px;
	cursor:pointer;
`;

const SectionBiography = (props: Props) =>{

	const [ showHistoryModal, setShowHistoryModal ] = useState(false);
	const [ limitHistory , setLimitHistory ] = useState(true)

	return (
        <>
		<HistoryModal
			isVisible={showHistoryModal}
			user={props.user}
			onClose={() => setShowHistoryModal(false)}
			onSave={(user) =>{
				setShowHistoryModal(false)
				props.onUserChanged(user)
			}}
		/>
        {props.user.type ==="grandpa" && 
            <Card style={{marginBottom:8}}>
                <TitleHeader>
                    <Text type='p' weight={"semibold"} style={{backgroundColor: !props.user.live?.history ? Color.status.color.warning : 'transparent'}}>
						Biografía
					</Text>
					<Button
						design="image"
						onClick={()=>setShowHistoryModal(true)}
						icon={<Edit2 height={20} width={20}/>}
						style={{position: 'absolute', right: 16, top: 12}}
					/>
                </TitleHeader>
                <Text type='p2' style={{whiteSpace: "pre-wrap"}}>
                    {props.user.live && props.user.live.history ? (props.user.live.history && limitHistory && props.user.live.history.length>300 ? props.user.live.history.substring(0,300) +"..." : props.user.live.history): 'Sin datos'}
                </Text>
                {props.user.live && props.user.live.history && props.user.live && props.user.live.history.length>300 && 
					<ShowMore 
						onClick={()=>{setLimitHistory(!limitHistory)}}
					>
						<Text type='p' style={{textDecoration: 'underline'}}>
							{limitHistory ?"ver más":"ver menos"}
						</Text>
                	</ShowMore>
				}
            </Card>
        }
        </>
	);
}

export default SectionBiography;
export interface Props{
    user: UserScheme,
	onUserChanged: (user: UserScheme) => void
}