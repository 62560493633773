import React, { useState } from "react";
import styled from 'styled-components';
import { EventScheme, ProductScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import { Color, Text, Button, Label } from '@adoptaunabuelo/react-components';
import { Pen } from 'lucide-react';
import TimelineModal from "./TimelineModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const BulletContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
`
const Cell = styled.div`
    display: flex;
    flex-direction: row;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
`
const Line = styled.div`
    height: 100%;
    min-height: 25px;
    border-right: 1px solid ${Color.line.soft};
`

const SectionMain = (props: Props) =>{

	const [ loading, setLoading ] = useState(false);
    const [ showModal, setShowModal ] = useState(false);

	return (
        <Card style={{marginBottom:16}}>
            <TimelineModal
                isVisible={showModal}
                event={props.event}
                product={props.product}
                onClose={() => setShowModal(false)}
                onSave={() =>{
                    setShowModal(false);
                }}
            />
            <TitleHeader>
				<Text type='p' weight={"semibold"}>
					Horario
				</Text>
				<Button
                    style={{position: 'absolute', right: 16, top: 12}} 
					design={'image'} 
					icon={<Pen height={20} width={20}/>}
                    loading={loading}
                    onClick={() => setShowModal(true)}
				/>
			</TitleHeader>
            <BulletContainer>
                {props.event.timeline ? props.event.timeline?.map((item, index) =>(
                        <Cell
                            key={'timing-'+index}
                        >
                            <Column>
                                <Text type='p' style={{backgroundColor: Color.background.soft, width: 55, borderRadius: 6, display: 'flex', justifyContent: 'center'}}>
                                    {item.time}
                                </Text>
                                {index+1 !== props.event.timeline?.length &&
                                    <Line/>
                                }
                            </Column>
                            <Text type='p' style={{marginBottom: 16}}>
                                {item.title}
                            </Text>
                        </Cell>
                    ))
                :
                    <Label
                        style={{backgroundColor: Color.status.color.warning}}
                        text="Falta añadir el horario"
                    />
                }
            </BulletContainer>
        </Card>
	);
}

export default SectionMain;
export interface Props{
    product: ProductScheme,
    event: EventScheme
}