import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Event, Product, EventScheme, ProductScheme } from 'client-v2';
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';

import Row from '../../../components/Containers/Row'
import Col from '../../../components/Containers/Col'
import AdminBar from "../../../components/Nav/AdminBar";
import MainSection from './MainSection';
import DetailSection from './DetailSection';
import BulletSection from './BulletSection';
import TimelineSection from './TimelineSection';
import UsersSection from './UsersSection';
import Spinner from '../../../components/Spinner/Spinner';

import { Button } from '@adoptaunabuelo/react-components';

const Container = styled.div`
	display: flex;
	width: -webkit-fill-available;
	flex-direction: column;
	padding: 0px 32px 32px;
`;

const Profile = (props: Props) =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
	const { setError } = bindActionCreators(actionsCreators, dispatch);

    const [ loading, setLoading ] = useState(false);
    const [ product, setProduct ] = useState<ProductScheme | undefined>(undefined);
    const [ event, setEvent ] = useState<EventScheme | undefined>(undefined);

    useEffect(() =>{
        setLoading(true);
		const eventId = new URLSearchParams(window.location.search).get("id");
        if(eventId){
            Product.getById(eventId, {
                include: ["Event"]
            }).then((product)=>{
                if(product.data.Event){
                    setProduct(product.data);
                    setEvent(product.data.Event);
                    setLoading(false);
                }
                else{
                    setLoading(false);
                    setError(true, 'No event for this product');
                }
            }).catch((e: string) =>{
                setLoading(false);
                setError(true, e);
            });
        }
	},[]);

    return(loading ? <Spinner/> :
        <Container>
            {(product && event) &&
                <>
                <AdminBar
                    style={{marginBottom: 16}}
                    title="Detalle"
                    goBack={() => navigate(-1)}
                    RightBar={
                        <Button
                            size={'small'}
                            onClick={() => window.open('https://adoptaunabuelo.org/evento?id='+product.objectId, '_blank')}
                        >
                            Vista previa
                        </Button>
                    }
                />
                <Row style={{width:"100%"}}>
                    <Col style={{width:"33%"}}>
                        <MainSection
                            product={product}
                            event={event}
                        />
                        <DetailSection
                            product={product}
                            event={event}
                        />
                    </Col>
                    <Col style={{width:"33%", marginLeft: 16, marginRight: 16}}>
                        <BulletSection
                            product={product}
                            event={event}
                        />
                        <TimelineSection
                            product={product}
                            event={event}
                        />
                    </Col>
                    <Col style={{width:"33%"}}>
                        <UsersSection
                            product={product}
                        />
                    </Col>
                </Row>
                </>
            }
        </Container>
    )
}
export default Profile;
export interface Props{

}