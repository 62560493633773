import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

import { ArrowLeft } from 'lucide-react';
import { Text, Button, Color, ColorV2 } from '@adoptaunabuelo/react-components';

const Container = styled.div<{backgroundColor?: string}>`
    display: flex;
    align-items: center;
    width: auto;
    height: 64px;
    min-height: 64px;
    position: sticky;
    top: 0px;
    z-index: 800;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : ColorV2.surface.background};
`;
const RightView = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`

const AdminBar = (props: Props) =>{

    return(
        <Container style={props.style} backgroundColor={props.backgroundColor}>
            {props.goBack &&
                <Button
                    design='image'
                    icon={<ArrowLeft height={20} width={20}/>}
                    onClick={props.goBack}
                    style={{marginRight: 8}}
                />
            }
            <Text type='p' style={{color: Color.text.full, fontWeight: 500}}>
                {props.title}
            </Text>
            <RightView>
                {props.RightBar}
            </RightView>
        </Container>
    )
}
export default AdminBar;
export interface Props extends ComponentPropsWithoutRef<"div">{
    title: string,
    backgroundColor?: string,
    goBack?: () => void,
    RightBar?: JSX.Element
}