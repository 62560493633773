import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import parse from 'html-react-parser';
import { ActivityRenderScheme, Constant } from 'client-v2';

import { Color, Text, Avatar } from '@adoptaunabuelo/react-components';
import { Phone, Bell } from 'lucide-react';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Cell = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center; 
    margin-top: 4px;
`
const Col = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`
const CallView = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${Color.background.soft};
    padding: 6px 8px;
    border-radius: 6px;
    width: fit-content;
    gap: 8px;
    margin-top: 6px;
    justify-content: center;
`
const Separator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px;
`
const Line = styled.div`
    display: flex;
    flex: 1;
    height: 1px;
    border-bottom: 1px dashed ${Color.line.soft};
`
const AudioPlayer = styled.audio`
    height: 32px;
    margin: 0px -8px;
    ::-webkit-media-controls-current-time-display{
        font-family: Poppins;
        font-size: 12px;
    }
    ::-webkit-media-controls-time-remaining-display{
        font-family: Poppins;
        font-size: 12px;
    }
`

const HistoryTask = (props: Props) =>{

    const states = Constant.adminTaskState();

    return(
        <Container>
            {props.activities.map(item => {
                if(item.type === 'adminTaskAdmin') {
                    return (
                        <Separator
                            key={'cell-separator-'+item.objectId}
                        >
                            <Line style={{marginRight: 8}}/>
                            <Text 
                                type='c1' 
                                style={{color: Color.text.high, display: 'flex', marginRight: 4}}
                            >
                                {item.subtitle}
                            </Text>
                            <Text 
                                type='c1' 
                                style={{color: Color.text.primary, backgroundColor: Color.background.primaryLow, padding: '2px 6px', borderRadius: 6}}
                            >
                                {item.To?.name} {item.To?.surname}
                            </Text>
                            <Line style={{marginLeft: 8}}/>
                        </Separator>
                    )
                }
                else if(item.type === 'adminTaskState') {
                    const state = states.filter((temp: any) => temp.id === item.state)[0]
                    return (
                        <Separator
                            key={'cell-separator-'+item.objectId}
                        >
                            <Line style={{marginRight: 8}}/>
                            <Text 
                                type='c1' 
                                weight='semibold'
                                style={{color: Color.text.high, display: 'flex', marginRight: 4}}
                            >
                                {item.Admin?.name}
                            </Text>
                            <Text 
                                type='c1' 
                                style={{color: Color.text.high, display: 'flex', marginRight: 4}}
                            >
                                {item.subtitle}
                            </Text>
                            <Text 
                                type='c1'
                                style={{color: state.color, backgroundColor: state.backgroundColor, padding: '2px 6px', borderRadius: 6}}
                            >
                                {state.title}
                            </Text>
                            <Line style={{marginLeft: 8}}/>
                        </Separator>
                    )
                }
                else return(
                    <Cell
                        key={'cell-'+item.objectId}
                    >
                        <Avatar
                            style={{marginRight: 12, height: 40, width: 40}}
                            name={item.Admin?.name}
                            icon={item.Admin?.image}
                        />
                        <Col>
                            <Row>
                                <Text type='p2' weight='semibold' style={{marginRight: 6}}>
                                    {item.Admin?.name}
                                </Text>
                                <Text type='c1' style={{color: Color.text.high}}>
                                    {moment(item.timestamp).format('DD/MM/YYYY HH:mm')}
                                </Text>
                            </Row>
                            <Text type='p2'>
                                {item.type === 'note' ? parse(
                                    '<style>p{margin: 0px}</style>'+item.subtitle
                                ) : item.type === 'call' ? (
                                    <CallView>
                                        <Row
                                            style={{gap: 8}}
                                        >
                                            <Phone height={16} width={16} color={Color.text.primary}/>
                                            <Text type='c1' weight='medium'>
                                                Duración {item.duration} min
                                            </Text>
                                            <Text type='c1' style={{color: Color.text.high}}>
                                                Hasta {moment(item.start).format('HH:mm')}
                                            </Text>
                                        </Row>
                                        {item.recordingUrl &&
                                            <AudioPlayer
                                                src={item.recordingUrl} 
                                                controls={true}
                                            />
                                        }
                                    </CallView>
                                ) : item.subtitle}
                            </Text>
                        </Col>
                    </Cell>
            )})}
        </Container>
    )
}
export default HistoryTask;
export interface Props{
    activities: Array<ActivityRenderScheme>
}