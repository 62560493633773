import React, { useEffect, useState } from "react";
import {
	Constant,
	User,
	AdminTask,
	AdminTaskScheme,
	UserScheme,
} from "client-v2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";

import SearchTable from "../../components/Table/SearchTable";
import { List, Columns } from "lucide-react";
import * as icons from "lucide-react";
import { Button } from "@adoptaunabuelo/react-components";

const Table = (props: Props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { setTaskTableFilters, setAutodial } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const taskTableFilters = useSelector(
		(state: State) => state.taskTableFilters
	);
	const role = useSelector((state: State) => state.role);
	const autodial = useSelector((state: State) => state.autodial);
	const currentUser: UserScheme = useSelector(
		(state: State) => state.currentUser
	);

	const states = Constant.adminTaskState().map((item) => ({
		...item,
		label: item.title,
	}));
	const priority = Constant.adminTaskPriority().map((item) => ({
		...item,
		label: item.title,
	}));

	const [loading, setLoading] = useState(false);
	const [taskCount, setTaskCount] = useState(0);
	const [taskArray, setTaskArray] = useState<
		(Omit<AdminTaskScheme, "priority"> & {
			priority: {
				lucide: any;
				id: string;
				title: string;
				icon: string;
				color: string;
			};
		})[]
	>([]);
	const [admins, setAdmins] = useState<Array<any>>([]);
	const [groups, setGroups] = useState<Array<any>>([]);
	const [types, setTypes] = useState<Array<any>>([]);
	const moreOptions = [
		{
			id: "isSubscriptor",
			label: "Mostrar donantes",
		},
		{
			id: "isCoorporate",
			label: "Mostrar corporativos",
		},
	];

	useEffect(() => {
		getAdminTask(taskTableFilters);
		getAdmins();
		getGroups();
		getTypes();
	}, []);

	const getGroups = () => {
		setGroups([
			{
				id: "support",
				label: "Story Builders",
			},
			{
				id: "sales",
				label: "Ventas",
			},
		]);
	};

	const getAdmins = async () => {
		User.get({
			type: "admin",
			data: {
				department: ["support", "sales"],
			},
			active: true,
		}).then((result) => {
			const temp = result.data.map((item) => ({
				...item,
				id: item.objectId,
				label: item.name + " " + item.surname,
			}));
			setAdmins([{ id: "non_assigned", label: "Sin asignar" }, ...temp]);
		});
	};

	const getTypes = () => {
		const types = Constant.adminTaskType();
		let temp = [];
		if (currentUser.data?.department === "sales") {
			temp = types.sales.map((item) => ({
				id: item.id,
				label: item.title,
			}));
		} else if (currentUser.data?.department === "support") {
			temp = types.support.map((item) => ({
				id: item.id,
				label: item.title,
			}));
		} else {
			temp = [
				...types.sales.map((item) => ({
					id: item.id,
					label: item.title,
				})),
				...types.support.map((item) => ({
					id: item.id,
					label: item.title,
				})),
			];
		}
		setTypes(temp);
	};

	const getAdminTask = async (filter: any) => {
		setLoading(true);
		const priorityArray = Constant.adminTaskPriority();
		AdminTask.get({
			...filter,
			limit: 20,
			descending: "priorityCode",
		})
			.then((result) => {
				setTaskCount(result.pagination.count);
				const temp = result.data.map((item) => {
					//Get priority icon
					const priority = priorityArray.filter(
						(item2) => item2.id === item.priority
					)[0];
					const getKeyValue =
						(key: string) => (obj: Record<string, any>) =>
							obj[key];
					const icon = getKeyValue(priority.icon)(icons);

					return {
						...item,
						priority: {
							...priority,
							lucide: icon,
						},
					};
				});
				setTaskArray(temp);
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
			});
	};

	const onRowClick = (row: any, target?: string) => {
		if (target === "_blank")
			window.open("/tareas/" + row.objectId, "_blank");
		else navigate("/tareas/" + row.objectId);
	};

	const onFiltersChange = (result: any) => {
		let temp = result;
		if (result.other) {
			temp = {
				...taskTableFilters,
				...result,
				other: undefined,
				isSubscriptor: result.other === "isSubscriptor" ? true : false,
				isCoorporate: result.other === "isCoorporate" ? true : false,
			};
		}
		setTaskTableFilters(temp);
		getAdminTask(temp);
	};

	const createAutodial = () => {
		const temp = taskArray.map((item) => {
			return {
				adminTask: item,
				user: item.To,
			};
		});
		setAutodial(true, temp);
	};

	const renderButton = () => {
		return (
			role.task.autodial && (
				<Button
					size="small"
					style={{ marginLeft: 18, height: 42 }}
					icon={<icons.Play height={20} width={20} fill={"white"} />}
					disabled={autodial.play}
					onClick={createAutodial}
				>
					{autodial.play ? "En llamada" : "Iniciar llamadas"}
				</Button>
			)
		);
	};

	return (
		<>
			<SearchTable
				title="Tareas"
				loading={loading}
				count={taskCount}
				filters={
					role.task.filters
						? [
								{
									id: "priority",
									placeholder: "Prioridad",
									options: priority,
									selectedOptions: taskTableFilters.priority,
									hideSearchBar: true,
									type: "multiple",
								},
								{
									id: "state",
									placeholder: "Estado",
									options: states,
									selectedOptions: taskTableFilters.state,
									hideSearchBar: true,
									type: "multiple",
								},
								{
									id: "type",
									placeholder: "Tipo",
									options: types,
									type: "multiple",
									selectedOptions: taskTableFilters.type,
								},
								{
									id: "adminId",
									placeholder: "Admin",
									type: "single",
									options: admins,
									selectedOptions: taskTableFilters.adminId,
								},
								{
									id: "group",
									placeholder: "Equipo",
									type: "single",
									options: groups,
									selectedOptions: taskTableFilters.group,
									hideSearchBar: true,
								},
								{
									id: "other",
									placeholder: "Otras opciones",
									type: "single",
									options: moreOptions,
									selectedOptions: taskTableFilters.other,
									hideSearchBar: true,
								},
						  ]
						: []
				}
				columns={[
					{
						id: "priority",
						title: "",
					},
					{
						id: "typeTitle",
						title: "Asunto",
					},
					{
						id: "state",
						title: "Estado",
					},
					{
						id: "To",
						title: "Solicitante",
					},
					{
						id: "To.isSubscriptor",
						title: "Dona",
					},
					{
						id: "To.city",
						title: "Ciudad",
					},
					{
						id: "coorporate",
						title: "Origen",
					},
					{
						id: "createdAt",
						title: "Solicitado",
					},
				]}
				switchOptions={[
					{
						id: "list",
						icon: <List />,
					},
					{
						id: "columns",
						icon: <Columns />,
					},
				]}
				disabled={!role.task.clickable}
				ButtonView={renderButton}
				rows={taskArray}
				page={taskTableFilters.page}
				onRowClick={onRowClick}
				onFiltersChange={onFiltersChange}
			/>
		</>
	);
};
export default Table;
export interface Props {}
