import React, { useState } from 'react';
import styled from 'styled-components';
import { Constant, AdminTaskScheme, UserScheme } from 'client-v2';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';

import Card from '../../../components/Containers/Card'
import { Text, Color, Filter, Button } from '@adoptaunabuelo/react-components';
import * as icons from 'lucide-react';

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 16px;
    align-items: center;
`;
const Cell = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    border-bottom: 1px solid ${Color.line.soft};
    padding: 12px 0px;
    cursor: pointer;
    &:hover{
        background-color: ${Color.background.soft};
    }
`
const ShowMore = styled.div`
	width:100%;
 	display:flex;
 	margin-top:8px;
	cursor:pointer;
`;

const SectionTickets = (props: Props) =>{

    const dispatch = useDispatch();
	const { setProfileFunction } = bindActionCreators(actionsCreators, dispatch);
    const navigate = useNavigate();

    const priorityArray = Constant.adminTaskPriority();
    const statesArray = Constant.adminTaskState().map((item: any) => ({...item, label: item.title}));
    const [ selectedOption, setSelectedOption ] = useState<Array<any>>([{id: "pending"}, {id: "inProgress"}]);
    const [ show, setShow ] = useState(false);

    const onCellClick = (item: AdminTaskScheme) =>{
        navigate('/tareas/'+item.objectId);
    }

    const onFilterChange = (op: Array<any>) =>{
        setSelectedOption(op);
        setProfileFunction({launch: true, function: 'admin-task', data: {
			state: op.map(item => item.id),
            limit: show ? undefined : 6
		}});
    }

    const onShowClick = () =>{
        setShow(!show);
        setProfileFunction({launch: true, function: 'admin-task', data: {
            state: selectedOption.map(item => item.id),
            limit: show ? 6 : undefined
        }});
    }

    return(
        <Card 
			style={{position:"relative", marginBottom:16}}
		>
            <TitleHeader>
                <Text type='p' weight={"semibold"}>
					Tickets
				</Text>
                <Filter
                    id='admin-task-filter'
                    style={{position: 'absolute', right: 16, top: 12}}
                    placeholder='Estado'
                    position={'bottom-left'}
                    type={'multiple'}
                    options={statesArray}
                    selectedOptions={selectedOption}
                    onChange={onFilterChange}
                />
            </TitleHeader>
            {props.adminTasks.length > 0 ? props.adminTasks.map((item, index) =>{
                const priority = priorityArray.filter((item2: any) => item2.id === item.priority)[0];
                const getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key];
                const Icon = getKeyValue(priority.icon)(icons);
                return(
                    <Cell
                        key={'ticket-'+index}
                        onClick={() => onCellClick(item)}
                    >
                        <Icon height={24} width={24} color={priority.color} style={{marginRight: 4}}/>
                        <Text type='p2' style={{display: 'flex', flex: 1}}>
                            {item.typeTitle}
                        </Text>
                    </Cell>
                )
            }) :
                <Text type='p'>
                    No tiene tickets abiertos
                </Text>
            }
            <ShowMore
                onClick={onShowClick}
            >
                <Text type='p' style={{textDecoration: 'underline'}}>
                    {show ? 'Mostrar menos' : 'Ver todos'}
                </Text>
            </ShowMore>
        </Card>
    )
}
export default SectionTickets;
export interface Props{
    user: UserScheme,
    adminTasks: Array<AdminTaskScheme>
}