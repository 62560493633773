export enum ActionType {
    SET_CALL_USER = "SET_CALL_USER",
    SET_PROFILE_FUNCTION = "SET_PROFILE_FUNCTION",
    SET_LOADING = "SET_LOADING",
    SET_VOLUNTEER_TABLE_FILTERS = "SET_VOLUNTEER_TABLE_FILTERS",
    SET_GRANDPA_TABLE_FILTERS = "SET_GRANDPA_TABLE_FILTERS",
    SET_TASK_TABLE_FILTERS = "SET_TASK_TABLE_FILTERS",
    SET_COORPORATE_TABLE_FILTERS = "SET_COORPORATE_TABLE_FILTERS",
    SET_CURRENT_USER = "SET_CURRENT_USER",
    SET_RESIDENCE_TABLE_FILTERS = "SET_RESIDENCE_TABLE_FILTERS",
    SET_ERROR = "SET_ERROR",
    SET_COORPORATE_PROFILE_FUNCTION="SET_COORPORATE_PROFILE_FUNCTION",
    SET_EVENT_TABLE_FILTERS="SET_EVENT_TABLE_FILTERS",
    SET_ROLE="SET_ROLE",
    SET_AUTODIAL="SET_AUTODIAL",
    SET_AMBASSADOR_TABLE_FILTERS="SET_AMBASSADOR_TABLE_FILTERS",
    SET_SALES_TABLE_FILTERS="SET_AMBASSADOR_SALES_FILTERS",
    SET_RESIDENCE_PROFILE_FUNCTION="SET_RESIDENCE_PROFILE_FUNCTION"
}