import React from "react";
import { Route, Routes } from "react-router-dom";

import Table from '../view/push/Table';

const Push = (props: any) =>{

	return (
		<Routes>
			<Route path={'/'} element={<Table/>}/>
		</Routes> 
	);
}

export default Push;
export interface Props{

}