import React from "react";
import { Route, Routes } from "react-router-dom";

import Table from '../view/challenge/Table';
import Detail from "../view/challenge/detail/Detail";
import Register from "../view/event/register/Register";

const Challenge = (props: any) =>{

	return (
		<Routes>
			<Route path={'/'} element={<Table/>}/>
			<Route path={'/perfil'} element={<Detail/>}/>
			{/*<Route path={'/registro'} element={<Register/>}/>*/}
		</Routes> 
	);
}

export default Challenge;
export interface Props{

}