import React, { useState } from "react";
import styled from 'styled-components';
import { EventScheme, ProductScheme } from 'client-v2';

import * as icons from 'lucide-react';
import Card from '../../../components/Containers/Card'
import { Color, Text, Button, Label } from '@adoptaunabuelo/react-components';
import { Pen } from 'lucide-react';
import BulletModal from "./BulletModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`
const BulletContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 16px;
    flex-wrap: wrap;
`
const BulletCell = styled.div`
    width: calc(50% - 42px);
    padding: 16px;
    border-radius: 12px;
    border: 1px solid ${Color.line.soft};
`

const SectionMain = (props: Props) =>{

    const iconsT: any = icons;
    const [ showModal, setShowModal ] = useState(false);

	return (
        <Card style={{marginBottom:16}}>
            <BulletModal
                isVisible={showModal}
                event={props.event}
                product={props.product}
                onClose={() => setShowModal(false)}
                onSave={() =>{
                    setShowModal(false);
                }}
            />
            <TitleHeader>
				<Text type='p' weight={"medium"}>
					Qué incluye
				</Text>
				<Button 
                    style={{position: 'absolute', right: 16, top: 12}}
					design={'image'} 
					icon={<Pen height={20} width={20}/>}
                    onClick={() => setShowModal(true)}
				/>
			</TitleHeader>
            <BulletContainer>
                {props.event.bullet ? props.event.bullet.map((item, index: number) =>{
                    const Icon = iconsT[item.icon];
                    return(
                        <BulletCell
                            key={'bullet-'+index}
                        >
                            <Icon style={{marginBottom: 12}} color={Color.text.full}/>
                            <Text type='p2'>
                                {item.title}
                            </Text>
                            <Text type='c1' style={{color: Color.text.high}}>
                                {item.subtitle}
                            </Text>
                        </BulletCell>
                    )})
                :
                    <Label
                        style={{backgroundColor: Color.status.color.warning}}
                        text="Falta añadir qué incluye"
                    />
                }
            </BulletContainer>
        </Card>
	);
}

export default SectionMain;
export interface Props{
    product: ProductScheme,
    event: EventScheme
}