import React, { useEffect, useState } from 'react';
import { Ambassador, Constant, ErrorScheme, Residence, UserScheme } from 'client-v2';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';

import { List, MapPin } from 'lucide-react';
import SearchTable from '../../components/Table/SearchTable';

const Table = (props: Props) =>{

    const dispatch = useDispatch();
	const { setAmbassadorTableFilters } = bindActionCreators(actionsCreators, dispatch);
	const ambassadorTableFilters = useSelector((state:State)=>state.ambassadorTableFilters);
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);
    const [ ambassadors, setAmbassadors ] = useState<Array<UserScheme>>([]);
    const [ count, setCount ] = useState(0);
    const [ provinces, setProvinces ] = useState<Array<any>>([]);
    const [ countries, setCountries ] = useState<Array<any>>([]);
    const [ languages, setLanguages ] = useState<Array<any>>([]);
    const [ residences, setResidences ] = useState<Array<any>>([]);

    useEffect(() =>{
        //Remove name, phone and email filters
        const temp = {
            ...ambassadorTableFilters,
            name: undefined,
            phone: undefined,
            email: undefined,
            objectId: undefined
        }

        getData(temp);
        getProvince();
        getCountry();
        getLanguages();
        getResidences();
    },[]);

    const getData = async (filter: any) =>{
        setLoading(true);
        if(filter.objectId){
            Ambassador.getById(filter.objectId).then((result: UserScheme) =>{
                setAmbassadors([result]);
                setCount(1);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setAmbassadors([]);
                setCount(0);
                setLoading(false);
            });
        }
        else{
            Ambassador.get({
                ...filter,
                limit: filter.limit ? filter.limit : 20
            }).then((result) =>{
                setAmbassadors(result.array);
                setCount(result.count);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
            });
        }
    }

    const getProvince = () =>{
        const provinces = Constant.cities();
        const temp = provinces.map((item) => ({
            ...item,
            label: item.title
        }));
        setProvinces(temp);
    }

    const getCountry = () =>{
        const country = Constant.country();
        const temp = country.map((item) => ({
            ...item,
            label: item.title
        }));
        setCountries(temp);
    }

    const getLanguages = () =>{
        const language = Constant.languages();
        const temp = language.map((item) => ({
            ...item,
            label: item.title
        }));
        setLanguages(temp);
    }

    const getResidences = async () =>{
        Residence.get().then((result) =>{
            const temp = result.data.map((it)=>{
                return {label: it.name, id: it.objectId}
            }).sort(Constant.dynamicSort("label"))
            setResidences(temp);
        });
    }

    const onRowClick = (user: UserScheme, target?: string) =>{
        if(target === '_blank')
            window.open('/embajadores/perfil?id='+user.objectId, '_blank');
        else
            navigate('/embajadores/perfil?id='+user.objectId);
    }

    const onFiltersChange = (result: any) =>{
        setAmbassadorTableFilters(result);
        getData(result);
    }

    return(
        <SearchTable
            title={'Embajadores'}
            filters={[
                {
                    id: 'city',
                    placeholder: 'Provincia',
                    type: 'single',
                    selectedOptions: ambassadorTableFilters.city,
                    options: provinces
                },
                {
                    id: 'country',
                    placeholder: 'País',
                    options: countries,
                    selectedOptions: ambassadorTableFilters.country,
                    type: 'multiple'
                },
                {
                    id: 'languages',
                    placeholder: 'Idioma',
                    options: languages,
                    selectedOptions: ambassadorTableFilters.languages,
                    type: 'multiple'
                },
                {
                    id: 'residenceId',
                    placeholder: 'Residencia',
                    type: 'single',
                    options: residences,
                    selectedOptions: ambassadorTableFilters.residenceId,
                }
            ]}
            columns={[
                {
                    id: 'name',
                    title: 'Nombre'
                },
                {
                    id: 'phone',
                    title: 'Teléfono'
                }, 
                {
                    id: 'city',
                    title: 'Ciudad'
                }, 
                {
                    id: 'country',
                    title: 'País'
                },
                {
                    id: 'residence',
                    title: 'Residencia'
                }
            ]}
            switchOptions={[
                {
                    id: 'list',
                    icon: <List/>
                },
                {
                    id: 'map',
                    icon: <MapPin/>
                }
            ]}
            rows={ambassadors}
            count={count}
            page={ambassadorTableFilters.page}
            loading={loading}
            onRowClick={onRowClick}
            onFiltersChange={onFiltersChange}
        />
    )
}
export default Table;
export interface Props{

}