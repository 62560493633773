import React, { useEffect, useState } from 'react';
import { Challenge, ChallengeScheme, ErrorScheme } from 'client-v2';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';

import SearchTable from '../../components/Table/SearchTable';
import { Button } from '@adoptaunabuelo/react-components';

const Table = (props: Props) =>{

    const dispatch = useDispatch();
	const { setEventTableFilters } = bindActionCreators(actionsCreators, dispatch);
	const eventTableFilters = useSelector((state:State)=>state.eventTableFilters);
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);
    const [ challengeArray, setChallengeArray ] = useState<Array<ChallengeScheme>>([]);
    const [ count, setCount ] = useState(0);

    useEffect(() =>{
        getData(eventTableFilters);
    },[]);

    const getData = async (filter: any) =>{
        setLoading(true);
        if(filter.objectId){
            Challenge.getById(filter.objectId).then((result) =>{
                setChallengeArray([result.data]);
                setCount(result ? 1 : 0);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setChallengeArray([]);
                setCount(0);
                setLoading(false);
            });
        }
        else{
            Challenge.get({
                ...filter, 
                limit: filter.limit ? filter.limit : 20,
                descending: 'createdAt'
            }).then((result) =>{
                setChallengeArray(result.data);
                setCount(result.pagination.count);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
            });
        }
    }

    const onRowClick = (challenge: ChallengeScheme, target?: string) =>{
        if(target === '_blank')
            window.open('/reto/perfil?id='+challenge.objectId, '_blank');
        else
            navigate('/reto/perfil?id='+challenge.objectId);
    }

    const onFiltersChange = (result: any) =>{
        setEventTableFilters(result);
        getData(result);
    }

    const renderButtonView = () =>(
        <Button
            style={{height: 36, marginLeft: 12}}
            onClick={() => navigate('/reto/registro')}
        >
            Añadir
        </Button>
    )

    return(
        <SearchTable
            title={'Sueños'}
            filters={[]}
            columns={[
                {
                    id: 'title',
                    title: 'Título'
                },
                {
                    id: 'challenge',
                    title: 'Tipo'
                },
                {
                    id: 'goal',
                    title: 'Objetivo'
                },
                {
                    id: 'amount',
                    title: 'Obtenido'
                },
                {
                    id: 'supporters',
                    title: 'Colaboradores'
                },
                {
                    id: 'endDate',
                    title: 'Fecha'
                }
            ]}
            rows={challengeArray}
            count={count}
            page={eventTableFilters.page}
            loading={loading}
            ButtonView={renderButtonView}
            onRowClick={onRowClick}
            onFiltersChange={onFiltersChange}
        />
    )
}
export default Table;
export interface Props{

}