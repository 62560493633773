import React, { useState } from "react";
import styled from "styled-components";
import { ResidenceScheme, UserScheme } from "client-v2";

import { Text, Color } from "@adoptaunabuelo/react-components";
import Card from "../../../components/Containers/Card";
import CardList from "../../../components/Profile/CardList";
import { Plus } from "lucide-react";
import MatchModal from "../../../components/Modals/MatchModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;

const SectionVolunteer = (props: Props) => {
	const [showMatchModal, setShowMatchModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState<UserScheme | undefined>(
		undefined
	);

	const onOptionClick = (user: UserScheme, action: string) => {
		if (action === "match") {
			setShowMatchModal(true);
			setSelectedUser(user);
		}
	};

	return (
		<>
			<MatchModal
				isVisible={showMatchModal}
				user={selectedUser}
				onSave={() => setShowMatchModal(false)}
				onClose={() => setShowMatchModal(false)}
			/>
			<Card style={{ marginBottom: 16 }}>
				<TitleHeader>
					<Text type="p" weight={"semibold"}>
						Voluntarios pendiente de match
						<Text type="c1" style={{ color: Color.text.high }}>
							{props.volunteerCount} resultados
						</Text>
					</Text>
				</TitleHeader>
				{props.volunteers.length > 0 ? (
					<CardList
						data={props.volunteers.map((item) => ({ user: item }))}
						options={[
							{
								id: "match",
								label: "Asignar abuelo",
								action: "match",
								icon: <Plus />,
							},
						]}
						onSave={(data) => onOptionClick(data.user, data.action)}
					/>
				) : (
					<Text type="p2" style={{ color: Color.text.high }}>
						No hay voluntarios en lista de espera
					</Text>
				)}
			</Card>
		</>
	);
};

export default SectionVolunteer;
export interface Props {
	residence: ResidenceScheme;
	volunteers: UserScheme[];
	volunteerCount: number;
	onResidenceChanged: (residence: ResidenceScheme) => void;
}
