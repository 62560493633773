import React from "react";
import { Route, Routes } from "react-router-dom";

import Table from '../view/corporate/Table';
import Profile from '../view/corporate/profile/Profile';
import Register from '../view/corporate/Register';

const Coorporate = (props: any) =>{

	return (
		<Routes>
			<Route path={'/'} element={<Table/>}/>
			<Route path={'/perfil'} element={<Profile/>}/>
			<Route path={'/registro'} element={<Register/>}/>
		</Routes> 
	);
}

export default Coorporate;
export interface Props{

}