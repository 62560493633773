import React, { useState, useEffect, CSSProperties } from "react";
import styled from "styled-components";

import { Text, Color } from "@adoptaunabuelo/react-components";

const Blink = styled.div`
    animation: blinker 1.5s linear infinite;
    color: ${Color.status.color.error};
    font-size: 16px;
    font-weight: 600;
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
`

const Countdown = (props: Props) =>{

    const [ timer, setTimer ] = useState(props.initSeconds);
    const [ timerString, setTimerString ] = useState<string | undefined>(undefined);

    useEffect(() => {
        const interval = setInterval(() => {
            getTimerString(timer-1);
            setTimer(timer-1);
        }, 1000);

        if(timer <= 0)
            clearInterval(interval)
    
        return () => clearInterval(interval);
    }, [timer]);

    const getTimerString = (timer: number) => {
        const date = new Date(timer*1000);
        setTimerString(date.toISOString().slice(14, 19));
    };      

    return(
        <Text
            type={props.textType ? props.textType : 'p'}
            style={props.textStyle}
        >
            {timer <= 0 ?
                <Blink>
                    {timerString}
                </Blink>
            : timerString}
        </Text>
    )
}
export default Countdown;
export interface Props {
    initSeconds: number
    textType?: any,
    textStyle?: CSSProperties
}