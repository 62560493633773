import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { User, Regex, Constant, UserScheme, ErrorScheme } from "client-v2";
import Geocode from "react-geocode";
import axios from "axios";

import { Input, Modal, Dropdown } from "@adoptaunabuelo/react-components";

const Icon = styled.img`
	height: 24px;
	width: 24px;
`;

const InfomationModal = (props: Props) => {
	const phoneUtil =
		require("google-libphonenumber").PhoneNumberUtil.getInstance();

	const [loading, setLoading] = useState(false);
	const [languages] = useState(Constant.languages());
	const [error, setError] = useState<string>("");

	const data = props.user.data;
	const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";
	const [selectedLanguages, setSelectedLanguages] = useState<Array<any>>(
		data && data.languages
			? data.languages.map((item: string) => ({ id: item }))
			: []
	);
	const [selectedEmail, setSelectedEmail] = useState<string | undefined>(
		props.user.email
	);
	const [selectedAddress, setSelectedAddress] = useState<any | undefined>({
		address: props.user.address,
		country: props.user.country,
		zipCode: props.user.zipCode,
		shortAddress: props.user.shortAddress,
		location: props.user.location
			? {
					lat: props.user.location.latitude,
					lng: props.user.location.longitude,
			  }
			: undefined,
	});
	const [selectedPhone, setSelectedPhone] = useState<{
		phone: string | undefined;
		countryCode: string | undefined;
	}>({
		phone: undefined,
		countryCode: undefined,
	});
	const [selectedGender, setSelectedGender] = useState<string | undefined>(
		props.user.gender
	);
	const [selectedIdCard, setSelectedIdCard] = useState<string | undefined>(
		props.user.idCard
	);
	const [selectedLOPD, setSelectedLOPD] = useState<string | undefined>(
		props.user.internalData?.lopdUrl
	);

	useEffect(() => {
		Geocode.setApiKey(googleAPIKey);
		Geocode.setLanguage("es");
	}, []);

	useEffect(() => {
		const result = phoneUtil.parseAndKeepRawInput(props.user.phone);
		const values = result.values_;
		setSelectedPhone({
			phone: values[2].toString(),
			countryCode: "+" + values[1],
		});
	}, []);

	const onSave = async () => {
		setLoading(true);
		setError("");
		const phone =
			selectedPhone.countryCode && selectedPhone.phone
				? selectedPhone.countryCode + selectedPhone.phone
				: undefined;
		User.set(props.user.objectId, {
			...selectedAddress,
			phone: phone === props.user.phone ? undefined : phone,
			email: selectedEmail,
			gender: selectedGender,
			idCard: selectedIdCard,
			data: {
				languages: selectedLanguages.map((item) => item.id),
			},
			internalData: {
				lopdUrl: selectedLOPD,
			},
		})
			.then((user) => {
				props.onSave(user.data);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setError(error.message);
				setLoading(false);
			});
	};

	const onLanguageChange = (array: Array<any>) => {
		setSelectedLanguages(array);
	};

	const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (Regex.email(e.target.value)) {
			setSelectedEmail(e.target.value);
		}
	};

	const onPhoneChange = (e: any) => {
		if (e.isValid) {
			setSelectedPhone({
				countryCode: e.country,
				phone: e.value,
			});
		}
	};

	const onLocationChange = async (item: {
		address: string;
		geocoder: google.maps.GeocoderResult;
		location: google.maps.LatLngLiteral;
	}) => {
		const locality = item.geocoder.address_components.filter((it) =>
			it.types.includes("locality")
		);
		const city = item.geocoder.address_components.filter((it) =>
			it.types.includes("administrative_area_level_2")
		);
		const country = item.geocoder.address_components.filter((it) =>
			it.types.includes("country")
		);
		const postal_code = item.geocoder.address_components.filter((it) =>
			it.types.includes("postal_code")
		);
		const sortAddress =
			(locality.length > 0 ? locality[0].long_name + ", " : "") +
			(city.length > 0 ? city[0].long_name + ", " : "") +
			country[0].long_name;

		//Get the timezone
		let timeZone = undefined;
		const result2 = await axios({
			method: "GET",
			url:
				"https://maps.googleapis.com/maps/api/timezone/json?location=" +
				item.location.lat +
				"%2C" +
				item.location.lng +
				"&timestamp=1331161200&key=" +
				googleAPIKey,
		});
		if (result2.data.status === "OK") {
			timeZone = result2.data.timeZoneId;
		}

		setSelectedAddress({
			address: item.address,
			shortAddress: sortAddress,
			location: item.location,
			country: country[0].short_name,
			city: city.length > 0 ? city[0].long_name : undefined,
			timeZone: timeZone,
			zipCode:
				postal_code.length > 0 ? postal_code[0].long_name : undefined,
		});
	};

	const onGenderChange = (option: Array<any>) => {
		setSelectedGender(option[0].id);
	};

	return (
		<Modal
			type="form"
			isVisible={props.isVisible}
			title={"Información"}
			error={error}
			options={[
				{
					id: "phone",
					title: "Teléfono",
					Data: (
						<Input
							type="tel"
							placeholder="Teléfono"
							defaultValue={selectedPhone.phone}
							country={selectedPhone.countryCode}
							onPhoneChange={onPhoneChange}
						/>
					),
				},
				{
					id: "email",
					title: "Email",
					Data: (
						<Input
							type="email"
							placeholder="Email"
							defaultValue={props.user.email}
							onChange={onEmailChange}
						/>
					),
				},
				{
					id: "location",
					title: "Dirección",
					Data: (
						<Input
							placeholder="Dirección aproximada"
							type="location"
							design="primary"
							value={selectedAddress.address}
							onLocationChange={onLocationChange}
						/>
					),
				},
				{
					id: "separator",
				},
				{
					id: "gender",
					title: "Género",
					Data: (
						<Dropdown
							id="gender-dropdown"
							style={{ flex: 1 }}
							placeholder="Género"
							type="single"
							menuPosition="top"
							options={[
								{
									id: "male",
									title: "Hombre",
									icon: (
										<Icon
											src={require("../../assets/img/Man.png")}
										/>
									),
								},
								{
									id: "female",
									title: "Mujer",
									icon: (
										<Icon
											src={require("../../assets/img/Woman.png")}
										/>
									),
								},
							]}
							selectedOptions={
								selectedGender
									? [{ id: selectedGender, title: "" }]
									: []
							}
							onChange={onGenderChange}
						/>
					),
				},
				{
					id: "languages",
					title: "Idiomas",
					Data: (
						<Dropdown
							id={"language-dropdown"}
							placeholder={"Idiomas"}
							type="multiple"
							onChange={onLanguageChange}
							options={languages}
							selectedOptions={selectedLanguages}
						/>
					),
				},
				{
					id: "separator",
				},
				{
					id: "idCard",
					title: "DNI o NIE",
					Data: (
						<Input
							type="text"
							placeholder="DNI o NIE"
							defaultValue={props.user.idCard}
							onChange={(e) => setSelectedIdCard(e.target.value)}
						/>
					),
				},
				{
					id: "lopdUrl",
					title: "LOPD",
					Data: (
						<Input
							type="text"
							placeholder="URL a documento de Drive"
							defaultValue={props.user.internalData?.lopdUrl}
							onChange={(e) => setSelectedLOPD(e.target.value)}
						/>
					),
				},
				{
					id: "separator",
				},
			]}
			onClose={() => {
				setError("");
				props.onClose();
			}}
			buttonProps={{
				onClick: onSave,
				loading: loading,
			}}
		/>
	);
};
export default InfomationModal;
export interface Props {
	isVisible: boolean;
	user: UserScheme;
	onClose: () => void;
	onSave: (user: UserScheme) => void;
}
