import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Product, EventScheme, ProductScheme } from 'client-v2';
import moment from 'moment';

import { Input, Text, Button, Feedback, TextArea } from '@adoptaunabuelo/react-components';
import AdminBar from "../../../components/Nav/AdminBar";

const Container = styled.div`
    position: relative;
	display: flex;
	width: -webkit-fill-available;
    height: 100vh;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const RegisterContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`
const RegisterView = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    background-color: white;
    padding: 24px 48px;
    border-radius: 12px;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Register = (props: Props) =>{

    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ selection, setSelection ] = useState<{title?: string, description?: string, address?: string, location?:{latitude: number, longitude: number}, shortAddress?: string, price?: string, limit?: string, startDate?: string, startTime?: string, endDate?: string, endTime?: string}>({
        title: undefined,
        description: undefined,
        address: undefined,
        location: undefined,
        shortAddress: undefined,
        price: undefined,
        limit: undefined,
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined
    })

    const onSaveClick = async () =>{
        if(selection.title && selection.description && selection.address && selection.shortAddress && selection.location && selection.price && selection.limit){
            setLoading(true);
            const start = moment(selection.startDate+' '+selection.startTime, 'DD/MM/YYYY HH:mm');
            const end = moment(selection.endDate+' '+selection.endTime, 'DD/MM/YYYY HH:mm');
            Product.create({
                type: 'event',
                charge: 'unique',
                title: selection.title,
                description: selection.description,
                address: selection.address,
                shortAddress: selection.shortAddress,
                location: selection.location,
                price: parseInt(selection.price),
                limit: parseInt(selection.limit),
                startDate: start.toDate(),
                endDate: end.toDate()
            }).then((result) =>{
                navigate('/evento/perfil?id='+result.data.objectId);
                setLoading(false);
            }).catch((e: string) =>{
                setLoading(false);
                setError(e);
            });
        }
        else{
            if(!selection.title) setError('Debes añadir el título del evento');
            else if(!selection.description) setError('Debes añadir la descripción del evento');
            else if(!selection.address) setError('Debes añadir la dirección exacta del evento');
            else if(!selection.shortAddress) setError('Debes añadir la dirección aproximada del evento');
            else if(!selection.price) setError('Debes añadir el precio del evento');
            else if(!selection.limit) setError('Debes añadir las plazas disponibles del evento');
        }
    }

    const onLocationChange = async (result: {address: string, geocoder: google.maps.GeocoderResult, location: google.maps.LatLngLiteral}) => {
        setSelection({
            ...selection,
            address: result.address,
            location: {
                latitude: result.location.lat,
                longitude: result.location.lng
            }
        })
    }

    return(
        <Container>
            <Feedback
                isVisible={error ? true : false}
                type='error'
                text={error ? error : ''}
                onClose={() => setError(undefined)}
            />
            <AdminBar
                style={{marginBottom: 16}}
                title="Registro de evento"
                goBack={() => navigate(-1)}
            />
            <RegisterContainer>
                <RegisterView>
                    <Text type='h3' weight='semibold' style={{marginTop: 12, marginBottom: 24}}>
                        Datos
                    </Text>
                    <Row
                        style={{marginBottom: 12}}
                    >
                        <Input 
                            type="text"
                            design={'secondary'}
                            containerStyle={{flex: 1}}
                            placeholder='Título'
                            onChange={(e) => setSelection({
                                ...selection,
                                title: e ? e.target.value : undefined
                            })}
                        />
                    </Row>
                    <Row style={{marginBottom: 12}}>
                        <TextArea
                            style={{width: 575}}
                            design={'design-2'}
                            placeholder='Descripción'
                            onChange={(e) => setSelection({
                                ...selection,
                                description: e ? e.target.value : undefined
                            })}
                        />
                    </Row>
                    <Row style={{marginBottom: 12, gap: 12}}>
                        <Input
                            type='location'
                            design='secondary'
                            placeholder='Dirección'
                            onLocationChange={onLocationChange}
                        />
                        <Input
                            type="text"
                            design={'secondary'}
                            containerStyle={{flex: 1}}
                            placeholder='Dirección aprox. (ej. Residencia)'
                            onChange={(e) => setSelection({
                                ...selection,
                                shortAddress: e ? e.target.value : undefined
                            })}
                        />
                    </Row>
                    <Row style={{marginBottom: 12, gap: 12}}>
                        <Input
                            type="text"
                            design={'secondary'}
                            containerStyle={{flex: 1}}
                            placeholder='Precio €'
                            onChange={(e) => setSelection({
                                ...selection,
                                price: e ? e.target.value : undefined
                            })}
                        />
                        <Input
                            type="text"
                            design={'secondary'}
                            containerStyle={{flex: 1}}
                            placeholder={'Plazas disponibles'}
                            onChange={(e) => setSelection({
                                ...selection,
                                limit: e ? e.target.value : undefined
                            })}
                        />
                    </Row>
                    <Row style={{marginBottom: 12, gap: 12}}>
                        <Input
                            design={'secondary'}
                            containerStyle={{flex: 1}}
                            placeholder='Fecha de inicio'
                            type={'date'}
                            showCalendar={true}
                            onChange={(e: any) => setSelection({
                                ...selection,
                                startDate: moment(e).format('DD/MM/YYYY'),
                            })}
                        />
                        <Input
                            design={'secondary'}
                            containerStyle={{width: 200}}
                            placeholder={'Hora de inicio'}
                            type={'time'}
                            value={selection.startTime}
                            onChange={(e) => setSelection({
                                ...selection,
                                startTime: e ? e.target.value : undefined
                            })}
                        />
                    </Row>
                    <Row style={{marginBottom: 12, gap: 12}}>
                        <Input
                            design={'secondary'}
                            containerStyle={{flex: 1}}
                            placeholder='Fecha de fin'
                            type={'date'}
                            showCalendar={true}
                            onChange={(e: any) => setSelection({
                                ...selection,
                                endDate: moment(e).format('DD/MM/YYYY'),
                            })}
                        />
                        <Input
                            design={'secondary'}
                            containerStyle={{width: 200}}
                            placeholder={'Hora de fin'}
                            type={'time'}
                            value={selection.endTime}
                            onChange={(e) => setSelection({
                                ...selection,
                                endTime: e ? e.target.value : undefined
                            })}
                        />
                    </Row>
                    <Row style={{flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <Button
                            loading={loading}
                            onClick={onSaveClick}
                        >
                            Guardar
                        </Button>
                    </Row>
                </RegisterView>
            </RegisterContainer>
        </Container>
    )
}
export default Register;
export interface Props{

}