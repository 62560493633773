import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { AdminTask, Constant, User, AdminTaskScheme, UserScheme, ErrorScheme } from 'client-v2';

import { Text, Feedback, Avatar, Dropdown } from '@adoptaunabuelo/react-components';
import Spinner from '../../../components/Spinner/Spinner';
import AdminBar from "../../../components/Nav/AdminBar";
import RegisterForm from "../../../components/Form/RegisterForm";

const Container = styled.div`
    position: relative;
	display: flex;
	width: -webkit-fill-available;
    height: 100vh;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const Register = (props: Props) =>{

    const navigate = useNavigate();
    const location = useLocation();
    const priorityArray = Constant.adminTaskPriority();

    const [ loading, setLoading ] = useState(false);
    const [ form, setForm ] = useState<{
        to?: UserScheme,
        group?: string,
        type?: string,
        admin?: string,
        priority?: {
            id: "high" | "low" | "medium",
            title: string
        }
    }>({
        to: undefined,
        group: undefined,
        type: undefined,
        admin: undefined,
        priority: undefined
    });
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ types, setTypes ] = useState<Array<any>>([]);
    const [ admins, setAdmins ] = useState<Array<any>>([]);

    useEffect(() =>{
        if(location.state && location.state.user){
            const user: UserScheme = location.state.user;
            const tempForm = {
                ...form,
                to: user
            }
            setForm(tempForm);
        }
        getAdmin();
    },[]);

    const getAdmin = async () =>{
        setLoading(true);
        User.get({
            type: 'admin',
            data: {
                department: ['support', 'sales'],
            },
            active: true
        }).then((result) =>{
            const temp = result.data.map((item: any) => ({
                ...item,
                id: item.objectId,
                title: item.name+' '+item.surname
            }));
            setAdmins(temp);
            setLoading(false);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
        });
    }

    const onAdminChange = (op: any) =>{
        const department = op[0].data.department;
        getTypes(department);
        setForm({
            ...form,
            admin: op[0].id,
            type: undefined,
            group: department
        });
    }

    const getTypes = (group: string) =>{
        const result: any = Constant.adminTaskType();
        setTypes(result[group] ? result[group] : []);
    }

    const onSaveClick = async () =>{
        setLoading(true);
        if(form.admin && form.group && form.type && form.to){
            AdminTask.create({
                adminId: form.admin ? form.admin : undefined,
                toId: form.to ? form.to.objectId : undefined,
                group: form.group as any,
                type: form.type,
                priority: form.priority?.id
            }).then((result) =>{
                setLoading(false);
                navigate('/tareas/'+result.data.objectId);
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
                setError(error.message);
            });
        }
        else{
            setLoading(false);
            setError('Asegúrate de haber añadido todos los datos necesarios')
        }
    }

    return(loading ? 
        <Spinner/>
    :
        <Container>
            <Feedback
                isVisible={error ? true : false}
                type='error'
                text={error ? error : ''}
                onClose={() => setError(undefined)}
            />
            <AdminBar
                style={{marginBottom: 16}}
                title="Nueva tarea"
                goBack={() => navigate(-1)}
            />
            <RegisterForm
                title={'Datos'}
                buttonProps={{
                    children: 'Guardar',
                    onClick: onSaveClick,
                    loading: loading
                }}
                options={[
                    {
                        id: 'user',
                        title: (form.to && form.to.type === 'volunteer') ? 'Voluntario' : 'Abuelo',
                        Data: <Row>
                            <Avatar
                                icon={form.to ? form.to.image?.url : undefined}
                                name={form.to ? form.to.name : undefined}
                                style={{height: 42, width: 42, marginRight: 8}}
                            />
                            <Text type='p'>
                                {form.to ? form.to.name : undefined}
                            </Text>
                        </Row>
                    },
                    {
                        id: 'separator'
                    },
                    {
                        id: 'admin',
                        title: 'Propietario',
                        Data: <Dropdown
                            id='dropdown-admin'
                            placeholder='Propietario'
                            options={admins}
                            type='single'
                            onChange={onAdminChange}
                        />
                    },
                    {
                        id: 'separator'
                    },
                    {
                        id: 'type',
                        title: 'Tipo',
                        Data: <Dropdown
                            id='dropdown-type'
                            placeholder='Tipo'
                            options={types}
                            selectedOptions={form.type ? [{id: form.type}] : []}
                            type='single'
                            onChange={(op: any) => setForm({
                                ...form,
                                type: op[0].id,
                                priority: {
                                    id: op[0].priority,
                                    title: priorityArray.filter((item: any) => item.id === op[0].priority)[0].title
                                }
                            })}
                        />
                    },
                    {
                        id: 'separator'
                    },
                    {
                        id: 'priority',
                        title: 'Prioridad',
                        Data: <Text type='p'>
                            {form.priority ? form.priority.title : '-'}
                        </Text>
                    }
                ]}
            />
        </Container>
    )
}
export default Register;
export interface Props{

}