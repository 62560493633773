import React from "react";
import { Color } from '@adoptaunabuelo/react-components';
import styled from 'styled-components';
import { Player } from '@lottiefiles/react-lottie-player';

import animation from '../../assets/animation/button-loading.json'

const Container = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: ${Color.background.deepBlue};
`
const Spinner = () => (
    <Container>
        <Player
            style={{height: 150, width: 150}}
            loop={true}
            autoplay={true}
            src={animation}
        />
    </Container>
);

export default Spinner;