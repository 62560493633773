import React, { useState } from "react";
import styled from 'styled-components';
import { ChallengeScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import PriceBreakdownCell from "../../../components/Cell/PriceBreakdownCell";
import { Text, Button } from '@adoptaunabuelo/react-components';
import { Pen } from 'lucide-react';
import DistributionModal from "./DistributionModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`

const DistributionSection = (props: Props) =>{

    const [ showModal, setShowModal ] = useState(false);

	return (
        <>
        <DistributionModal
            isVisible={showModal}
            challenge={props.challenge}
            onSave={(challenge) => {
                setShowModal(false);
                props.setChallenge(challenge)
            }}
            onClose={() => setShowModal(false)}
        />
        <Card style={{marginBottom:16}}>
            <TitleHeader>
				<Text type='p' weight={"semibold"}>
					Distribución
				</Text>
				<Button 
                    style={{position: 'absolute', right: 16, top: 12}}
					design={'image'} 
					icon={<Pen height={20} width={20}/>}
                    onClick={() => setShowModal(true)}
				/>
			</TitleHeader>
            <PriceBreakdownCell
                options={props.challenge.distribution}
            />
        </Card>
        </>
	);
}

export default DistributionSection;
export interface Props{
    challenge: ChallengeScheme,
    setChallenge: (challenge: ChallengeScheme) => void
}