import React, { useState } from 'react';

import { Modal, Checkbox } from '@adoptaunabuelo/react-components';

const CancelationModal = (props: Props) =>{

    const [ step, setStep ] = useState(props.options ? 0 : 1);
    const [ motivation, setMotivation ] = useState<{id: string, label: string} | undefined>(undefined);

    const onNextClick = () =>{
        if(step === 0){
            props.onStepChange && props.onStepChange(step+1);
            setStep(step+1);
        }
        else if(step === 1)
            props.onSave && props.onSave(motivation);
    }

    return(
        <Modal
            isVisible={props.isVisible}
            title={props.title ? props.title : (step === 0 ? '¿Cuál es el motivo de la baja?' : '¿Seguro que quieres continuar?')}
            subtitle={props.subtitle ? props.subtitle : (step === 0 ? undefined : 'Este paso no se puede revertir')}
            onClose={() => {
                setStep(0);
                setMotivation(undefined);
                props.onClose()
            }}
            error={props.error}
            buttonProps={{
                loading: props.loading,
                children: step === 0 ? 'Siguiente' : 'Finalizar',
                disabled: (motivation || step === 1) ? false : true,
                onClick: onNextClick
            }}
        >
            {(step === 0 && props.options) &&
                <Checkbox
                    type={'single'}
                    options={props.options}
                    onChange={(option: any) => setMotivation(option[0])}
                />
            }
        </Modal>
    )
}
export default CancelationModal;
export interface Props{
    isVisible: boolean,
    title?: string,
    subtitle?: string,
    loading?: boolean,
    error?: string,
    options?: Array<{
        id: string,
        label: string
    }>
    onSave?: (motivation?: {id: string, label: string}) => void,
    onStepChange?: (step: number) => void,
    onClose: () => void
}