import React from "react";
import { Route, Routes } from "react-router-dom";

import Profile from '../view/volunteer/profile/Profile';
import Table from '../view/grandpa/search/Table';
import Register from '../view/grandpa/register/Register';

const Grandpa = (props: any) =>{

	return (
		<Routes>
			<Route path={'/'} element={<Table/>}/>
			<Route path={'/perfil'} element={<Profile/>}/>
			<Route path={'/registro'} element={<Register/>}/>
		</Routes> 
	);
}

export default Grandpa;
export interface Props{

}