import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Activity, PaymentScheme, UserScheme } from "client-v2";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../redux";
import { bindActionCreators } from "redux";
import { useNavigate } from "react-router-dom";

import { Button, ColorV2, Text } from "@adoptaunabuelo/react-components";
import { FileDown, FileUp } from "lucide-react";
import DownloadLeadsModal from "../../components/Modals/DownloadLeadsModal";
import UploadLeadsModal from "../../components/Modals/UploadLeadsModal";
import SearchTable from "../../components/Table/SearchTable";

const Header = styled.div`
	display: flex;
	flex: 1;
	justify-content: space-between;
	margin-left: 24px;
`;
const CounterContainer = styled.div`
	display: flex;
	gap: 12px;
`;
const ButtonContainer = styled.div`
	display: flex;
	gap: 12px;
`;
const Counter = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid ${ColorV2.border.neutralHigh};
	border-radius: 12px;
	padding: 2px 8px;
`;

const Table = (props: Props) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { setSalesTableFilters } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const salesTableFilters = useSelector(
		(state: State) => state.salesTableFilters
	);
	const role = useSelector((state: State) => state.role);
	const currentUser = useSelector((state: State) => state.currentUser);

	const [downloadModalShow, setDownloadModalShow] = useState(false);
	const [uploadModalShow, setUploadModalShow] = useState(false);
	const [loading, setLoading] = useState(false);
	const [salesArray, setSalesArray] = useState<
		Array<PaymentScheme & { Admin: UserScheme }>
	>([]);
	const [salesCount, setSalesCount] = useState(0);
	const [counters, setCounters] = useState<{
		pendingRemittance?: { amount: number; count: number };
		paid?: { amount: number; count: number };
		failed?: { amount: number; count: number };
	}>({
		pendingRemittance: { amount: 0, count: 0 },
		paid: { amount: 0, count: 0 },
		failed: { amount: 0, count: 0 },
	});

	useEffect(() => {
		getData(salesTableFilters);
	}, []);

	const getData = async (filter: any) => {
		setLoading(true);
		Activity.getSales({
			...filter,
		})
			.then((result) => {
				setSalesArray(result.data.array);
				setSalesCount(result.pagination.count);
				setCounters({
					...result.data,
				});
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
			});
	};

	const onFiltersChange = (result: any) => {
		let temp = {
			...salesTableFilters,
			...result,
		};
		if (result.createdAt) {
			temp = {
				...temp,
				createdAt:
					result.createdAt?.startDate && result.createdAt?.endDate
						? {
								min: result.createdAt.startDate.toString(),
								max: result.createdAt.endDate.toString(),
						  }
						: undefined,
			};
		}
		setSalesTableFilters(temp);
		getData(temp);
	};

	const onRowClick = (payment: PaymentScheme, target?: string) => {
		if (target === "_blank")
			window.open(
				"/voluntarios/perfil?id=" + payment.User.objectId,
				"_blank"
			);
		else navigate("/voluntarios/perfil?id=" + payment.User.objectId);
	};

	const renderButtonView = () => (
		<Header>
			<CounterContainer>
				{Object.entries(counters).map(([key, value]) => {
					if (key === "array") return null;
					else
						return (
							<Counter>
								<Text type="p" weight="semibold">
									{value.amount} €
								</Text>
								<Text type="b3">
									{value.count}{" "}
									{key === "pendingRemittance"
										? "pendiente remesa"
										: key === "paid"
										? "pagado"
										: "fallido"}
								</Text>
							</Counter>
						);
				})}
			</CounterContainer>
			<ButtonContainer>
				{role.task.downloadLeads && (
					<Button
						size="small"
						design="secondary"
						style={{ height: 42 }}
						icon={<FileDown height={24} width={24} />}
						onClick={() => setDownloadModalShow(true)}
					>
						Descargar
					</Button>
				)}
				{role.task.uploadLeads && (
					<Button
						size="small"
						design="secondary"
						style={{ height: 42 }}
						icon={<FileUp height={24} width={24} />}
						onClick={() => setUploadModalShow(true)}
					>
						Cargar
					</Button>
				)}
			</ButtonContainer>
		</Header>
	);

	return (
		<>
			<DownloadLeadsModal
				isVisible={downloadModalShow}
				currentUser={currentUser}
				onClose={() => setDownloadModalShow(false)}
			/>
			<UploadLeadsModal
				isVisible={uploadModalShow}
				onClose={() => setUploadModalShow(false)}
			/>
			<SearchTable
				title={"Ventas"}
				filters={[
					{
						id: "state",
						placeholder: "Estado del pago",
						type: "multiple",
						options: [
							{
								id: "pending_remittance",
								label: "Pendiente remesa",
							},
							{
								id: "paid",
								label: "Pagado",
							},
							{
								id: "failed",
								label: "Fallido",
							},
						],
						selectedOptions: salesTableFilters.state,
						hideSearchBar: true,
					},
					{
						id: "type",
						placeholder: "Tipo pago",
						type: "single",
						hideSearchBar: true,
						options: [
							{
								id: "first",
								label: "Primer pago",
							},
							{
								id: "recurrent",
								label: "Pago recurrente",
							},
							{
								id: "one",
								label: "Pago puntual",
							},
						],
						selectedOptions: salesTableFilters.type,
					},
					{
						id: "createdAt",
						placeholder: "Fecha de pago",
						type: "date",
						selectedOptions: salesTableFilters.createdAt,
					},
				]}
				columns={[
					{
						id: "user",
						title: "Nombre",
					},
					{
						id: "state",
						title: "Estado del pago",
					},
					{
						id: "price",
						title: "Cuota",
					},
					{
						id: "payment-type",
						title: "Tipo pago",
					},
					{
						id: "Subscription.state",
						title: "Estado suscripción",
					},
					{
						id: "createdAt",
						title: "Fecha de pago",
					},
				]}
				page={salesTableFilters.page}
				rows={salesArray}
				count={salesCount}
				loading={loading}
				ButtonView={renderButtonView}
				onRowClick={onRowClick}
				onFiltersChange={onFiltersChange}
			/>
		</>
	);
};
export default Table;
export interface Props {}
