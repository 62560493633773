import { Action } from '../actions/index'
import { ActionType } from '../actions-types/index'

const initialState: boolean = false

export const modalReducer = (data: boolean = initialState, action: Action) =>{
    switch (action.type) {
    	case ActionType.SET_LOADING:
       		return action.data;
		case ActionType.SET_ERROR:
			return action.data;
      	default:
        	return data;
    }
};