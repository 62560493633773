import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	Activity,
	AdminTask,
	Constant,
	User,
	ActivityScheme,
	AdminTaskScheme,
	UserScheme,
	ErrorScheme,
	ActivityRenderScheme,
} from "client-v2";
import moment from "moment";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import {
	Color,
	Text,
	Button,
	Feedback,
	Select,
	Avatar,
	Input,
} from "@adoptaunabuelo/react-components";
import Card from "../../../components/Containers/Card";
import HistoryTask from "../../../components/Profile/History-Task";
import * as icons from "lucide-react";

const TitleHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
	border-bottom: 1px solid ${Color.line.soft};
	padding-bottom: 21px;
	align-items: flex-start;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const ContentView = styled.div`
	display: flex;
	flex: 1;
	overflow: scroll;
`;
const InputView = styled.div`
	display: flex;
	padding-top: 8px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;
const SelectIcon = styled.div<{ color: string }>`
	height: 18px;
	width: 18px;
	border-radius: 40px;
	background-color: ${(props) => props.color};
`;

const SectionNotes = (props: Props) => {
	const stateOptions = Constant.adminTaskState().map((item) => ({
		...item,
		label: item.title,
		icon: <SelectIcon color={item.color} />,
	}));
	const priority = Constant.adminTaskPriority();
	const currentUser: UserScheme = useSelector(
		(state: State) => state.currentUser
	);
	const [loading, setLoading] = useState(false);
	const [selectedState, setSelectedState] = useState(props.task.state);
	const [colors, setColors] = useState<any>(undefined);
	const [activity, setActivity] = useState<Array<any>>(props.historyArray);
	const [adminArray, setAdminArray] = useState<Array<any>>([]);
	const [selectedAdmin, setSelectedAdmin] = useState<any | undefined>(
		undefined
	);
	const [selectedPriority, setSelectedPriority] = useState<any>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		getPriority();
		getAdmins();
	}, []);

	useEffect(() => {
		setActivity(props.historyArray);
	}, [props.historyArray]);

	useEffect(() => {
		setSelectedState(props.task.state);
	}, [props.task.state]);

	useEffect(() => {
		const result = stateOptions.filter(
			(item) => item.id === selectedState
		)[0];
		setColors({
			backgroundColor: result.backgroundColor,
			color: result.color,
		});
	}, [selectedState]);

	const getPriority = async () => {
		//Get selected priority
		const temp = priority.filter(
			(item) => item.id === props.task.priority
		)[0];
		const getKeyValue = (key: string) => (obj: Record<string, any>) =>
			obj[key];
		const icon = getKeyValue(temp.icon)(icons);
		setSelectedPriority({
			...temp,
			lucide: icon,
		});
	};

	const getAdmins = async () => {
		setLoading(true);
		User.get({
			type: "admin",
			data: {
				department: ["support", "sales"],
			},
			active: true,
		})
			.then((result) => {
				const temp = result.data.map((item: UserScheme) => {
					if (item.objectId === props.task.Admin?.objectId) {
						setSelectedAdmin({
							id: item.objectId,
							label: item.name + " " + item.surname,
							icon: (
								<Avatar
									style={{
										height: 24,
										width: 24,
										fontSize: 14,
									}}
									name={item.name}
									icon={item.image?.url}
								/>
							),
						});
					}
					return {
						id: item.objectId,
						label: item.name + " " + item.surname,
						icon: (
							<Avatar
								style={{ height: 24, width: 24, fontSize: 14 }}
								name={item.name}
								icon={item.image?.url}
							/>
						),
					};
				});
				setAdminArray(temp);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	const onStateChange = async (state: any) => {
		setLoading(true);
		AdminTask.set(props.task.objectId, {
			state: state.id,
			adminId: currentUser.objectId,
		})
			.then(async (result: AdminTaskScheme) => {
				setSelectedState(state.id);
				setColors({
					backgroundColor: state.backgroundColor,
					color: state.color,
				});
				const activity = await props.getHistory(props.task);
				setActivity(activity.data);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setError(
					"Hubo un error al tratar de modificar el estado de esta tarea."
				);
				setLoading(false);
			});
	};

	const onSend = async (text: string) => {
		setLoading(true);
		Activity.create({
			type: "note",
			fromId: props.user.objectId,
			adminId: currentUser.objectId,
			data: {
				AdminTask: props.task.objectId,
				note: text,
			},
		})
			.then(() => {
				props
					.getHistory(props.task)
					.then((result) => {
						setActivity(result.data);
						setLoading(false);
					})
					.catch((error: ErrorScheme) => {
						setLoading(false);
					});
			})
			.catch((error: ErrorScheme) => {
				setError(error.message);
				setLoading(false);
			});
	};

	const onAssignClick = async () => {
		setLoading(true);
		AdminTask.set(props.task.objectId, {
			adminId: currentUser.objectId,
		})
			.then((result: AdminTaskScheme) => {
				setSelectedAdmin({
					id: currentUser.objectId,
					label: currentUser.name + " " + currentUser.surname,
					icon: () => (
						<Avatar
							style={{ height: 24, width: 24, fontSize: 14 }}
							name={currentUser.name}
							icon={currentUser.image?.url}
						/>
					),
				});
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setError(error.message);
			});
	};

	const onSelectChange = async (option: any) => {
		setLoading(true);
		AdminTask.set(props.task.objectId, {
			adminId: option.id,
		})
			.then((result) => {
				setSelectedAdmin(option);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
				setError(
					"Hubo un error al tratar de asignarle esta tarea a otra persona."
				);
			});
	};

	return (
		<>
			<Card style={{ flex: 1, height: "100%" }}>
				<Feedback
					isVisible={error ? true : false}
					type="error"
					text={error ? error : ""}
					onClose={() => setError(undefined)}
				/>
				<TitleHeader>
					<Col style={{ flex: 1, marginRight: 12 }}>
						<Row>
							<Text type="h5" weight="semibold">
								{props.task.typeTitle}
							</Text>
							<Row>
								<Select
									id={"state-select"}
									title={"label"}
									style={{
										width: 140,
										border: "none",
										...colors,
									}}
									options={stateOptions}
									selectedItem={stateOptions.find(
										(item) => item.id === selectedState
									)}
									onChange={onStateChange}
								/>
							</Row>
						</Row>
						<Row style={{ marginTop: 12 }}>
							{selectedPriority && (
								<Text
									type="p2"
									style={{
										color: Color.text.high,
										display: "flex",
									}}
								>
									Prioridad:{" "}
									<selectedPriority.lucide
										color={selectedPriority.color}
										style={{ marginLeft: 4 }}
										height={20}
										width={20}
									/>{" "}
									<Text
										type="p2"
										style={{
											color: Color.text.full,
											marginLeft: 2,
										}}
									>
										{selectedPriority.title}
									</Text>
								</Text>
							)}
							<Text
								type="p2"
								style={{
									color: Color.text.high,
									display: "flex",
								}}
							>
								Id:{" "}
								<Text
									type="p2"
									style={{
										color: Color.text.full,
										marginLeft: 4,
									}}
								>
									{props.task.objectId}
								</Text>
							</Text>
							<Text
								type="p2"
								style={{
									color: Color.text.high,
									display: "flex",
								}}
							>
								Solicitado:{" "}
								<Text
									type="p2"
									style={{
										color: Color.text.full,
										marginLeft: 4,
									}}
								>
									{moment(props.task.createdAt).format(
										"DD/MM/YYYY HH:mm"
									)}
								</Text>
							</Text>
							{adminArray.length > 0 && selectedAdmin ? (
								<Select
									id="admin-select"
									style={{ border: "none", padding: 0 }}
									optionStyle={{ width: 250 }}
									title="label"
									options={adminArray}
									selectedItem={selectedAdmin}
									onChange={onSelectChange}
								/>
							) : (
								<Button
									style={{ width: "fit-content" }}
									size="small"
									loading={loading}
									onClick={onAssignClick}
								>
									Asignarme la tarea
								</Button>
							)}
						</Row>
					</Col>
				</TitleHeader>
				<ContentView>
					{activity.length > 0 && (
						<HistoryTask activities={activity} />
					)}
				</ContentView>
				<InputView>
					<Input
						type="chat"
						style={{ width: "100%", borderRadius: 12 }}
						placeholder="Escribe una nota"
						loading={loading}
						onSend={onSend}
					/>
				</InputView>
			</Card>
		</>
	);
};

export default SectionNotes;
export interface Props {
	user: UserScheme;
	task: AdminTaskScheme;
	historyArray: Array<any>;
	getHistory: (task: AdminTaskScheme) => Promise<any>;
}
