import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../redux';
import { UserScheme, MatchScheme, ResidenceScheme } from 'client-v2';

import { Color, Text, Button, Menu, Label, Avatar } from '@adoptaunabuelo/react-components';
import { MoreHorizontal, Trash2, Check, BookOpen, Star, ExternalLink, Trash, Briefcase, School } from 'lucide-react';

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 0px;
    border-bottom: 1px solid ${Color.line.soft};
    cursor: pointer;
`;
const Column = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const InactiveView = styled.div`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: white;
    opacity: 0.6;
`

const CardList = (props: Props) =>{

    const options = [
        {
            id: 'add-visit',
            label: 'Registrar visita',
            action: "add-visit",
            icon: <BookOpen height={20} width={20}/>
        },
        {
            id: 'set-top',
            label: 'Match Top',
            action: "set-top",
            icon: <Star height={20} width={20}/>
        },
        {
            id:"cancel-match",
            label:"Cancelar match",
            action:"cancel-match",
            icon: <Trash2 height={20} width={20}/>
        }
    ]

    const optionsInactive = [
        {
            id:"reactive-match",
            label:"Reactivar match",
            action:"reactive-match",
            icon: <Check height={20} width={20}/>
        }
    ]

    const navigate = useNavigate();
    const role = useSelector((state:State)=>state.role);

    const onClickMenu = (user: UserScheme, match: MatchScheme, action: string) =>{
        if(action==="cancel-match"){
            props.onSave && props.onSave({
                user: user,
                match: match, 
                action: action,
                title:"Cancelar Match",
                subtitle:"Se procedera a cancelar el match. El voluntario no podrá contactar con el abuelo.",
                buttonLabel: "Cancelar match"
            })
        }
        else if(action==="reactive-match"){
            props.onSave && props.onSave({
                user: user,
                match: match, 
                action: action,
                title:"Reactivar Match",
                subtitle:"Se procedera a reactivar el match. El voluntario podrá contactar de nuevo con el abuelo.",
                buttonLabel: "Reactivar match"
            })
        }
        else if(action==="set-top"){
            props.onSave && props.onSave({
                user: user,
                match: match, 
                action: action,
                title: match.top ? 'Quitar match Top' : "Match Top",
                subtitle: match.top ? 'Se eliminará la marca Top de este match' : "Se marcará el match como Top.",
                buttonLabel: match.top ? 'Quitar Top' : "Marcar como Top"
            })
        }
        else{
            props.onSave && props.onSave({
                user: user,
                match: match, 
                action: action
            })
        }
    }

    const onCardClic = (item: any, target?: string) =>{
        if(item.user.type === 'volunteer' || props.type === 'volunteer'){
            if(target)
                window.open('/voluntarios/perfil/?id='+(item.user.objectId ? item.user.objectId : item.user.id), '_blank');
            else
                navigate('/voluntarios/perfil/?id='+(item.user.objectId ? item.user.objectId : item.user.id));
        }
        else if(item.user.type === 'grandpa'){
            if(target)
                window.open('/abuelos/perfil/?id='+(item.user.objectId ? item.user.objectId : item.user.id), '_blank');
            else
                navigate('/abuelos/perfil/?id='+(item.user.objectId ? item.user.objectId : item.user.id));
        }
    }

    return(
        <>
        {props.data.map((item:{user: UserScheme, match?: MatchScheme, residence?: ResidenceScheme, labels?: Array<string>}, index:number)=> item.user ? (
            <ItemContainer 
                onClick={()=>onCardClic(item)} 
                key={'card-volunteer-'+index+'-'+item.user.objectId} 
                style={{borderBottom: index+1 === props.data.length ? 'none' : '1px solid '+Color.line.soft}}
            >
                {(item.match && item.match.hidden) &&
                    <InactiveView/>
                }
                <Row>
                    <Avatar 
                        style={{height:40, width:40, marginRight:12, fontSize:20}} 
                        name={item.user.name} 
                        icon={item.user.image?.url}
                    />
                    <Column>
                        <Row style={{alignItems: 'center', height: 24}}>
                            <Text type='p2' weight="medium">
                                {item.user.name} {item.user.surname} 
                                <span style={{fontWeight: 400, fontSize: 10, color: Color.text.high}}>
                                    {item.user.type === 'grandpa' && (item.user.Residence ?
                                        ' (Residencia)'
                                    :
                                        ''
                                    )}
                                </span>
                            </Text>
                            {role.actions.newTab &&
                                <Button
                                    design='image'
                                    icon={<ExternalLink height={16} width={16} color={Color.text.high}/>}
                                    onClick={(e) => {
                                        onCardClic(item, '_blank');
                                        e.stopPropagation();
                                    }}
                                />
                            }
                        </Row>
                        <Row>
                            {(item.match && item.match.top) &&
                                <Text type='c1' style={{display: 'flex', alignItems: 'center', backgroundColor: Color.status.color.warning, padding: '2px 8px', borderRadius: 3, marginRight: 6}}>
                                    <Star height={14} width={14} color={Color.text.full} style={{marginRight: 2}}/>
                                    Top
                                </Text>
                            }
                            {item.user.Coorporate && 
                                <Text type='c1' style={{color: Color.text.high}}>Corporativo</Text>
                            }
                        </Row>
                        {item.labels &&
                            <Row>
                                {item.labels.map(label =>(label ? 
                                    <Label
                                        key={'label-'+label+'-'+index}
                                        style={{fontSize: 10, height: 20, marginRight: 4}}
                                        text={label}
                                    />
                                : null))}
                            </Row>
                        }
                    </Column>
                    {(item.match || props.options) && !props.hideMenu &&
                        <Menu
                            id={"match-option-"+index}
                            position={"bottom-left"}
                            icon={<MoreHorizontal height={20} width={20} color={Color.text.full}/>}
                            options={props.options ? props.options : (item.match && item.match.hidden) ? optionsInactive : options}
                            menuStyle={{width: 220}}
                            onClick={(option: any) => item.match ? onClickMenu(item.user, item.match, option.action) : (props.onSave && props.onSave({user: item.user, action: option.action}))}
                        />
                    }
                </Row>
            </ItemContainer>
        ) : null)}
        </>
    )
}
export default CardList;
export interface Props extends ComponentPropsWithoutRef<"div">{
    type?: "volunteer" | "grandpa",
    options?: Array<{
        id: string,
        label: string,
        action: string,
        icon: React.ReactElement
    }>
    hideMenu?: boolean,
    data: Array<{
        user: UserScheme,
        match?: MatchScheme, 
        residence?: ResidenceScheme,
        labels?: Array<string>
    }>,
    onSave?:(data:{
        user: UserScheme,
        match?: MatchScheme, 
        action: string,
        title?: string, 
        subtitle?: string,
        buttonLabel?: string
    })=>void
}