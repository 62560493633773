import React, { useEffect, useState } from "react";
import { User, Constant, Residence, UserScheme, ErrorScheme } from "client-v2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators, State } from "../../../redux";
import { bindActionCreators } from "redux";

import { List, MapPin } from "lucide-react";
import SearchTable from "../../../components/Table/SearchTable";
import { Button } from "@adoptaunabuelo/react-components";

const Table = (props: Props) => {
	const dispatch = useDispatch();
	const { setGrandpaTableFilters } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const grandpaTableFilters = useSelector(
		(state: State) => state.grandpaTableFilters
	);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [grandpas, setGrandpas] = useState<Array<UserScheme>>([]);
	const [count, setCount] = useState(0);
	const [states, setStates] = useState<Array<any>>([]);
	const [hobbies, setHobbies] = useState<Array<any>>([]);
	const [provinces, setProvinces] = useState<Array<any>>([]);
	const [countries, setCountries] = useState<Array<any>>([]);
	const [languages, setLanguages] = useState<Array<any>>([]);
	const [residences, setResidences] = useState<Array<any>>([]);

	useEffect(() => {
		//Remove name, phone and email filters
		const temp = {
			...grandpaTableFilters,
			name: undefined,
			phone: undefined,
			email: undefined,
		};
		getData(temp);
		getStates();
		getHobbies();
		getProvince();
		getCountry();
		getLanguages();
		getResidences();
	}, []);

	const getData = async (filter: any) => {
		setLoading(true);
		if (filter.objectId) {
			User.getById(filter.objectId)
				.then((result) => {
					if (result.data.type === "grandpa") {
						setGrandpas([result.data]);
						setCount(1);
						setLoading(false);
					} else {
						setGrandpas([]);
						setCount(0);
						setLoading(false);
					}
				})
				.catch((error: ErrorScheme) => {
					setGrandpas([]);
					setCount(0);
					setLoading(false);
				});
		} else {
			let data = {};
			let internalData = {};
			if (filter.hobbies) data = { ...data, hobbies: filter.hobbies };
			if (filter.languages)
				data = { ...data, languages: filter.languages };
			if (filter.lopdUrl)
				internalData = {
					...internalData,
					lopdUrl: filter.lopdUrl === "Firmada" ? true : false,
				};
			User.get({
				...filter,
				hobbies: undefined,
				languages: undefined,
				lopdUrl: undefined,
				data: data,
				internalData: internalData,
				type: "grandpa",
				limit: filter.limit ? filter.limit : 20,
			})
				.then((result) => {
					setGrandpas(result.data);
					setCount(result.pagination.count);
					setLoading(false);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
				});
		}
	};

	const getStates = () => {
		const states = Constant.state();
		const temp = states.filter((item) => item.grandpa);
		const temp2 = temp.map((item) => ({
			...item,
			label: item.title,
		}));
		setStates(temp2);
	};

	const getHobbies = () => {
		const hobbies = Constant.hobbies();
		const temp = hobbies.map((item) => ({
			...item,
			label: item.title,
		}));
		setHobbies(temp);
	};

	const getProvince = () => {
		const provinces = Constant.cities();
		const temp = provinces.map((item) => ({
			...item,
			label: item.title,
		}));
		setProvinces(temp);
	};

	const getCountry = () => {
		const country = Constant.country();
		const temp = country.map((item) => ({
			...item,
			label: item.title,
		}));
		setCountries(temp);
	};

	const getLanguages = () => {
		const language = Constant.languages();
		const temp = language.map((item) => ({
			...item,
			label: item.title,
		}));
		setLanguages(temp);
	};

	const getResidences = async () => {
		Residence.get().then((result) => {
			const temp = result.data
				.map((it) => {
					return { label: it.name, id: it.objectId };
				})
				.sort(Constant.dynamicSort("label"));
			setResidences([
				{
					id: "home",
					label: "Abuelos de domicilio",
				},
				...temp,
			]);
		});
	};

	const onRowClick = (user: UserScheme, target?: string) => {
		if (target === "_blank")
			window.open("/abuelos/perfil?id=" + user.objectId, "_blank");
		else navigate("/abuelos/perfil?id=" + user.objectId);
	};

	const onFiltersChange = (result: any) => {
		let temp = result;
		if (temp.birthday && temp.birthday.startDate && temp.birthday.endDate) {
			temp = {
				...temp,
				birthday: {
					min: temp.birthday.startDate,
					max: temp.birthday.endDate,
				},
			};
		} else {
			temp = {
				...temp,
				birthday: undefined,
			};
		}
		if (temp.residenceId && temp.residenceId === "home") {
			temp = {
				...temp,
				residenceId: undefined,
				doesNotExist: ["Residence"],
			};
		} else {
			temp = {
				...temp,
				doesNotExist: undefined,
			};
		}
		if (temp.services) {
			var data = {};
			if (temp.services.indexOf("visit") > -1)
				data = { ...data, visit: true };
			if (temp.services.indexOf("letter") > -1)
				data = { ...data, letter: true };
			if (temp.services.indexOf("casting") > -1)
				data = { ...data, casting: true };
			console.log(data);
			temp = {
				data: data,
			};
		} else {
			temp = {
				...temp,
				services: undefined,
			};
		}
		setGrandpaTableFilters(result);
		getData(temp);
	};

	const renderButtonView = () => (
		<Button
			style={{ height: 36, marginLeft: 12 }}
			onClick={() => navigate("/abuelos/registro")}
		>
			Añadir
		</Button>
	);

	return (
		<SearchTable
			title={"Abuelos"}
			filters={[
				{
					id: "state",
					placeholder: "Estado",
					options: states,
					selectedOptions: grandpaTableFilters.state,
					hideSearchBar: true,
					type: "multiple",
				},
				{
					id: "hobbies",
					placeholder: "Aficiones",
					options: hobbies,
					selectedOptions: grandpaTableFilters.hobbies,
					type: "multiple",
				},
				{
					id: "city",
					placeholder: "Provincia",
					type: "single",
					selectedOptions: grandpaTableFilters.city,
					options: provinces,
				},
				{
					id: "country",
					placeholder: "País",
					options: countries,
					selectedOptions: grandpaTableFilters.country,
					type: "multiple",
				},
				{
					id: "birthday",
					placeholder: "Fecha nacimiento",
					type: "date",
					options: [],
					selectedOptions: grandpaTableFilters.birthday,
				},
				{
					id: "languages",
					placeholder: "Idioma",
					options: languages,
					selectedOptions: grandpaTableFilters.languages,
					type: "multiple",
				},
				{
					id: "residenceId",
					placeholder: "Residencia",
					type: "single",
					options: residences,
					selectedOptions: grandpaTableFilters.residenceId,
				},
				{
					id: "services",
					placeholder: "Programas",
					options: [
						{
							id: "visit",
							label: "Voluntariado",
						},
						{
							id: "letter",
							label: "Cartas",
						},
						{
							id: "casting",
							label: "Casting",
						},
					],
					selectedOptions: grandpaTableFilters.services,
					type: "multiple",
				},
				{
					id: "lopdUrl",
					placeholder: "LOPD",
					type: "single",
					position: "bottom-left",
					options: [
						{
							id: "Firmada",
							label: "Firmada",
						},
						{
							id: "No firmada",
							label: "No firmada",
						},
					],
					selectedOptions: grandpaTableFilters.lopdUrl,
				},
			]}
			columns={[
				{
					id: "name",
					title: "Nombre",
				},
				{
					id: "phone",
					title: "Teléfono",
				},
				{
					id: "residence",
					title: "Origen",
				},
				{
					id: "shortAddress",
					title: "Ciudad",
				},
				{
					id: "state",
					title: "Estado",
				},
			]}
			switchOptions={[
				{
					id: "list",
					icon: <List />,
				},
				{
					id: "map",
					icon: <MapPin />,
				},
			]}
			rows={grandpas}
			count={count}
			page={grandpaTableFilters.page}
			loading={loading}
			ButtonView={renderButtonView}
			onRowClick={onRowClick}
			onFiltersChange={onFiltersChange}
		/>
	);
};
export default Table;
export interface Props {}
