import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Constant, Regex, UserScheme } from 'client-v2';

import { SearchBar, Button, Filter } from '@adoptaunabuelo/react-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const FiltersView = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    flex-wrap: wrap;
    gap: 8px;
`

const GrandpaFilters = (props: Props) =>{

    const [ timer, setTimer ] = useState<any>(undefined);
    const [ languages, setLanguages ] = useState<Array<OptionProps>>([]);
    const [ dates, setDates ] = useState<Array<OptionProps>>([]);
    const [ hours, setHours ] = useState<Array<OptionProps>>([]);
    const [ selection, setSelection ] = useState<{
        languages: Array<string>,
        distance?: number,
        dates: Array<string>,
        hours: Array<string>,
        residence?: boolean
    }>({ languages: [], distance: 20, dates: [], hours: [] });

    useEffect(() =>{
        initSelection();
    },[]);

    useEffect(() =>{
        getLanguages();
        getDates();
    },[]);

    const initSelection = () =>{
        const temp = {
            languages: (props.user.data && props.user.data.languages) ? props.user.data.languages : [],
            distance: 20,
            dates: (props.user.data && props.user.data.schedule) ? (props.user.data.schedule.dates ? props.user.data.schedule.dates : []) : [],
            hours: (props.user.data && props.user.data.schedule) ? (props.user.data.schedule.hours ? props.user.data.schedule.hours : []) : [],
            residence: undefined
        }
        setSelection(temp);
        props.onChange && props.onChange(temp);
    }

    const getLanguages = () =>{
        const allLanguages: {
            id: string;
            title: string;
            en: string;
        }[] = Constant.languages();
        const languages: Array<OptionProps> = allLanguages.map(item => ({...item, label: item.title}));
        setLanguages(languages);
    }

    const getDates = () =>{
        const schedule: {
            hours: {
                id: string;
                title: string;
            }[];
            days: {
                id: string;
                title: string;
            }[];
        } = Constant.schedule();
        const dates: Array<OptionProps> = schedule.days.map(item => ({...item, label: item.title}));
        const hours: Array<OptionProps> = schedule.hours.map(item => ({...item, label: item.title}));
        setDates(dates);
        setHours(hours);
    }

    const onFilterChange = (filter: string, options: Array<{id: string}> | number | undefined) =>{
        let tempObject = selection;
        if(typeof options === 'number'){
            if(filter === 'distance'){
                tempObject = {
                    ...selection,
                    distance: options
                }
            }
            else if(filter === 'removeAll'){
                tempObject = {
                    languages: [], distance: 0, dates: [], hours: [], residence: undefined
                }
            }
        }
        else if(Array.isArray(options)){
            const temp = options.map(item => item.id);
            if(filter === 'languages'){
                tempObject = {
                    ...selection,
                    languages: temp
                }
            }
            else if(filter === 'dates'){
                tempObject = {
                    ...selection,
                    dates: temp
                }
            }
            else if(filter === 'hours'){
                tempObject = {
                    ...selection,
                    hours: temp
                }
            }
            else if(filter === 'residence'){
                tempObject = {
                    ...selection,
                    residence: temp[0] === 'residence' ? true : false
                }
            }
        }
        setSelection(tempObject);
        props.onChange && props.onChange(tempObject);
    }

    const onSearchBarChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        if(timer){
            clearTimeout(timer);
            setTimer(undefined);
        }
        setTimer(setTimeout(() => {
            const text = e.target.value;
            let tempObject = selection;
            if(text && text.length > 0){
                let newFilter = {}
                const regxPhone = /^[0-9\+]{1,}[0-9\-]{1,15}$/
                const text2 = text.replace(/\s/g, '');
                if(Regex.email(text2))
                    newFilter = { 
                        email: text2,
                        name: undefined,
                        phone: undefined,
                        objectId: undefined
                    };
                else if (regxPhone.test(text2)) 
                    newFilter={ 
                        phone: text2,
                        name: undefined,
                        email: undefined,
                        objectId: undefined
                    };
                else if (Regex.objectId(text2)) 
                    newFilter={ 
                        phone: undefined,
                        name: undefined,
                        email: undefined,
                        objectId: text2
                    };
                else if(!text.includes("+")) 
                    newFilter = { 
                        name: text,
                        email: undefined,
                        phone: undefined,
                        objectId: undefined
                    };
                const temp = {
                    ...tempObject,
                    ...newFilter
                }
                setSelection(temp);
                props.onChange && props.onChange(temp);
            }
            else{
                const temp = {
                    ...tempObject,
                    name: undefined,
                    email: undefined,
                    phone: undefined
                }
                setSelection(temp);
                props.onChange && props.onChange(temp);
            }
        }, 500));
    }

    return(
        <Container style={props.style}>
            {(!props.disabled || !props.disabled.includes("searchbar")) &&
                <SearchBar
                    placeholder='Buscar'
                    onChange={onSearchBarChange}
                />
            }
            <FiltersView>
                <Filter
                    id="languages"
                    placeholder="Idiomas"
                    options={languages}
                    selectedOptions={selection.languages.map(item => ({id: item}))}
                    type="multiple"
                    onChange={(options: Array<{id: string}>) => onFilterChange('languages', options)}
                    disabled={props.disabled ? props.disabled.includes("languages") : false}
                />
                <Filter
                    id="dates"
                    placeholder="Disp. días"
                    options={dates}
                    selectedOptions={selection.dates.map(item => ({id: item}))}
                    type="multiple"
                    onChange={(options: Array<{id: string}>) => onFilterChange('dates', options)}
                    disabled={props.disabled ? props.disabled.includes("dates") : false}
                />
                <Filter
                    id="hours"
                    placeholder="Disp. horas"
                    options={hours}
                    selectedOptions={selection.hours.map(item => ({id: item}))}
                    type="multiple"
                    position='bottom-left'
                    onChange={(options: Array<{id: string}>) => onFilterChange('hours', options)}
                    disabled={props.disabled ? props.disabled.includes("hours") : false}
                />
                <Filter
                    id="distance"
                    placeholder='Distancia'
                    type='ratio'
                    min={1}
                    max={50}
                    position='bottom-left'
                    selectedOptions={props.disabled ? (props.disabled.includes("distance") ? 0 : selection.distance) : selection.distance}
                    onChange={(options?: number) => onFilterChange('distance', options ? options : 0)}
                    disabled={props.disabled ? props.disabled.includes("distance") : false}
                />
                <Filter
                    id="residence"
                    placeholder="Tipo"
                    options={[
                        {
                            id: 'residence',
                            label: 'Residencia'
                        },
                        {
                            id: 'home',
                            label: 'Domicilio'
                        }
                    ]}
                    type="single"
                    onChange={(options: Array<{id: string}>) => onFilterChange('residence', options)}
                    disabled={props.disabled ? props.disabled.includes("residence") : false}
                />
                <Button
                    design='call-to-action'
                    style={{fontSize: 14}}
                    onClick={() => onFilterChange('removeAll', 0)}
                >
                    Borrar todo
                </Button>
            </FiltersView>
        </Container>
    )
}
export default GrandpaFilters;
export interface Props{
    user: UserScheme
    style?: React.CSSProperties,
    disabled?: Array<"distance" | 'languages' | 'dates' | 'hours' | 'searchbar' | 'residence'>
    onChange?: (filters: {
        languages: Array<string>,
        distance?: number,
        dates?: Array<string>,
        hours?: Array<string>,
    }) => void
}
export interface OptionProps{
    id: string
    label: string
    sublabel?: string
}