import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import { State } from '../redux';

import Table from '../view/task/Table';
import Detail from '../view/task/detail/Detail';
import Register from '../view/task/register/Register';
import Spinner from "../components/Spinner/Spinner";

const Task = (props: any) =>{

	const loading = useSelector((state:State)=>state.modal.loading);

	return (
		<>
		{loading && <Spinner/>}
		<Routes>
			<Route path={'/'} element={<Table/>}/>
			<Route path={'/registro'} element={<Register/>}/>
			<Route path={'/:objectId'} element={<Detail/>}/>
		</Routes> 
		</>
	);
}

export default Task;
export interface Props{

}