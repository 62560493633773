import React, { useState, useEffect } from "react";
import { Constant, UserScheme, User } from "client-v2";
import styled from "styled-components";
import moment from "moment";

import Card from "../../../components/Containers/Card";
import { Text, Button } from "@adoptaunabuelo/react-components";
import {
	Phone,
	Globe,
	Edit2,
	MapPin,
	Mail,
	User as UserIcon,
	Fingerprint,
	FolderLock,
	FileDown,
} from "lucide-react";
import InfomationModal from "../../../components/Modals/InfomationModal";
import InfoCell from "../../../components/Cell/InfoCell";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 13px;
	align-items: center;
`;

const SectionInfo = (props: Props) => {
	const grandpa = props.user.type === "grandpa" ? true : false;
	const [showInfomationModal, setShowInfomationModal] = useState(false);
	const [exportData, setExportData] = useState<
		{ Admin: UserScheme; date: string; exportId: string } | undefined
	>(undefined);

	useEffect(() => {
		getExportData();
	}, []);

	const getExportData = async () => {
		const exportArray = props.user.internalData?.export;
		if (exportArray) {
			const temp = await Promise.all(
				exportArray.map(async (item) => {
					const admin = await User.getById(item.adminId);
					const date = new Date(item.date.iso);
					return {
						...item,
						date: moment(date).format("DD/MM/YYYY"),
						Admin: admin.data,
					};
				})
			);
			temp.reduce((a, b) => (a.date > b.date ? a : b));
			setExportData(temp[0]);
		}
	};

	return (
		<>
			<InfomationModal
				isVisible={showInfomationModal}
				user={props.user}
				onClose={() => setShowInfomationModal(false)}
				onSave={(user) => {
					setShowInfomationModal(false);
					props.onUserChanged(user);
				}}
			/>
			<Card style={{ marginBottom: 16 }}>
				<TitleHeader>
					<Text type="p" weight={"semibold"}>
						Información
					</Text>
					<Button
						style={{ position: "absolute", right: 16, top: 12 }}
						design="image"
						onClick={() => setShowInfomationModal(true)}
						icon={<Edit2 height={20} width={20} />}
					/>
				</TitleHeader>
				<InfoCell
					title={"Teléfono"}
					subtitle={props.user.phone}
					icon={Phone}
				/>
				<InfoCell
					title={"Email"}
					subtitle={props.user.email ? props.user.email : "-"}
					icon={Mail}
				/>
				<InfoCell
					title={"Género"}
					subtitle={
						props.user.gender
							? props.user.gender === "male"
								? "Hombre"
								: props.user.gender === "female"
								? "Mujer"
								: "Otro"
							: "-"
					}
					icon={UserIcon}
				/>
				<InfoCell
					title="Idiomas"
					subtitle={
						props.user.data &&
						props.user.data.languages &&
						props.user.data.languages.length > 0
							? props.user.data.languages.map((language: any) => {
									const temp = Constant.languages().filter(
										(temp: any) => temp.id === language
									);
									return temp && temp.length > 0
										? temp[0].title + ", "
										: "-, ";
							  })
							: "-"
					}
					icon={Globe}
					empty={
						grandpa
							? props.user.data &&
							  props.user.data.languages &&
							  props.user.data.languages.length > 0
								? false
								: true
							: false
					}
				/>
				<InfoCell
					title={"Dirección"}
					subtitle={props.user.address ? props.user.address : "-"}
					icon={MapPin}
					empty={grandpa && !props.user.address ? true : false}
				/>
				<InfoCell
					title={"DNI o NIE"}
					subtitle={props.user.idCard ? props.user.idCard : "-"}
					icon={Fingerprint}
				/>
				{exportData && (
					<InfoCell
						title={"Última exportación"}
						subtitle={
							exportData
								? `${exportData.Admin.name} ${
										exportData.Admin.surname
								  } (${exportData.date})`
								: "-"
						}
						icon={FileDown}
					/>
				)}
				<InfoCell
					title={"Documento LOPD"}
					subtitle={
						<a
							href={props.user.internalData?.lopdUrl}
							target={"_blank"}
						>
							{props.user.internalData?.lopdUrl
								? "Ver documento"
								: "-"}
						</a>
					}
					icon={FolderLock}
				/>
			</Card>
		</>
	);
};

export default SectionInfo;
export interface Props {
	user: UserScheme;
	onUserChanged: (user: UserScheme) => void;
}
