import React, { useEffect, useState } from 'react';
import { Corporate, CoorporateScheme, ErrorScheme } from 'client-v2';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';

import SearchTable from '../../components/Table/SearchTable';
import { Button } from '@adoptaunabuelo/react-components';

const Table = (props: Props) =>{

    const dispatch = useDispatch();
	const { setCoorporateTableFilters } = bindActionCreators(actionsCreators, dispatch);
	const coorporateTableFilters = useSelector((state:State)=>state.coorporateTableFilters);
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);
    const [ coorporates, setCoorporates ] = useState<Array<CoorporateScheme>>([]);
    const [ count, setCount ] = useState(0);

    useEffect(() =>{
        getData(coorporateTableFilters);
    },[]);

    const getData = async (filter: any) =>{
        setLoading(true);
        if(filter.objectId){
            Corporate.getById(filter.objectId).then((result) =>{
                setCoorporates([result.data]);
                setCount(result ? 1 : 0);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setCoorporates([]);
                setCount(0);
                setLoading(false);
            });
        }
        else{
            Corporate.get({
                ...filter, 
                limit: filter.limit ? filter.limit : 20,
                ascending: 'name'
            }).then((result) =>{
                setCoorporates(result.data);
                setCount(result.pagination.count);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
            });
        }
    }

    const onRowClick = (coorporate: CoorporateScheme, target?: string) =>{
        if(target === '_blank')
            window.open('/corporativo/perfil?id='+coorporate.objectId, '_blank');
        else
            navigate('/corporativo/perfil?id='+coorporate.objectId);
    }

    const onFiltersChange = (result: any) =>{
        const temp = {
            ...result,
            active: result.active ? (result.active === 'true') : undefined
        }
        setCoorporateTableFilters(temp);
        getData(temp);
    }

    const renderButtonView = () =>(
        <Button
            style={{height: 36, marginLeft: 12}}
            onClick={() => navigate('/corporativo/registro')}
        >
            Añadir
        </Button>
    )

    return(
        <SearchTable
            title={'Empresas'}
            filters={[
                {
                    id: 'active',
                    placeholder: 'Estado',
                    type: 'single',
                    options: [
                        {
                            id: 'true',
                            label: 'Activa'
                        },
                        {
                            id: 'false',
                            label: 'Inactiva'
                        }
                    ],
                    selectedOptions: coorporateTableFilters.active === true ? ['true'] :  coorporateTableFilters.active === false ? ['false'] : undefined,
                    hideSearchBar: true
                }
            ]}
            columns={[
                {
                    id: 'name',
                    title: 'Nombre'
                },
                {
                    id: 'active',
                    title: 'Activo'
                },
                {
                    id: 'address',
                    title: 'Dirección'
                }
            ]}
            rows={coorporates}
            count={count}
            page={coorporateTableFilters.page}
            loading={loading}
            ButtonView={renderButtonView}
            onRowClick={onRowClick}
            onFiltersChange={onFiltersChange}
        />
    )
}
export default Table;
export interface Props{

}