import React, { useState } from "react";
import styled from 'styled-components';
import { UserScheme } from 'client-v2';
import { useDispatch } from 'react-redux';
import { actionsCreators } from '../../../redux';
import { bindActionCreators } from 'redux';

import { Phone, User, Edit2, MoreHorizontal, HeartHandshake } from 'lucide-react'
import EmergencyContactModal from '../../../components/Modals/EmergencyContactModal'
import Card from '../../../components/Containers/Card'
import { Text, Menu } from '@adoptaunabuelo/react-components';
import InfoCell from '../../../components/Cell/InfoCell';

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
`;

const SectionEmergencyContact = (props: Props) =>{

    const dispatch = useDispatch();
    const { setCallUser } = bindActionCreators(actionsCreators, dispatch);

    const [ showModal, setShowModal ] = useState(false);

    const onSave = (user?: UserScheme) =>{
        setShowModal(false);
        if(user)
            props.onUserChanged(user)
    }

    const onMenuClick = (option: any) =>{
        if(option.id === 'call'){
            if(props.user.data && props.user.data.emergencyContact && props.user.data.emergencyContact.phone && props.user.data.emergencyContact.name){
                const user: any = {
                    objectId: 'sec_'+props.user.objectId,
                    name: props.user.data.emergencyContact.name,
                    surname: '',
                    phone: props.user.data.emergencyContact.phone
                }
                setCallUser(user);
            }
        }
        else if(option.id === 'edit'){
            setShowModal(true)
        }
    }

	return (props.user.type ==="grandpa" ? 
        <>
        <EmergencyContactModal 
            isVisible={showModal}
            user={props.user}
            contact={props.user.data && props.user.data.emergencyContact ? {
                ...props.user.data.emergencyContact,
                position: props.user.data.emergencyContact.relationship
            } : undefined}
            onSave={onSave}
            onClose={() => setShowModal(false)}
        />
        <Card style={{marginBottom:16}}>
            <TitleHeader>
                <Text type='p' weight={"semibold"}>
                    Contacto de emergencia
                </Text>
                <Menu
                    id={'menu-emergency-contact'}
                    position={'bottom-left'}
                    icon={<MoreHorizontal height={20} width={20}/>}
                    menuStyle={{width: 'unset'}}
                    options={[
                        {
                            id: 'call',
                            label: 'Llamar',
                            icon: <Phone height={20} width={20}/>
                        },
                        {
                            id: 'edit',
                            label: 'Editar',
                            icon: <Edit2 height={20} width={20}/>
                        }
                    ]}
                    onClick={onMenuClick}
                />
            </TitleHeader>
            <InfoCell
                title={'Nombre'}
                subtitle={(props.user.data && props.user.data.emergencyContact) ? props.user.data.emergencyContact.name : '-'}
                icon={User}
            />
            <InfoCell
                title={'Teléfono'}
                subtitle={(props.user.data && props.user.data.emergencyContact) ? props.user.data.emergencyContact.phone : '-'}
                icon={Phone}
            />
            <InfoCell
                title={'Parentesco'}
                subtitle={(props.user.data && props.user.data.emergencyContact) ? props.user.data.emergencyContact.relationship : '-'}
                icon={HeartHandshake}
            />
        </Card>
        </>
	: null);
}

export default SectionEmergencyContact;
export interface Props{
    user: UserScheme
    onUserChanged: (user: UserScheme) => void
}