import React, { useState } from 'react';
import styled from 'styled-components';
import { User, Grandpa, Match, UserScheme, MatchScheme, ErrorScheme } from 'client-v2';
import moment from 'moment';

import { Check, ExternalLink, Info } from 'lucide-react';
import { Modal, Text, Color, Button, Avatar } from '@adoptaunabuelo/react-components';
import TableFilters from '../Filters/TableFilters';

const Cell = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0px;
    border-bottom: 1px solid ${Color.line.soft};
    cursor: pointer;
    &:hover{
        background-color: ${Color.status.neutral.hover};
    }
`
const Box = styled.div<{selected: boolean, error?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
    background-color: ${props => props.selected ? (props.error ? Color.status.color.error : Color.background.primary) : (props.error ? Color.status.color.errorDefault : Color.background.primaryLow)};
    border: ${props => props.selected ? '1px solid '+(props.error ? Color.status.color.error : Color.background.primary) : '1px solid '+(props.error ? Color.line.redSoft : Color.line.primarySoft)};
    border-radius: 4px;
`
const DataContent = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`
const Banner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 12px;
    background-color: ${Color.background.soft};
`

const MatchModal = (props: Props) =>{

    const [ userArray, setUserArray ] = useState<Array<UserScheme>>([]);
    const [ loading, setLoading ] = useState(false);
    const [ userSelected, setUserSelected ] = useState<UserScheme | undefined>(undefined);
    const [ modalError, setModalError ] = useState<string | undefined>(undefined);

    const search = async (filter: any) =>{
        setLoading(true);
        if(filter.objectId){
            User.getById(filter.objectId).then((result) =>{
                setUserArray([result.data]);
            });
        }
        else if(filter.phone){
            User.get({
                type: props.user?.type === 'volunteer' ? 'grandpa' : 'volunteer',
                phone: filter.phone
            }).then((result) =>{
                setUserArray(result.data);
            });
        }
        else if(props.user) {
            Grandpa.get(props.user.objectId, {
                ...filter,
                'schedule[dates]': filter.dates ? filter.dates : undefined,
                'schedule[hours]': filter.hours ? filter.hours : undefined,
                dates: undefined,
                hours: undefined
            }).then((result) =>{
                setUserArray(result.data);
            });
        }
        setLoading(false);
    }

    const onClose = () =>{
        setModalError(undefined);
        props.onClose()
    }

    const goToProfile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, user: UserScheme) =>{
        if(user.type === 'grandpa')
            window.open('/abuelos/perfil/?id='+user.objectId, '_blank');
        if(user.type === 'volunteer')
            window.open('/voluntarios/perfil/?id='+user.objectId, '_blank');
    }

    const getDistance = (loc1: {latitude: number, longitude: number}, loc2: {latitude: number, longitude: number}) =>{
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(loc2.latitude-loc1.latitude);  // deg2rad below
        var dLon = deg2rad(loc2.longitude-loc1.longitude); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(deg2rad(loc1.latitude)) * Math.cos(deg2rad(loc2.latitude)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2); 
        
        
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
    }
      
    const deg2rad = (deg: number) =>{
        return deg * (Math.PI/180)
    }

    const onSave = async () =>{
        if(props.user && userSelected){
            setModalError(undefined);
            setLoading(true);
            const currentUser = await User.currentAsync();
            Match.create({
                adminId: currentUser.data.objectId,
                volunteerId: props.user.type === 'grandpa' ? userSelected.objectId : props.user.objectId,
                grandpaId: props.user.type === 'grandpa' ? props.user.objectId : userSelected.objectId,
            }).then((result) =>{
                setLoading(false);
                props.onSave();
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
                setModalError(error.message);
            });
        }
    }

    return(
        <Modal
            isVisible={props.isVisible}
            style={{width: 550}}
            contentStyle={{minHeight: 300}}
            onClose={onClose}
            title={'Búsqueda'}
            subtitle={'Selecciona el usuario con el que hacer match'}
            Header={props.user ?
                <TableFilters
                    user={props.user}
                    style={{ marginTop: 16, paddingBottom: 12, borderBottom: '1px solid '+Color.line.soft }}
                    onChange={(filters) => search(filters)}
                />
            : 
                <></>
            }
            buttonProps={{
                loading: loading,
                disabled: !userSelected,
                onClick: onSave,
                children: 'Guardar'
            }}
            error={modalError}
        >
            {props.user && props.user.Residence &&
                <Banner>
                    <Text type='c1' style={{display: 'flex', flex: 1, color: Color.text.high}}>
                        Estás buscando {props.user.type === 'volunteer' ? 'abuelos' : 'voluntarios'} en la residencia a la que pertenece el {props.user.type === 'volunteer' ? 'voluntario' : 'abuelo'}. Además, se incluyen {props.user.type === 'volunteer' ? 'abuelos' : 'voluntarios'} de domicilios cercanos.
                    </Text>
                    <Info height={20} width={20} color={Color.text.high}/>
                </Banner>
            }
            {userArray.map((user, index) =>{
                const selected = user.objectId === userSelected?.objectId;
                const distance = (props.user && props.user.location && user.location) ? getDistance(props.user.location, user.location) : undefined;
                return(
                    <Cell
                        key={'user'+index}
                        onClick={() => {
                            setUserSelected(user);
                        }}
                    >
                        <Box
                            style={{marginRight: 12}}
                            selected={selected}
                        >
                            {selected &&
                                <Check 
                                    data-testid="check-icon"
                                    height={20} 
                                    width={20}
                                    color='white'
                                    strokeWidth={2}
                                />
                            }
                        </Box>
                        <Avatar 
                            style={{height:38, width:38, marginRight:8, fontSize:14}} 
                            name={user.name} 
                            icon={user.image?.url}
                        />
                        <DataContent>
                            <Row>
                                <Text type='p'>
                                    {user.name}, {user.birthday ? moment().diff(user.birthday.iso, 'years') : ''}
                                </Text>
                                {user.Residence &&
                                    <Text type='c1' style={{marginLeft: 8, color: '#2D55B5', backgroundColor: '#EBF9FF', padding: '0px 6px', borderRadius: 6, height: 18}}>
                                        Residencia
                                    </Text>
                                }
                            </Row>
                            {distance &&
                                <Text type='c1' style={{flex: 1, color: Color.text.high}}>
                                    a {distance.toFixed(2)} Km
                                </Text>
                            }
                        </DataContent>
                        <Button
                            style={{marginLeft: 8}}
                            design='image'
                            icon={<ExternalLink height={20} width={20} color={Color.text.high}/>}
                            onClick={(e) => goToProfile(e, user)} 
                        />
                    </Cell>
                )
            })}
        </Modal>
    )
}
export default MatchModal;
export interface Props{
    isVisible: boolean
    onClose: () => void
    onSave: () => void
    user?: UserScheme
}