import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { Corporate, Residence, User, UserScheme, CoorporateScheme, ResidenceScheme, ErrorScheme } from 'client-v2';
import moment from 'moment';

import { Input, Avatar, Button, Checkbox, Modal, Dropdown } from '@adoptaunabuelo/react-components';

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
`;

const EditUserModal = (props: Props) =>{

    const [ timer, setTimer ] = useState<any>(undefined);
    const [ form, setForm ] = useState<any>({
        name:props.user.name,
        surname:props.user.surname,
        birthday:props.user.birthday ? moment(props.user.birthday.iso).format('DD/MM/YYYY'): undefined,
        image:undefined,
        removeImage: undefined,
        coorporateId: props.corporate ? props.corporate.objectId : undefined,
        residenceId: props.residence ? props.residence.objectId : undefined
    })
    const [ loading, setLoading ] = useState(false);
    const [ isCoorporate, setIsCoorporate ] = useState(props.user.Coorporate ? true : false);
    const [ isResidence, setIsResidence ] = useState(props.user.Residence ? true : false);
    const [ corporateOptions, setCorporateOptions ] = useState<Array<any>>([]);
    const [ residenceOptions, setResidenceOptions ] = useState<Array<any>>([]);
    const [ error, setError ] = useState('');

    useEffect(() =>{
        if(props.corporate){
            setForm({...form, coorporateId: props.corporate.objectId});
            createCheckboxOption([props.corporate]);
        }
    },[props.corporate]);

    useEffect(() =>{
        if(props.residence){
            setForm({...form, residenceId: props.residence.objectId});
            createCheckboxOption([props.residence]);
        }
    },[props.residence]);
    
    const handleInput = (e:any) =>{
        const {name, value} = e.target;
        setForm({...form, [name]:value})
    }

    const createCheckboxOption = (options: Array<CoorporateScheme | ResidenceScheme>) =>{
        return options.map(item =>{
            return{
                id: item.objectId,
                title: item.name
            }
        });
    }

    const onSave = async () => {
        setLoading(true);
        let tmpForm = {...form}
        if(tmpForm.birthday){
            tmpForm.birthday = new Date(tmpForm.birthday);
        }
        if(!tmpForm.residenceId){
            tmpForm.remove = tmpForm.remove ? [...tmpForm.remove, 'Residence'] : ['Residence'];
        }
        if(!tmpForm.coorporateId){
            tmpForm.remove = tmpForm.remove ? [...tmpForm.remove, 'Coorporate'] : ['Coorporate'];
        }
        User.set(props.user.objectId, tmpForm).then((result) =>{
            props.onSave(result.data, result.data.Coorporate, result.data.Residence);
            setLoading(false);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
            setError(error.message);
        });
    }

    const onImageCrop = (file: string) =>{
        setForm({...form, image: file})
    }

    const onSearch = (text: string, type: 'residence' | 'corporate') =>{
        if(timer){
            clearTimeout(timer);
            setTimer(undefined);
        }
        setTimer(setTimeout(() => {
            if(type === 'corporate')
                searchCoporate(text);
            else if(type === 'residence')
                searchResidence(text);
        }, 500));
    }

    const searchCoporate = async (text: string) =>{
        setLoading(true);
        Corporate.get({
            name: text,
            limit: 5
        }).then((result) =>{
            const options = createCheckboxOption(result.data);
            setCorporateOptions(options);
            setLoading(false);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
        });
    }

    const searchResidence = async (text: string) =>{
        setLoading(true);
        Residence.get({
            name: text,
            limit: 5
        }).then((result) =>{
            const options = createCheckboxOption(result.data);
            setResidenceOptions(options);
            setLoading(false);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
        });
    }

    const onCoorporateDropdownChange = (options: Array<any>) =>{
        setForm({...form, coorporateId: options[0].id});
    }

    const onResidenceDropdownChange = (options: Array<any>) =>{
        setForm({...form, residenceId: options[0].id});
    }

    const onCoorporateCheckboxChange = (options: Array<any>) =>{
        if(options.length > 0)
            setIsCoorporate(true)
        else{
            setIsCoorporate(false)
            setForm({...form, coorporateId: undefined});
        }
    }

    const onResidenceCheckboxChange = (options: Array<any>) =>{
        if(options.length > 0)
            setIsResidence(true)
        else{
            setIsResidence(false)
            setForm({...form, residenceId: undefined});
        }
    }

    return(
        <Modal
            type='form'
            isVisible={props.isVisible}
            title={"Personal"} 
            error={error}
            options={[
                {
                    id: 'photo',
                    title: 'Foto',
                    Data: <ImageContainer>
                        <Avatar 
                            style={{height: 80, width: 80}}
                            name={form.name} 
                            icon={form.image ? form.image : props.user.image && props.user.image.url && !form.removeImage ? props.user.image.url : null}
                        /> 
                        <Input
                            type="image"
                            style={{marginLeft: 12, marginRight: 8}}
                            maxHeight={1000}
                            maxWidth={1000}
                            onChange={onImageCrop}
                        />
                        <Button
                            style={{height:32}}
                            design="text"
                            size="small"
                            onClick={()=>{setForm({...form, removeImage:true})}}
                        >
                            Eliminar foto
                        </Button>
                    </ImageContainer>
                },
                {
                    id: 'separator'
                },
                {
                    id: 'name',
                    title: 'Nombre',
                    Data: <Input 
                        type="text"
                        containerStyle={{display: 'flex', flex: 1}}
                        style={{flex: 1}}
                        name="name" 
                        placeholder="Nombre"
                        onChange={handleInput}
                        defaultValue={props.user.name}
                    />
                },
                {
                    id: 'surname',
                    title: 'Apellidos',
                    Data: <Input 
                        type="text"
                        containerStyle={{display: 'flex', flex: 1}}
                        style={{flex: 1}}
                        name="surname" 
                        placeholder="Apellidos"
                        onChange={handleInput}
                        defaultValue={props.user.surname}
                    />
                },
                {
                    id: 'separator'
                },
                {
                    id: 'birthday',
                    title: 'Cumpleaños',
                    Data: <Input 
                        name="birthday" 
                        type={"date"} 
                        style={{flex: 1}}
                        containerStyle={{display: 'flex', flex: 1}}
                        onChange={(date: any) =>{
                            setForm({
                                ...form,
                                birthday: date
                            });
                        }}
                        placeholder={'Fecha de cumpleaños'}
                        defaultValue={moment(props.user.birthday?.iso).format('DD/MM/YYYY')}
                    />
                },
                {
                    id: 'separator'
                },
                props.user.type === 'volunteer' ? {
                    id: 'coorporate',
                    title: 'Empresa',
                    Data: <div style={{marginBottom: 8}}>
                        <Checkbox
                            elementStyle={{marginBottom: 0}}
                            type="multiple"
                            options={[
                                {
                                    id: 'coorporate',
                                    label: 'Pertenece a una empresa'
                                }
                            ]}
                            selectedOptions={isCoorporate ? [{id: 'coorporate'}] : []}
                            onChange={onCoorporateCheckboxChange}
                        />
                        {isCoorporate &&
                            <Dropdown
                                id={'coorporate-dropdown'}
                                style={{marginTop: 8}}
                                placeholder={'Empresa'}
                                type='single'
                                menuPosition="top"
                                options={corporateOptions}
                                onSearchChange={(text) => onSearch(text, 'corporate')}
                                onChange={onCoorporateDropdownChange}
                            />
                        }
                    </div>
                } : { id:'' },
                {
                    id: 'residence',
                    title: 'Residencia',
                    Data: <div>
                        <Checkbox
                            elementStyle={{marginBottom: 0}}
                            type="multiple"
                            options={[
                                {
                                    id: 'residence',
                                    label: props.user.type === 'volunteer' ? 'Hace voluntariado en residencia' : 'Vive en residencia'
                                }
                            ]}
                            selectedOptions={isResidence ? [{id: 'residence'}] : []}
                            onChange={onResidenceCheckboxChange}
                        />
                        {isResidence &&
                            <Dropdown
                                id={'residence-dropdown'}
                                style={{marginTop: 8}}
                                placeholder={'Residencia'}
                                type='single'
                                menuPosition="top"
                                options={residenceOptions}
                                onSearchChange={(text) => onSearch(text, 'residence')}
                                onChange={onResidenceDropdownChange}
                            />
                        }
                    </div>
                }
            ]}
            onClose={props.onClose} 
            buttonProps={{
                onClick: onSave,
                loading: loading
            }}
        />
    )
}
export default EditUserModal;
export interface Props{
    isVisible: boolean,
    user: UserScheme,
    corporate?: CoorporateScheme,
    residence?: ResidenceScheme,
    onClose: () => void,
    onSave: (user: UserScheme, corporate?: CoorporateScheme, residence?: ResidenceScheme) => void
}
