import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Corporate, CoorporateScheme, ErrorScheme } from 'client-v2';

import { Text, Button, Feedback, Input, Avatar } from '@adoptaunabuelo/react-components'
import AdminBar from "../../components/Nav/AdminBar";

const Container = styled.div`
    position: relative;
	display: flex;
	width: -webkit-fill-available;
    height: 100vh;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const RegisterContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`
const RegisterView = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    background-color: white;
    padding: 24px 48px;
    border-radius: 12px;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
`

const Register = (props: Props) =>{

    const navigate = useNavigate();
    const [ loading, setLoading ] = useState(false);
    const [ name, setName ] = useState<string | undefined>(undefined);
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ logo, setLogo ] = useState<string | undefined>(undefined);

    const onSaveClick = async () =>{
        if(name){
            setLoading(true);
            Corporate.create({
                name: name,
                logo: logo
            }).then((result) =>{
                setLoading(false);
                navigate('/corporativo/perfil/?id='+result.data.objectId);
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
                setError(error.message);
            });
        }
        else{
            if(!name) setError('Debe añadir el nombre del abuelo/a');
        }
    }

    const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        setError(undefined);
        setName(e.target.value);
    }

    const onAvatarChange = (base64: string) =>{
        setLogo(base64);
    }

    return(
        <Container>
            <Feedback
                isVisible={error ? true : false}
                type='error'
                text={error ? error : ''}
                onClose={() => setError(undefined)}
            />
            <AdminBar
                style={{marginBottom: 16}}
                title="Registro de empresas"
                goBack={() => navigate(-1)}
            />
            <RegisterContainer>
                <RegisterView>
                    <Text type='h3' weight='semibold' style={{marginTop: 12, marginBottom: 24}}>
                        Datos de la empresa
                    </Text>
                    <Row>
                        <Avatar
                            name={name ? name : 'E'}
                            icon={logo}
                            style={{marginRight: 12, height: 62, width: 62}}
                            editable={true}
                            onChange={onAvatarChange}
                        />
                        <Input 
                            type="text"
                            containerStyle={{flex: 1}}
                            placeholder='Nombre'
                            onChange={onNameChange}
                        />
                    </Row>
                    <Row style={{flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                        <Button
                            loading={loading}
                            onClick={onSaveClick}
                        >
                            Guardar
                        </Button>
                    </Row>
                </RegisterView>
            </RegisterContainer>
        </Container>
    )
}
export default Register;
export interface Props{

}