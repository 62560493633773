import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from '../view/Login';

const Auth = (props: Props) =>{

	return (
		<Routes>
			<Route path={'/'} element={<Login/>}/>
		</Routes>
	);
}

export default Auth;
export interface Props{
	
}