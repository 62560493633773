import { Input, Modal } from "@adoptaunabuelo/react-components";
import { Activity, UserScheme, ErrorScheme } from "client-v2";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

const Column = styled.div`
	display: flex;
	flex-direction: column;
	padding: 4px 0px 12px;
	gap: 8px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
`;

const AddVisitModal = (props: AddVisitModalProps) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [startDate, setStartDate] = useState<moment.Moment | undefined>(
		undefined
	);
	const [endDate, setEndDate] = useState<moment.Moment | undefined>(
		undefined
	);

	const onHourChange = (type: "start" | "end", e: any) => {
		const tempTime = moment(e.target.value, "HH:mm");
		if (type === "start") {
			setStartDate(tempTime);
		} else if (type === "end") {
			setEndDate(tempTime);
		}
	};

	const onSave = () => {
		setError(undefined);
		if (startDate && endDate) {
			setLoading(true);
			const duration = moment
				.duration(endDate.diff(startDate))
				.asMinutes();
			if (duration > 0) {
				Activity.create({
					type: "visit",
					fromId: props.volunteer.objectId,
					toId: props.grandpa.objectId,
					data: {
						duration: duration,
					},
				})
					.then(() => {
						setLoading(false);
						props.onClose();
					})
					.catch((e: ErrorScheme) => {
						setLoading(false);
						setError(e.message);
					});
			} else {
				setLoading(false);
				setError("La hora de inicio debe ser menor a la hora de fin");
			}
		} else {
			setError("Selecciona una de inicio y de fin de la visita");
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			title="Registrar visita"
			buttonProps={{
				children: "Guardar",
				loading: loading,
				onClick: onSave,
			}}
			error={error}
			onClose={props.onClose}
		>
			<Column>
				<Row>
					<Input
						containerStyle={{ display: "flex", flex: 1 }}
						type={"time"}
						design={"secondary"}
						placeholder="Hora de inicio"
						onChange={(e: any) => onHourChange("start", e)}
					/>
					<Input
						containerStyle={{ display: "flex", flex: 1 }}
						type={"time"}
						design={"secondary"}
						placeholder="Hora de fin"
						onChange={(e: any) => onHourChange("end", e)}
					/>
				</Row>
			</Column>
		</Modal>
	);
};
export default AddVisitModal;
export interface AddVisitModalProps {
	isVisible: boolean;
	volunteer: UserScheme;
	grandpa: UserScheme;
	onClose: () => void;
}
