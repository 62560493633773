import React, { useState } from 'react';
import styled from "styled-components";
import { Event, EventScheme, ProductScheme } from 'client-v2';

import { Color, Text, Input, Button, Modal } from '@adoptaunabuelo/react-components';
import { Trash, Plus } from 'lucide-react';

const Row = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
`
const BulletContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 12px;
`
const Cell = styled.div`
    display: flex;
    flex-direction: row;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 16px;
`
const Line = styled.div`
    height: 100%;
    min-height: 25px;
    border-right: 1px solid ${Color.line.soft};
`

const TimelineModal = (props: Props) =>{

    const [ loading, setLoading ] = useState(false);
    const [ selectedTime, setSelectedTime ] = useState<string | undefined>(undefined);
    const [ selectedTitle, setSelectedTitle ] = useState<string | undefined>(undefined);
    const [ error, setError ] = useState<string | undefined>(undefined);
    const [ timeline, setTimeline ] = useState(props.event.timeline ? props.event.timeline : []);

    const onSave = async () =>{
        if(timeline.length > 0){
            setLoading(true);
            Event.set(props.event.objectId, {
                timeline: timeline
            }).then((event) =>{
                setLoading(false);
                props.onSave && props.onSave(event.data);
            }).catch((e: string) =>{
                setError(e);
            });
        }
        else{
            setError('Debes añadir al menos una celda para poder continuar');
        }
    }

    const addTiming = () =>{
        if(selectedTime && selectedTitle){
            setError(undefined);
            const temp = [...timeline, {
                time: selectedTime,
                title: selectedTitle
            }];
            setTimeline(temp);
        }
        else{
            setError('Debes completar Título y hora para añadir.')
        }
    }

    const removeTiming = (index: number) =>{
        const temp = [...timeline];
        temp.splice(index, 1);
        setTimeline(temp);
    }

    return(
        <Modal
            isVisible={props.isVisible}
            onClose={props.onClose}
            title={'Editar timing'}
            error={error}
            buttonProps={{
                children: 'Guardar',
                loading: loading,
                onClick: onSave
            }}
        >
            <BulletContainer>
                {timeline?.map((item, index) =>{
                    return(
                        <Cell
                            key={'timing-'+index}
                        >
                            <Column>
                                <Row>
                                    <Button
                                        design='image'
                                        style={{position: 'absolute', left: -36, top: -10}}
                                        icon={<Trash height={16} width={16}/>}
                                        onClick={() => removeTiming(index)}
                                    />
                                    <Text type='p' style={{backgroundColor: Color.background.soft, width: 55, borderRadius: 6, display: 'flex', justifyContent: 'center', height: 'fit-content'}}>
                                        {item.time}
                                    </Text>
                                </Row>
                                <Line/>
                            </Column>
                            <Text type='p' style={{marginBottom: 16}}>
                                {item.title}
                            </Text>
                        </Cell>
                    )
                })}
                <Cell
                    key={'timing-add'}
                    style={{alignItems: 'center', marginBottom: 24}}
                >
                    <Column>
                        <Input
                            type="text"
                            style={{width: 45}}
                            placeholder='00:00'
                            onChange={(e) => setSelectedTime(e ? e.target.value : undefined)}
                        />
                    </Column>
                    <Input
                        type="text"
                        containerStyle={{flex: 1, marginRight: 12}}
                        placeholder='Título'
                        onChange={(e) => setSelectedTitle(e ? e.target.value : undefined)}
                    />
                    <Button
                        size='small'
                        icon={<Plus/>}
                        onClick={addTiming}
                    >
                        Añadir
                    </Button>
                </Cell>
            </BulletContainer>
        </Modal>
    )
}
export default TimelineModal;
export interface Props{
    isVisible: boolean,
    event: EventScheme
    product: ProductScheme,
    onClose: () => void,
    onSave?: (event: EventScheme) => void
}