import React from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';
import { State } from '../redux';

import Table from '../view/sales/Table';
import Spinner from "../components/Spinner/Spinner";

const Sales = (props: any) =>{

	const loading = useSelector((state:State)=>state.modal.loading);

	return (
		<>
		{loading && <Spinner/>}
		<Routes>
			<Route path={'/'} element={<Table/>}/>
		</Routes> 
		</>
	);
}

export default Sales;
export interface Props{

}