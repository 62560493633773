import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
`

const Row = (props: Props) =>{

    return(
        <Container
            {...props}
        >
            {props.children}
        </Container>
    )
}
export default Row;
export interface Props extends ComponentPropsWithoutRef<"div">{

}