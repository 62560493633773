import React, { useEffect, useState } from 'react';
import { Product, Event, ProductScheme, ErrorScheme } from 'client-v2';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';

import SearchTable from '../../components/Table/SearchTable';
import { Button } from '@adoptaunabuelo/react-components';

const Table = (props: Props) =>{

    const dispatch = useDispatch();
	const { setEventTableFilters } = bindActionCreators(actionsCreators, dispatch);
	const eventTableFilters = useSelector((state:State)=>state.eventTableFilters);
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState(false);
    const [ products, setProducts ] = useState<Array<ProductScheme>>([]);
    const [ count, setCount ] = useState(0);

    useEffect(() =>{
        getData(eventTableFilters);
    },[]);

    const getData = async (filter: any) =>{
        setLoading(true);
        if(filter.objectId){
            Product.getById(filter.objectId).then((result) =>{
                setProducts([result.data]);
                setCount(result ? 1 : 0);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setProducts([]);
                setCount(0);
                setLoading(false);
            });
        }
        else{
            Product.get({
                ...filter, 
                type: 'event',
                limit: filter.limit ? filter.limit : 20,
                include: ['Event']
            }).then(async (result) =>{
                setProducts(result.data);
                setCount(result.pagination.count);
                setLoading(false);
            }).catch((error: ErrorScheme) =>{
                setLoading(false);
            });
        }
    }

    const onRowClick = (product: ProductScheme, target?: string) =>{
        if(target === '_blank')
            window.open('/evento/perfil?id='+product.objectId, '_blank');
        else
            navigate('/evento/perfil?id='+product.objectId);
    }

    const onFiltersChange = (result: any) =>{
        setEventTableFilters(result);
        getData(result);
    }

    const renderButtonView = () =>(
        <Button
            style={{height: 36, marginLeft: 12}}
            onClick={() => navigate('/evento/registro')}
        >
            Añadir
        </Button>
    )

    return(
        <SearchTable
            title={'Eventos'}
            filters={[]}
            columns={[
                {
                    id: 'title',
                    title: 'Título'
                },
                {
                    id: 'shortAddress',
                    title: 'Dirección'
                },
                {
                    id: 'price',
                    title: 'Precio'
                },
                {
                    id: 'startDate',
                    title: 'Fecha'
                }
            ]}
            rows={products}
            count={count}
            page={eventTableFilters.page}
            loading={loading}
            ButtonView={renderButtonView}
            onRowClick={onRowClick}
            onFiltersChange={onFiltersChange}
        />
    )
}
export default Table;
export interface Props{

}