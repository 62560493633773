import React, { useState } from 'react';
import styled from 'styled-components';
import { ErrorScheme, PaymentMethod, PaymentMethodScheme, UserScheme } from 'client-v2';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';

import { Plus, Trash2, MoreHorizontal, Award } from 'lucide-react';
import { Text, Button, Color, Modal, Menu, Label } from '@adoptaunabuelo/react-components';
import PaycardIcon from '../../assets/img/Paycard';
import PaymentMethodModal from '../../components/Modals/PaymentMethodModal';

const Container = styled.div`
    position: relative;
`
const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 13px;
    align-items: center;
`;
const Cell = styled.div`
    display: flex;
    flex-direction: row;
    padding: 8px 0px;
    border-bottom: 1px solid ${Color.line.soft};
    align-items: center;
`
const Column = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`
const Row = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
`

const SectionPaymentMethod = (props: Props) =>{

    const dispatch = useDispatch();
	const { setProfileFunction } = bindActionCreators(actionsCreators, dispatch);
    const currentUser = useSelector((state:State)=>state.currentUser);

    const [ showAddModal, setShowAddModal ] = useState(false);
    const [ showRemoveModal , setShowRemoveModal ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ paymentMethod, setPaymentMethod ] = useState<PaymentMethodScheme | undefined>(undefined);

    const removePaymentMethod = async (paymentMethod: PaymentMethodScheme) =>{
        setLoading(true);
        PaymentMethod.remove(paymentMethod.objectId, {
            userId: props.user.objectId,
        }).then((result) =>{
            setShowRemoveModal(false);
            setPaymentMethod(undefined);
            setLoading(false);
            setProfileFunction({ launch: true, function: 'payment-method' })
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
        });
    }

    const markAsDefault = async (paymentMethod: PaymentMethodScheme) =>{
        setLoading(true);
        PaymentMethod.setDefault(paymentMethod.objectId, {
            userId: props.user.objectId,
        }).then((result) =>{
            setPaymentMethod(undefined);
            setLoading(false);
            setProfileFunction({ launch: true, function: 'payment-method' })
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
        });
    }

    const onMenuClick = (option: string, paymentMethod: PaymentMethodScheme) =>{
        if(option === 'remove'){
            setShowRemoveModal(true);
            setPaymentMethod(paymentMethod);
        }
        if(option === 'default'){
            markAsDefault(paymentMethod);
        }
    }

    return(
        <>
        <PaymentMethodModal
            isVisible={showAddModal}
            user={props.user}
            currentUser={currentUser}
            onClose={() => setShowAddModal(false)}
            onSave={() => {
                setShowAddModal(false);
                setProfileFunction({ launch: true, function: 'payment-method' })
            }}
        />
        <Modal
			isVisible={showRemoveModal}
			title={'¿Seguro que quieres continuar?'}
			subtitle={'Al eliminar este método de pago no se podrá recuperar'}
			onClose={() => setShowRemoveModal(false)}
			buttonProps={{
				children: 'Eliminar método de pago',
				onClick: () => paymentMethod ? removePaymentMethod(paymentMethod) : null,
				loading: loading
			}}
		/>
        <Container>
            <TitleHeader>
                <Text type='p' weight={"semibold"}>
					Métodos de pago
				</Text>
                <Button 
                    onClick={()=> setShowAddModal(true)} 
                    design={'image'}
                    icon={<Plus height={20} width={20} color={Color.text.primary}/>}
                    style={{position: 'absolute', right: 0, top: -10}}
                />
            </TitleHeader>
            {(props.paymentMethods && props.paymentMethods.length > 0) ? 
                props.paymentMethods.map(item => (
                    <Cell
                        key={'payment-method-'+item.objectId}
                    >
                        <PaycardIcon
                            brand={item.brand}
                            style={{
                                height: 32,
                                width: 32,
                                borderRadius: 6, 
                                marginRight: 12
                            }}
                        />
                        <Column>
                            <Text type='p' weight='medium'>
                                **** **** **** {item.last4}
                            </Text>
                            <Row
                                style={{alignItems: 'center', gap: 8}}
                            >
                                <Text 
                                    type='c1'
                                    style={{color: Color.text.high}}
                                >
                                    {item.name ? item.name : 'Sin nombre'}
                                </Text>
                                {item.default &&
                                    <Label
                                        style={{fontSize: 10, padding: '0px 4px', height: 22, backgroundColor: '#EBF9FF'}}
                                        color={'#2D55B5'}
                                        size='small'
                                        type='label'
                                        text='Predeterminado'
                                    />
                                }
                            </Row>
                        </Column>
                        <Menu
                            id={'menu-payment-method-'+item.objectId}
                            icon={<MoreHorizontal height={20} width={20} color={Color.text.full}/>}
                            menuStyle={{width: 300}}
                            options={[
                                {
                                    id: 'default',
                                    label: 'Marcar como predeterminado',
                                    icon: <Award height={20} width={20}/>
                                },
                                {
                                    id: 'remove',
                                    label: 'Eliminar',
                                    icon: <Trash2 color={Color.status.color.error} height={20} width={20}/>
                                }
                            ]}
                            position={'bottom-left'}
                            onClick={(option) => onMenuClick(option.id, item)}
                        />
                    </Cell>
                ))
            :
                <Text type='p2' style={{color: Color.text.medium}}>
                    No hay ninguna aportación activa
                </Text>
            }
        </Container>
        </>
    )
}
export default SectionPaymentMethod;
export interface Props{
    user: UserScheme,
    paymentMethods?: Array<PaymentMethodScheme>
}