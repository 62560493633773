import React from "react";
import { Route, Routes } from "react-router-dom";

import Profile from '../view/volunteer/profile/Profile';
import Table from '../view/volunteer/search/Table';

const Volunteer = (props: any) =>{

	return (
		<Routes>
			<Route path={'/'} element={<Table/>}/>
			<Route path={'/perfil'} element={<Profile/>}/>
		</Routes> 
	);
}

export default Volunteer;
export interface Props{

}