import React from "react";
import styled from 'styled-components';
import { CoorporateScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import { Text, Color } from '@adoptaunabuelo/react-components';

const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin: 18px 0px;
`;
const CallRow = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	margin: 8px 0px;
`
const CallCell = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
`

const SectionAnalytics = (props: Props) =>{

	const renderCell = (title: string, data: number) =>(
		<CallCell>
			<Text type='h4' weight={"semibold"}>
				{data}
			</Text>
			<Text type='c1' style={{color: Color.text.high}}>
				{title}
			</Text>
		</CallCell>
	)

	return (
        <Card style={{marginBottom:16}}>
            <Text type='p' weight={"semibold"}>
				Acompañamiento
			</Text>
			{props.analytics &&
				<Column>
					<CallRow>
						{renderCell('horas',  Math.round(parseFloat(props.analytics.call.duration)/60 * 100) / 100)}
						{renderCell('llamadas', props.analytics.call.amount)}
						{renderCell('de '+props.analytics.review.amount+' valoraciones', props.analytics.review.average)}
					</CallRow>
					<CallRow>
						{renderCell('voluntarios activos', props.analytics.volunteer.active)}
						{renderCell('voluntarios', props.analytics.volunteer.amount)}
						{renderCell('historial voluntarios', props.analytics.volunteer.total)}
					</CallRow>
				</Column>
			}
        </Card>
	);
}

export default SectionAnalytics;
export interface Props{
    coorporate: CoorporateScheme,
	analytics: any
	onCoorporateChanged: (coorporate: CoorporateScheme) => void
}