export const SalesRole = {
    pages: {                    //Sidebar pages access
        tareas: true,
        voluntarios: true,
        abuelos: false,
        residencias: false,
        corporativo: false,
        evento: false,
        reto: false,
        ventas: false,
        embajadores: false,
        push: false,
        notifications: false,   //Show notifications view
    },
    pagesFav: {                 //Inital state for pages fav
        tareas: true,
        voluntarios: true,
        abuelos: false,
        residencias: false,
        coporativo: false,
        evento: false,
        reto: false,
        ventas: false,
        embajadores: false,
        push: false,
    },
    user: {
        set: {
            banned: false,      //Allow user to repeat onboarding
            state: false,       //Allow change user state
            match: true,       //Allow to create or edit a match
        },
        call: false,            //Allow to make call from user profile
        whatsapp: false         //Allow to send whatsapp message from user profile
    },
    task: {
        autodial: false,         //Allow to init call autodial
        downloadLeads: false,    //Allow to download leads
        uploadLeads: false,      //Allow to upload leads
        filters: true,          //Show filtrer in table
        clickable: true,        //Allow access detail from table
    },
    sales: {
        filter: false           //Allow to change admin filter
    },
    actions: {
        newTab: true            //Show "open in new tab" button
    },
    residence: {
        remove: false           //Can remove residence
    }
}