import React, { useState } from "react";
import styled from 'styled-components';
import { Regex, Auth, ErrorScheme } from 'client-v2';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State} from '../redux';
import { bindActionCreators } from 'redux';

import { Button, Input, Text, ColorV2 } from '@adoptaunabuelo/react-components';
import Card from '../components/Containers/Card';
import Logo from '../assets/img/brand/AdoptaUnAbuelo.svg'

const LoginContainer = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background-color: ${ColorV2.surface.background};
`
const LogoView = styled.img`
	margin-bottom: 32px;
	height: 80px;
`

const Login = (props: Props) =>{

	const dispatch = useDispatch();
	const taskTableFilters = useSelector((state:State)=>state.taskTableFilters);
	const { setCurrentUser, setTaskTableFilters } = bindActionCreators(actionsCreators, dispatch);
	const [ credentials, setCredrentials ]= useState({email:"", password:""});
	const [ credentialsErrors, setCredentialsErrors] = useState({email: "", password:""});
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState<string | undefined>(undefined);

	const navigate = useNavigate();

	const onCredentialsChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
		const { name, value } = e.target;
		setError(undefined);
		if(name==="email"){
			let test = Regex.email(value);
			if(!test){
				setCredentialsErrors({...credentialsErrors, email:"Email incorrecto"})
			} 
			else 
				setCredentialsErrors({...credentialsErrors, email:""})
		}
		setCredrentials({...credentials, [name]:value})
	}
	
	const onLoginPress = async () =>{
		if(!credentialsErrors.email && credentials.email && credentials.password){
			setLoading(true);
			Auth.login(credentials.email, credentials.password).then((result) =>{
				const user = result.data;
				if(user.type === 'admin' && user.active){
					setLoading(false);
					setCurrentUser(user);
					const department = (user.data && user.data.department) ? user.data.department : undefined;
					setTaskTableFilters({
						...taskTableFilters,
						priority: department === 'sales' ? ['high', 'medium', 'low'] : undefined,
						state: department === 'sales' ? ['pending'] : ['pending', 'inProgress'],
						adminId: department === 'admin' ? undefined : user.objectId,
						group:  department === 'admin' ? 'support' : department
					});
					navigate('/tareas');
				}
				else{
					setLoading(false);
					setError('Parece que no tienes acceso');
				}
			}).catch((error: ErrorScheme) =>{
				setLoading(false);
				setError(error.message)
			});
		}
	}

	return (
		<LoginContainer>
			<Card
				style={{width:460, backgroundColor: 'transparent'}}
			>
				<LogoView
					src={Logo}
				/>
				<Text 
					type='h3' 
					weight="semibold"
					style={{marginBottom: 48, textAlign: 'center'}}
				>
					¡Hola de nuevo!
				</Text>
				<Input
					id="email"
					design="secondary"
					containerStyle={{marginBottom: 12}}
					type={"email"}
					name="email"
					placeholder="Email"
					error={credentialsErrors.email}
					onChange={onCredentialsChange}
				/>
				<Input
					name="password"
					design="secondary"
					placeholder="Contraseña"
					type="password"
					error={credentialsErrors.password}
					onChange={onCredentialsChange}
				/>
				<Text type='c1' style={{color: ColorV2.text.red}}>
					{error}
				</Text>
				<Button
					style={{marginTop:24}}
					loading={loading}
					onClick={onLoginPress}
				>
					Iniciar sesión
				</Button>
			</Card>
		</LoginContainer>
	);
}
export default Login;
export interface Props{
	
}
