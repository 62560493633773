import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Modal, Text, ProgressBar, ColorV2 } from '@adoptaunabuelo/react-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`
const Section = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    align-items: center;
`
const Col = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
`
const Row = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const ReliefModal = (props: Props) =>{

    const isMobile = window.screen.width <= 768;
    const [ priceAmount, setPriceAmount ] = useState('0');
    const [ reliefAmount, setReliefAmount ] = useState('0');

    useEffect(() =>{
        getReliefAmount(props.price, props.charge);
    }, [props.price, props.charge]);

    const getReliefAmount = (price: number, charge: string) =>{
        const totalAmount = charge === 'monthly' ? price * 12 : price;
        const reliefSection1 = totalAmount > 250 ? 250 : totalAmount;
        const reliefSection2 = totalAmount > 250 ? totalAmount-250 : 0;
        const relief = reliefSection1*0.8 + reliefSection2*0.4;
        setPriceAmount((price*(charge === 'monthly' ? 12 : 1) - relief).toFixed(2));
        setReliefAmount(relief.toFixed(2));
    }

    return (
        <Modal
            isVisible={props.isVisible}
            type={isMobile ? 'full-screen' : 'default'}
            onClose={props.onClose}
        >
            <Container>
                <Text type='h4' weight='semibold' style={{marginBottom: 4}}>
                    Recupera hasta el 80% de tus donaciones
                </Text>
                <Text type='p'>
                    En tu próxima declaración de la Renta podrás desgravarte la cantidad que hayas donado a la Fundación Adopta Un Abuelo siguiendo los estos porcentajes:
                </Text>
                <Section
                    style={{backgroundColor: ColorV2.surface.secondary, marginTop: 32}}
                >
                    <Text type='h6' weight='medium' style={{marginBottom: 16}}>
                        Como particular*
                    </Text>
                    <Row>
                        <Col>
                            <Text type='h1' weight='semibold'>
                                80%
                            </Text>
                            <Text type='p2' weight='medium'>
                                hasta 250 €
                            </Text>
                        </Col>
                        <Col
                            style={{opacity: 0.43}}
                        >
                            <Text type='h1' weight='semibold'>
                                40%
                            </Text>
                            <Text type='p2' weight='medium'>
                                el resto**
                            </Text>
                        </Col>
                    </Row>
                    <Text type='c2' style={{color: ColorV2.text.neutralMedium, marginTop: 16}}>
                        **La deducción pasa a ser del <span style={{fontSize: 13, fontWeight: 600}}>45%</span> si colaboras en Adopta Un Abuelo con una donación igual o superior durante al menos 3 años.
                    </Text>
                </Section>
                <Section
                    style={{backgroundColor: ColorV2.surface.neutralSoft, marginTop: 16}}
                >
                    <Text type='p'>
                        Si donas {props.price} € {props.charge === 'monthly' ? 'al mes durante un año' : 'este año'}, tras la declaración de la renta:
                    </Text>
                    <ProgressBar
                        style={{height: 8, width: '100%', marginTop: 24, marginBottom: 8}}
                        progress={[
                            {
                                value: parseFloat(reliefAmount)/props.price*100,
                                color: ColorV2.surface.primaryHard
                            },
                            {
                                value: parseFloat(priceAmount)/props.price*100,
                                color: ColorV2.surface.primary
                            }
                        ]}
                    />
                    <Row>
                        <Col
                            style={{alignItems: 'flex-start'}}
                        >
                            <Text type='p'>
                                Te devolverán
                            </Text>
                            <Text type={isMobile ? 'h2' : 'h1'} weight='semibold'>
                                {reliefAmount.split('.')[0]}
                                <span style={{fontSize: 24, fontWeight: 500}}>
                                    ,{reliefAmount.split('.')[1]}€
                                </span>
                            </Text>
                        </Col>
                        <Col
                            style={{alignItems: 'flex-end'}}
                        >
                            <Text type='p' style={{color: ColorV2.text.primary}}>
                                Solo te costaría
                            </Text>
                            <Text type={isMobile ? 'h2' : 'h1'} weight='semibold' style={{color: ColorV2.text.primary}}>
                                {priceAmount.split('.')[0]}
                                <span style={{fontSize: 24, fontWeight: 500}}>
                                    ,{priceAmount.split('.')[1]}€
                                </span>
                            </Text>
                        </Col>
                    </Row>
                </Section>
                <Text type='c2' style={{color: ColorV2.text.neutralMedium, marginTop: 16}}>
                    *No aplica a País Vasco y Navarra, donde la deducción es del 20 y el 25%.
                </Text>
            </Container>
        </Modal>
    )
}
export default ReliefModal;
export interface Props{
    isVisible: boolean,
    price: number,
    charge: string
    onClose: () => void
}