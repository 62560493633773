import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	AdminTask,
	Subscription,
	PaymentMethod,
	Corporate,
	Product,
	Residence,
	AdminTaskScheme,
	CoorporateScheme,
	PaymentMethodScheme,
	ProductScheme,
	ResidenceScheme,
	SubscriptionScheme,
	UserScheme,
    ErrorScheme,
} from "client-v2";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreators } from "../../../redux";
import { bindActionCreators } from "redux";
import { State } from "../../../redux";

import AdminBar from "../../../components/Nav/AdminBar";
import Row from "../../../components/Containers/Row";
import Col from "../../../components/Containers/Col";
import SectionUser from "./SectionUser";
import SectionNotes from "./SectionNotes";
import Spinner from "../../../components/Spinner/Spinner";
import { Countdown, Text, Color } from "@adoptaunabuelo/react-components";
import { Phone } from "lucide-react";

const Container = styled.div`
	height: 100vh;
	padding: 0px 32px 16px;
	overflow: hidden;
`;

const Detail = (props: Props) => {
	const dispatch = useDispatch();
	const { setCallUser, setError } = bindActionCreators(
		actionsCreators,
		dispatch
	);
	const currentUser: UserScheme = useSelector(
		(state: State) => state.currentUser
	);
	const profileFunction = useSelector((state: State) => state.profile);
	const params = useParams<any>();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [task, setTask] = useState<AdminTaskScheme | undefined>(undefined);
	const [user, setUser] = useState<UserScheme | undefined>(undefined);
	const [subscription, setSubscription] = useState<
		SubscriptionScheme | undefined
	>(undefined);
	const [product, setProduct] = useState<ProductScheme | undefined>(
		undefined
	);
	const [paymentMethods, setPaymentMethods] = useState<
		Array<PaymentMethodScheme> | undefined
	>(undefined);
	const [historyArray, setHistoryArray] = useState<Array<any>>([]);
	const [dateSLA, setDateSLA] = useState(new Date());
	const [hideSLA, setHideSLA] = useState(false);
	const [coorporate, setCoorporate] = useState<CoorporateScheme | undefined>(
		undefined
	);
	const [residence, setResidence] = useState<ResidenceScheme | undefined>(
		undefined
	);
	const [nCalls, setNCalls] = useState(0);

	useEffect(() => {
		if (params.objectId) getTask(params.objectId);
		else navigate(-1);
	}, [params]);

	useEffect(() => {
		if (profileFunction.launch && user) {
			get(user);
		}
	}, [profileFunction]);

	const get = (user: UserScheme) => {
		if (profileFunction.function === "subscription") {
			getSubscription(user.objectId);
		} else if (profileFunction.function === "payment-method") {
			getPaymentMethods(user.objectId);
		}
	};

	const getTask = async (objectId: string) => {
		setLoading(true);
		AdminTask.getById(objectId, {
			include: ["To"],
		})
			.then((result) => {
				setTask(result.data);
				setUser(result.data.To);
				if (result.data.To.Coorporate)
					getCoorporate(result.data.To.Coorporate.objectId);
				if (result.data.To.Residence)
					getResidence(result.data.To.Residence.objectId);

				//Check the SLA
				if (
					result.data.state === "done" ||
					result.data.state === "canceled"
				)
					setHideSLA(true);
				else setHideSLA(false);

				//Get task group
				if (result.data.group === "sales") {
					getSubscription(result.data.To.objectId);
					getPaymentMethods(result.data.To.objectId);
				}

				getHistory(result.data)
					.then((result2) => {
						setHistoryArray(result2.data);
						//Get number of calls
						const result3 = result2.data.filter(
							(obj) => obj.type === "call"
						).length;
						setNCalls(result3);

						calculateSLA(result.data);
						setLoading(false);
					})
					.catch((error: ErrorScheme) => {
						setLoading(false);
						setError(true, error.message);
					});
			})
			.catch((error: ErrorScheme) => {
				navigate(-1);
				setLoading(false);
				setError(true, error.message);
			});
	};

	const getHistory = async (task: AdminTaskScheme) => {
		return AdminTask.getHistory(task.objectId);
	};

	const getSubscription = async (userId: string) => {
		Subscription.get({
			userId: userId,
			active: true,
		}).then((result) => {
			const tempSubscription = result.data ? result.data[0] : undefined;
			setSubscription(tempSubscription);
			if (tempSubscription) {
				const product = tempSubscription.Product;
				Product.getById(product.objectId).then((result) => {
					setProduct(result.data);
				});
			}
		});
	};

	const getPaymentMethods = async (userId: string) => {
		PaymentMethod.get({
			userId: userId,
		}).then((result) => {
			setPaymentMethods(result.data);
		});
	};

	const getCoorporate = async (objectId: string) => {
		Corporate.getById(objectId).then((result) => {
			setCoorporate(result.data);
		});
	};

	const getResidence = async (objectId: string) => {
		Residence.getById(objectId).then((result) => {
			setResidence(result.data);
		});
	};

	const calculateSLA = (adminTask: AdminTaskScheme) => {
		const sla = AdminTask.calculateSLA(adminTask.priority);
		const createdAt = new Date(adminTask.createdAt);
		createdAt.setHours(createdAt.getHours() + sla);
		setDateSLA(createdAt);
	};

	const onCallClick = () => {
		if (user && task) {
			//Init call
			setCallUser(user, {
				adminTask: task,
			});
			//Change admin task state
			if (task.state === "pending") {
				AdminTask.set(task.objectId, {
					state: "inProgress",
					adminId: currentUser.objectId,
				})
					.then(async (result: AdminTaskScheme) => {
						const tempTask = {
							...task,
							state: result.state,
						};
						setTask(tempTask);
						const history = await getHistory(tempTask);
						if (Array.isArray(history)) setHistoryArray(history);
					})
					.catch((error: ErrorScheme) => {
						setError(
							true,
							"Hubo un error al tratar de modificar el estado de esta tarea."
						);
					});
			}
		}
	};

	return loading || !user || !task ? (
		<Spinner />
	) : (
		<Container>
			<AdminBar
				title="Detalle"
				goBack={() => navigate(-1)}
				RightBar={
					!hideSLA ? (
						<Row>
							<Text
								type="c1"
								style={{
									color: Color.text.red,
									alignItems: "center",
									display: "flex",
									marginRight: 24,
								}}
							>
								<Phone
									height={16}
									width={16}
									style={{ marginRight: 4 }}
								/>{" "}
								{nCalls} llamadas
							</Text>
							<Countdown toDate={dateSLA} color={"white"} />
						</Row>
					) : (
						<></>
					)
				}
			/>
			<Row style={{ height: "calc(100vh - 116px)" }}>
				<Col style={{ flex: 2, marginRight: 16 }}>
					<SectionNotes
						user={user}
						task={task}
						historyArray={historyArray}
						getHistory={getHistory}
					/>
				</Col>
				<Col>
					<SectionUser
						user={user}
						coorporate={coorporate}
						residence={residence}
						subscription={subscription}
						product={product}
						paymentMethods={paymentMethods}
						task={task}
						onCallClick={onCallClick}
					/>
				</Col>
			</Row>
		</Container>
	);
};
export default Detail;
export interface Props {
	task?: AdminTaskScheme;
}
