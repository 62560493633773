import React, { ComponentPropsWithoutRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { TicketScheme } from 'client-v2';
import { useSelector } from 'react-redux';
import { State } from '../../redux';

import { Color, Text, Button, Menu, Label, Avatar } from '@adoptaunabuelo/react-components';
import { MoreHorizontal, ExternalLink, Trash } from 'lucide-react';
import moment from 'moment';

const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 10px 0px;
    border-bottom: 1px solid ${Color.line.soft};
    cursor: pointer;
`;
const Column = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TicketList = (props: Props) =>{

    const navigate = useNavigate();
    const role = useSelector((state:State)=>state.role);

    const onCardClick = (item: TicketScheme, target?: string) =>{
        if(item.User.type === 'volunteer'){
            if(target)
                window.open('/voluntarios/perfil/?id='+(item.User.objectId), '_blank');
            else
                navigate('/voluntarios/perfil/?id='+(item.User.objectId));
        }
        else if(item.User.type === 'grandpa'){
            if(target)
                window.open('/abuelos/perfil/?id='+(item.User.objectId), '_blank');
            else
                navigate('/abuelos/perfil/?id='+(item.User.objectId));
        }
    }

    return(
        <>
        {props.data.map((item, index:number)=> (item && item.User) ? (
            <ItemContainer 
                onClick={()=>onCardClick(item)} 
                key={'card-volunteer'+index} 
                style={{borderBottom: index+1 === props.data.length ? 'none' : '1px solid '+Color.line.soft}}
            >
                <Row>
                    <Avatar 
                        style={{height:40, width:40, marginRight:12, fontSize:20}} 
                        name={item.User.name} 
                        icon={item.User.image?.url}
                    />
                    <Column>
                        <Row style={{alignItems: 'center'}}>
                            <Text type='p2' weight="medium">
                                {item.User.name} {item.User.surname}
                            </Text>
                            {role.actions.newTab &&
                                <Button
                                    design='image'
                                    icon={<ExternalLink height={16} width={16} color={Color.text.high}/>}
                                    onClick={(e) => {
                                        onCardClick(item, '_blank');
                                        e.stopPropagation();
                                    }}
                                />
                            }
                        </Row>
                        <Row
                            style={{marginTop: -8}}
                        >
                            <Text type='c1' style={{color: Color.text.high}}>
                                Inscrito {moment(item.createdAt).format('HH:mm DD/MM/YY')}
                            </Text>
                            {item.state === 'canceled' &&
                                <Label
                                    style={{fontSize: 10, height: 20, marginLeft: 8}}
                                    text={'Cancelado'}
                                />
                            }
                        </Row>
                    </Column>
                    <Menu
                        id={"match-option-"+index}
                        position={"bottom-left"}
                        icon={<MoreHorizontal height={20} width={20} color={Color.text.full}/>}
                        options={item.state !== 'canceled' ? [
                            {
                                id:"delete",
                                label:"Eliminar",
                                icon: <Trash height={20} width={20}/>
                            }
                        ] : []}
                        menuStyle={{width: 220}}
                        onClick={(op: any) => props.onSave && props.onSave({
                            ticket: item,
                            action: op.id
                        })}
                    />
                </Row>
            </ItemContainer>
        ) : null)}
    </>
    )
}
export default TicketList;
export interface Props extends ComponentPropsWithoutRef<"div">{
    data: Array<TicketScheme>,
    onSave?:(data: {
        ticket: TicketScheme,
        action: string
    })=>void
}