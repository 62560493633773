import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const View = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: white;
    border-radius: 12px;
    padding: 18px 16px;
`

const CardBody = (props: Props) =>{

    return(
        <View
            style={props.style}
        >
            {props.children}
        </View>
    )
}
export default CardBody;
export interface Props{
    title?: string,
    subtitle?: string,
    children: React.ReactNode,
    style?: CSSProperties
    bodyStyle?: CSSProperties
}