import React from 'react';
import styled from 'styled-components';

import { Text, Button, Feedback } from '@adoptaunabuelo/react-components';

const Row = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
`
const LabelColumn = styled.div`
    display: flex;
    width: 112px;
    align-items: center;
`
const DataColumn = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`
const RegisterContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
`
const RegisterView = styled.div`
    display: flex;
    flex-direction: column;
    width: 600px;
    background-color: white;
    padding: 24px 48px;
    border-radius: 12px;
`
const Separator = styled.div`
    margin: 24px 0px 16px;
`

const RegisterForm = (props: Props) =>{

    return(
        <RegisterContainer>
            <Feedback
                isVisible={props.error ? true : false}
                type='error'
                text={props.error ? props.error : ''}
                onClose={() => props.onErrorClose && props.onErrorClose()}
            />
            <RegisterView>
                <Text type='h3' weight='semibold' style={{marginTop: 12, marginBottom: 24}}>
                    {props.title}
                </Text>
                {props.options.map((item, index) =>{
                    if(item.id === 'separator') return(
                        <Separator key={'separator-'+index}>
                            {item.title &&
                                <Text type='p2' weight='medium'>
                                    {item.title}
                                </Text>
                            }
                        </Separator>
                    )
                    else return(
                        <Row key={'option-'+item.id}>
                            <LabelColumn>
                                <Text type='c1' weight="medium">
                                    {item.title}
                                </Text>
                            </LabelColumn>
                            <DataColumn>
                                {item.Data}
                            </DataColumn>
                        </Row>
                    )
                })}
                <Row style={{flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end'}}>
                    <Button
                        size='small'
                        {...props.buttonProps}
                    />
                </Row>
            </RegisterView>
        </RegisterContainer>
    )
}
export default RegisterForm;
export interface Props{
    title?: string
    error?: string,
    options: Array<{
        id: string,
        title?: string,
        Data?: JSX.Element
    }>
    buttonProps: {
        loading?: boolean,
        success?: boolean,
        children: string,
        onSuccess?: () => void
        onClick?: () => void
    }
    onErrorClose?: () => void
}