import React, { useState } from 'react';
import styled from 'styled-components';
import { Corporate, CoorporateScheme, ErrorScheme } from 'client-v2';

import { Modal, Input, Select } from '@adoptaunabuelo/react-components';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 24px;
`

const CorporateInfoModal = (props: Props) =>{

    const [ loading, setLoading ] = useState(false);
    const [ name, setName ] = useState<string | undefined>(undefined);
    const [ selection, setSelection ] = useState({label: props.coorporate.active ? 'Activa' : 'Inactiva'})
    const [ error, setError ] = useState<string | undefined>(undefined);

    const onNameChange = (e: any) =>{
        setName(e.target.value);
    }

    const onSave = async () =>{
        setLoading(true);
        Corporate.set(props.coorporate.objectId, {
            name: name,
            active: selection ? (selection.label === 'Activa' ? true : false) : undefined
        }).then((result) =>{
            setLoading(false);
            props.onSave(result.data);
        }).catch((error: ErrorScheme) =>{
            setLoading(false);
            setError(error.message);
        });
    }

    return(
        <Modal
            isVisible={props.isVisible}
            onClose={props.onClose}
            title={'Editar'}
            error={error}
            buttonProps={{
                children: 'Guardar',
                loading: loading,
                onClick: onSave
            }}
        >
            <Container>
                <Input
                    type="text"
                    placeholder='Nombre'
                    defaultValue={props.coorporate.name}
                    containerStyle={{flex: 1}}
                    onChange={onNameChange}
                />
                <Select
                    id='selector_active'
                    options={[
                        {label: 'Activa'},
                        {label: 'Inactiva'}
                    ]}
                    selectedItem={selection}
                    onChange={(selection) => setSelection(selection)}
                />
            </Container>
        </Modal>
    )
}
export default CorporateInfoModal;
export interface Props{
    isVisible: boolean,
    onClose: () => void,
    onSave: (coorporate: CoorporateScheme) =>void,
    coorporate: CoorporateScheme
}